import {
  get,
  postFormData,
  deleteMethod,
  put,
  putFormData,
} from "../base/index";

export default {
  getDiscountCoupons: (params) => {
    return get(`${process.env.REACT_APP_URL_BASE}/discount_coupons/`, params);
  },
  createDiscountCoupon: (body) => {
    return postFormData(
      `${process.env.REACT_APP_URL_BASE}/discount_coupons/`,
      body
    );
  },
  deleteDiscountCoupon: (id) => {
    return deleteMethod(
      `${process.env.REACT_APP_URL_BASE}/discount_coupons/${id}/`
    );
  },
  updateDiscountCoupon: (body, typePutPetition) => {
    return typePutPetition === 2
      ? putFormData(`${process.env.REACT_APP_URL_BASE}/discount_coupons/`, body)
      : put(`${process.env.REACT_APP_URL_BASE}/discount_coupons/`, body);
  },
  getDiscountRates: (params) => {
    return get(`${process.env.REACT_APP_URL_BASE}/discount_rates/`, params);
  },
  createDiscountRate: (body) => {
    return postFormData(
      `${process.env.REACT_APP_URL_BASE}/discount_rates/`,
      body
    );
  },
  deleteDiscountRate: (id) => {
    return deleteMethod(
      `${process.env.REACT_APP_URL_BASE}/discount_rates/${id}/`
    );
  },
  updateDiscountRate: (body, typePutPetition) => {
    return typePutPetition === 2
      ? putFormData(`${process.env.REACT_APP_URL_BASE}/discount_rates/`, body)
      : put(`${process.env.REACT_APP_URL_BASE}/discount_rates/`, body);
  },
};
