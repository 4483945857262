import React, { useEffect } from "react";
import { Input, Button, Spinner } from "reactstrap";
import DropZone from "../../commons/components/DropZone";
import { useFormik } from "formik";
import Icon from "@mdi/react";
import { mdiClose } from "@mdi/js";
import TimePicker from "rc-time-picker";
import "rc-time-picker/assets/index.css";
import moment from "moment";
import { useNavigate, useParams } from "react-router-dom";

import { useDispatch, useSelector } from "react-redux";
import {
  getCategories,
  getCourseType,
  getSubcategories,
} from "../../redux/actions/categoriesActions";
import { getMentors } from "../../redux/actions/usersActions";
import {
  createCourse,
  updateCourseComplete,
} from "../../redux/actions/coursesActions";
const yup = require("yup");

const convertHM = (seconds) => {
  let hour = Math.floor(seconds / 3600);
  let minute = Math.floor((seconds - hour * 3600) / 60);

  return {
    hour,
    minute,
  };
};

const CreateCourse = () => {
  const dispatch = useDispatch();
  const { courseId } = useParams();
  const courseTypeList = useSelector(
    (state) => state.categories.courseType?.data
  );
  const categoriesList = useSelector(
    (state) => state.categories.categories?.data
  );
  const subcategoriesList = useSelector(
    (state) => state.categories.subcategories?.data
  );
  const mentorsList = useSelector((state) => state.users.mentors?.data);
  const courseslist = useSelector((state) => state.courses.courses?.data);
  const courseToEdit =
    courseId && courseslist
      ? courseslist.find((course) => course.id == courseId)
      : null;
  const isSendingRequest = useSelector(
    (state) => state.courses.modalActionStatus
  );
  const navigate = useNavigate();

  const formik = useFormik({
    initialValues: {
      courseType: courseToEdit ? courseToEdit.course_type.id : "",
      category: courseToEdit ? courseToEdit.subcategory.category.id : "",
      subCategory: courseToEdit ? courseToEdit.subcategory.id : "",
      courseMaterial: courseToEdit
        ? courseToEdit.course_material_url !== ""
          ? [
            {
              name: courseToEdit?.course_material_url
                ?.slice(
                  courseToEdit?.course_material_url.lastIndexOf("/") + 1
                )
                .replace(/%20/g, " "),
            },
          ]
          : []
        : [],
      courseAudio: courseToEdit
        ? [
          {
            name: courseToEdit?.course_audio_url
              ?.slice(courseToEdit?.course_audio_url.lastIndexOf("/") + 1)
              .replace(/%20/g, " "),
          },
        ]
        : [],
      estimatedTime: courseToEdit
        ? moment().set(convertHM(courseToEdit?.estimated_duration))
        : null,
      creditHours: courseToEdit
        ? courseToEdit?.credit_hours
        : "",
      title: courseToEdit ? courseToEdit.title : "",
      description: courseToEdit ? courseToEdit.description : "",
      description_web: courseToEdit ? courseToEdit.description_web : "",
      coursePicture: courseToEdit
        ? [
          {
            name: courseToEdit?.course_cover_url
              ?.slice(courseToEdit?.course_cover_url.lastIndexOf("/") + 1)
              .replace(/%20/g, " "),
            preview: courseToEdit?.course_cover_url,
          },
        ]
        : [],
      mentor: courseToEdit ? courseToEdit.mentor.id : "",
      price: courseToEdit ? courseToEdit.course_price : "",
      course_price_web: courseToEdit ? courseToEdit.course_price_web : "",
      code: courseToEdit ? courseToEdit.code : "",
      assessmentType: courseToEdit ? courseToEdit.assessment_type : "",
    },
    validationSchema: yup.object({
      courseType: yup.string().notOneOf([""]).required(),
      category: yup.string().notOneOf([""]).required(),
      subCategory: yup.string().notOneOf([""]).required(),
      courseAudio: yup.array().min(1).required(),
      estimatedTime: yup.object().required(),
      creditHours: yup.number().required(),
      title: yup.string().required(),
      description: yup.string().required(),
      description_web: yup.string().required(),
      coursePicture: yup.array().min(1).required(),
      mentor: yup.string().notOneOf([""]).required(),
      price: yup.number().required(),
      course_price_web: yup.number().required(),
      code: yup.string().required(),
      assessmentType: yup.string().when("courseType", {
        is: () =>
          courseTypeList.find(
            (courseType) => courseType.id == formik.values.courseType
          )?.id !== 1,
        then: yup.string().required(),
      }),
    }),
    onSubmit: ({
      code,
      courseAudio,
      courseMaterial,
      coursePicture,
      courseType,
      description,
      description_web,
      estimatedTime,
      creditHours,
      mentor,
      price,
      course_price_web,
      subCategory,
      title,
      assessmentType,
    }) => {
      let bodyFormData = new FormData();

      if (courseToEdit) {
        const isNewCourseAudioFile =
          courseAudio[0]?.name ===
            courseToEdit?.course_audio_url
              ?.slice(courseToEdit?.course_audio_url.lastIndexOf("/") + 1)
              .replace(/%20/g, " ")
            ? false
            : true;
        bodyFormData.append("id", courseToEdit.id);
        bodyFormData.append("code", code);
        bodyFormData.append("title", title);
        bodyFormData.append("description", description);
        bodyFormData.append("description_web", description_web);
        bodyFormData.append(
          "course_material_url",
          courseMaterial[0]?.name ===
            courseToEdit?.course_material_url
              ?.slice(courseToEdit?.course_material_url.lastIndexOf("/") + 1)
              .replace(/%20/g, " ")
            ? courseToEdit?.course_material_url
            : courseMaterial.length > 0
              ? courseMaterial[0]
              : ""
        );
        bodyFormData.append(
          "course_audio_url",
          courseAudio[0]?.name ===
            courseToEdit?.course_audio_url
              ?.slice(courseToEdit?.course_audio_url.lastIndexOf("/") + 1)
              .replace(/%20/g, " ")
            ? courseToEdit?.course_audio_url
            : ""
        );
        bodyFormData.append(
          "estimated_duration",
          moment(estimatedTime).hour() * 3600 +
          moment(estimatedTime).minute() * 60
        );
        bodyFormData.append(
          "credit_hours",
          creditHours
        );
        bodyFormData.append(
          "course_cover_url",
          coursePicture[0]?.name ===
            courseToEdit?.course_cover_url
              ?.slice(courseToEdit?.course_cover_url.lastIndexOf("/") + 1)
              .replace(/%20/g, " ")
            ? courseToEdit?.course_cover_url
            : coursePicture[0]
        );
        bodyFormData.append("course_price", price);
        bodyFormData.append("course_price_web", course_price_web);
        bodyFormData.append("is_free", courseToEdit.is_free);
        bodyFormData.append(
          "is_free_public_user",
          courseToEdit.is_free_public_user
        );
        bodyFormData.append("is_active", courseToEdit.is_active);
        bodyFormData.append("is_featured", courseToEdit.is_featured);
        bodyFormData.append("has_pre_test", courseToEdit.has_pre_test);
        bodyFormData.append("has_post_test", courseToEdit.has_post_test);
        bodyFormData.append("course_type_id", courseType);
        bodyFormData.append("subcategory_id", subCategory);
        bodyFormData.append("mentor_id", mentor);
        bodyFormData.append("assessment_type", assessmentType);
        dispatch(
          updateCourseComplete(
            bodyFormData,
            isNewCourseAudioFile ? courseAudio[0] : null
          )
        );
      } else {
        bodyFormData.append("code", code);
        bodyFormData.append("title", title);
        bodyFormData.append("description", description);
        bodyFormData.append("description_web", description_web);
        bodyFormData.append(
          "course_material_url",
          courseMaterial.length > 0 ? courseMaterial[0] : ""
        );
        bodyFormData.append("course_audio_url", "");
        bodyFormData.append(
          "estimated_duration",
          moment(estimatedTime).hour() * 3600 +
          moment(estimatedTime).minute() * 60
        );
        bodyFormData.append(
          "credit_hours",
          creditHours
        );
        bodyFormData.append("course_cover_url", coursePicture[0]);
        bodyFormData.append("course_price", price);
        bodyFormData.append("course_price_web", course_price_web);
        bodyFormData.append("is_free", "False");
        bodyFormData.append("is_free_public_user", "False");
        bodyFormData.append("is_active", "True");
        bodyFormData.append("is_featured", "True");
        bodyFormData.append("has_pre_test", "False");
        bodyFormData.append("has_post_test", "True");
        bodyFormData.append("course_type_id", courseType);
        bodyFormData.append("subcategory_id", subCategory);
        bodyFormData.append("mentor_id", mentor);
        bodyFormData.append("assessment_type", assessmentType);
        dispatch(createCourse(bodyFormData, courseAudio[0]));
      }
    },
  });

  useEffect(() => {
    if (isSendingRequest === "COMPLETED") navigate(`/cursos`);
  }, [isSendingRequest]);

  useEffect(() => {
    dispatch(
      getCourseType({
        data_all: "YES",
      })
    );
    dispatch(
      getCategories({
        data_all: "YES",
      })
    );
    dispatch(
      getMentors({
        data_all: "YES",
      })
    );
  }, []);

  useEffect(() => {
    if (formik.values.category !== "") {
      dispatch(
        getSubcategories(
          {
            data_all: "YES",
            category_id: formik.values.category,
          },
          false
        )
      );
    }
  }, [formik.values.category]);

  const validateEqualInitialData = () => {
    if (courseToEdit) {
      if (
        formik.values.courseType == courseToEdit.course_type.id &&
        formik.values.category == courseToEdit.subcategory.category &&
        formik.values.subCategory == courseToEdit.subcategory.id &&
        ((formik.values.courseMaterial.length === 0 &&
          courseToEdit?.course_material_url === "") ||
          formik.values.courseMaterial[0]?.name ==
          courseToEdit?.course_material_url
            ?.slice(courseToEdit?.course_material_url.lastIndexOf("/") + 1)
            .replace(/%20/g, " ")) &&
        formik.values.courseAudio[0].name ===
        courseToEdit?.course_audio_url
          ?.slice(courseToEdit?.course_audio_url.lastIndexOf("/") + 1)
          .replace(/%20/g, " ") &&
        formik.values.title == courseToEdit.title &&
        formik.values.description == courseToEdit.description &&
        formik.values.description_web == courseToEdit.description_web &&
        formik.values.coursePicture[0].name ===
        courseToEdit?.course_cover_url
          ?.slice(courseToEdit?.course_cover_url.lastIndexOf("/") + 1)
          .replace(/%20/g, " ") &&
        formik.values.mentor == courseToEdit.mentor.id &&
        formik.values.price == courseToEdit.course_price &&
        formik.values.course_price_web == courseToEdit.course_price_web &&
        formik.values.code == courseToEdit.code &&
        formik.values.assessmentType == courseToEdit.assessmentType
      ) {
        return true;
      }
      return false;
    }
    return false;
  };

  return (
    <div className="coursesContainer">
      <div className="headerContainer">
        <div className="leftHeaderContainer">
          <h4>{courseId ? "Editar" : "Subir"} Curso</h4>
        </div>
      </div>
      <div className="createCourseContainer">
        <form className="formContainer" onSubmit={formik.handleSubmit}>
          <div className="rowFields">
            <Input
              name={"courseType"}
              type="select"
              value={formik.values.courseType}
              onChange={formik.handleChange}
              style={{
                color: formik.values.courseType === "" ? "#9ea6ab" : "#131F2A",
              }}
              invalid={formik.submitCount > 0 && formik.errors.courseType}
            >
              <option key="select" value="" style={{ display: "none" }}>
                Tipo de Curso
              </option>
              {courseTypeList?.map((courseType, index) => (
                <option
                  key={`courseType${index}`}
                  value={courseType.id}
                  style={{ color: "#131F2A" }}
                >
                  {courseType.name}
                </option>
              ))}
            </Input>
            <Input
              name={"category"}
              type="select"
              value={formik.values.category}
              onChange={formik.handleChange}
              style={{
                color: formik.values.category === "" ? "#9ea6ab" : "#131F2A",
              }}
              invalid={formik.submitCount > 0 && formik.errors.category}
            >
              <option key="select" value="" style={{ display: "none" }}>
                Selecciona Categoría
              </option>
              {categoriesList?.map((category, index) => (
                <option
                  key={`category${index}`}
                  value={category.id}
                  style={{ color: "#131F2A" }}
                >
                  {category.name}
                </option>
              ))}
            </Input>
            <Input
              name={"subCategory"}
              type="select"
              value={formik.values.subCategory}
              onChange={formik.handleChange}
              style={{
                color: formik.values.subCategory === "" ? "#9ea6ab" : "#131F2A",
              }}
              invalid={formik.submitCount > 0 && formik.errors.subCategory}
              disabled={formik.values.category === ""}
            >
              <option key="select" value="" style={{ display: "none" }}>
                Selecciona Subcategoría
              </option>
              {subcategoriesList?.map((subcategory, index) => (
                <option
                  key={`subcategory${index}`}
                  value={subcategory.id}
                  style={{ color: "#131F2A" }}
                >
                  {subcategory.name}
                </option>
              ))}
            </Input>
          </div>
          <div className="rowFields">
            <DropZone
              onChange={(file) => formik.setFieldValue("courseMaterial", file)}
              pictures={formik.values.courseMaterial}
              multiple={false}
              typeFiles={["application/pdf"]}
              title={"Material del curso"}
              invalid={formik.submitCount > 0 && formik.errors.courseMaterial}
            />
            <DropZone
              onChange={(file) => formik.setFieldValue("courseAudio", file)}
              pictures={formik.values.courseAudio}
              multiple={false}
              typeFiles={["audio/mpeg"]}
              title={"Selecciona audio"}
              invalid={formik.submitCount > 0 && formik.errors.courseAudio}
            />
            <TimePicker
              placeholder="Tiempo estimado"
              showSecond={false}
              className={
                formik.submitCount > 0 && formik.errors.estimatedTime
                  ? "time-picker-error"
                  : "time-picker"
              }
              onChange={(e) =>
                formik.setFieldValue("estimatedTime", e ? e : null)
              }
              defaultOpenValue={moment().set({ hour: 0, minute: 0 })}
              value={formik.values.estimatedTime}
              inputReadOnly
              focusOnOpen
            />
          </div>
          <div className="rowFields">
            <Input
              placeholder="Título del Curso"
              name={"title"}
              value={formik.values.title}
              onChange={formik.handleChange}
              invalid={formik.submitCount > 0 && formik.errors.title}
              style={{ width: "100%" }}
            />
          </div>
          <div className="rowFields">
            <Input
              placeholder="Descripción del Curso"
              type="textarea"
              name={"description"}
              value={formik.values.description}
              onChange={formik.handleChange}
              invalid={formik.submitCount > 0 && formik.errors.description}
            />
          </div>
          <div className="rowFields">
            <Input
              placeholder="Descripción en la web"
              type="textarea"
              name={"description_web"}
              value={formik.values.description_web}
              onChange={formik.handleChange}
              invalid={formik.submitCount > 0 && formik.errors.description_web}
            />
          </div>
          <div className="rowFields">
            <DropZone
              onChange={(file) => formik.setFieldValue("coursePicture", file)}
              pictures={formik.values.coursePicture}
              multiple={false}
              typeFiles={["image/jpeg", "image/png"]}
              title={"Portada del Curso"}
              invalid={formik.submitCount > 0 && formik.errors.coursePicture}
            />
            <Input
              name={"mentor"}
              type="select"
              value={formik.values.mentor}
              onChange={formik.handleChange}
              style={{
                color: formik.values.mentor === "" ? "#9ea6ab" : "#131F2A",
              }}
              invalid={formik.submitCount > 0 && formik.errors.mentor}
            >
              <option key="select" value="" style={{ display: "none" }}>
                Selecciona Mentor
              </option>
              {mentorsList?.map((mentor, index) => (
                <option
                  key={`subcategory${index}`}
                  value={mentor.id}
                  style={{ color: "#131F2A" }}
                >
                  {mentor.name}
                </option>
              ))}
            </Input>
            <Input
              placeholder="Código"
              name={"code"}
              value={formik.values.code}
              onChange={formik.handleChange}
              invalid={formik.submitCount > 0 && formik.errors.code}
            />
          </div>
          <div className="rowFields">
            <Input
              placeholder="Precio del Curso"
              type="number"
              name={"price"}
              value={formik.values.price}
              onChange={formik.handleChange}
              invalid={formik.submitCount > 0 && formik.errors.price}
            />
            <Input
              placeholder="Precio en la web"
              type="number"
              name={"course_price_web"}
              value={formik.values.course_price_web}
              onChange={formik.handleChange}
              invalid={formik.submitCount > 0 && formik.errors.course_price_web}
            />
            <Input
              name={"assessmentType"}
              type="select"
              value={formik.values.assessmentType}
              onChange={formik.handleChange}
              style={{
                color:
                  formik.values.assessmentType === "" ? "#9ea6ab" : "#131F2A",
              }}
              invalid={formik.submitCount > 0 && formik.errors.assessmentType}
              disabled={
                formik.values.courseType === "" ||
                courseTypeList.find(
                  (courseType) => courseType.id == formik.values.courseType
                )?.id === 1
              }
            >
              <option key="select" value="" style={{ display: "none" }}>
                Selecciona Tipo de Evaluación
              </option>
              <option value={"CATEGORY"} style={{ color: "#131F2A" }}>
                Categoría
              </option>
              <option value={"COURSE"} style={{ color: "#131F2A" }}>
                Curso Individual
              </option>
            </Input>
          </div>
          <div className="rowFields">
            <Input
              placeholder="Horas crédito"
              type="number"
              name={"creditHours"}
              value={formik.values.creditHours}
              onChange={formik.handleChange}
              invalid={formik.submitCount > 0 && formik.errors.creditHours}
            />
          </div>

          <div className="buttons">
            <div className="coursePicturePreview">
              {formik.values.coursePicture?.length > 0 && (
                <>
                  <button
                    onClick={() => formik.setFieldValue("coursePicture", [])}
                  >
                    <Icon path={mdiClose} color="black" size="16px" />
                  </button>
                  <img src={formik.values.coursePicture[0].preview} />
                </>
              )}
            </div>
            <Button
              type="submit"
              color="primary"
              disabled={
                isSendingRequest === "LOADING" || validateEqualInitialData()
              }
            >
              <span style={{ marginRight: "8px" }}>Subir Curso</span>
              {isSendingRequest === "LOADING" && (
                <Spinner color="light" size="sm" />
              )}
            </Button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default CreateCourse;
