import React, { useState, useEffect } from "react";
import {
  Modal,
  Input,
  ModalBody,
  Button,
  InputGroup,
  InputGroupText,
} from "reactstrap";
import { Grid, IconButton, TextField } from "@mui/material";
import Icon from "@mdi/react";
import { mdiClose } from "@mdi/js";

import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { updateDiscountCoupon } from "../../../redux/actions/discountsActions";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";

const CreateDiscountCouponModal = ({
  isOpenModal,
  closeModal,
  createCoupon,
  createMode,
  initialData,
  page,
  rowsPerPage,
}) => {
  const dispatch = useDispatch();
  const modalStatus = useSelector((state) => state.discounts.modalActionStatus);
  const [code, setCode] = useState("");
  const [discount, setDiscount] = useState("");
  const [startDate, setStartDate] = useState(moment());
  const [endDate, setEndDate] = useState(moment());
  const [submittedForm, setSubmittedForm] = useState(false);

  useEffect(() => {
    if (!createMode) {
      setCode(initialData?.code);
      setDiscount(initialData?.discount);
      setStartDate(initialData ? moment(initialData?.start_date) : moment());
      setEndDate(initialData ? moment(initialData?.end_date) : moment());
    }
  }, [isOpenModal]);

  useEffect(() => {
    if (modalStatus === "COMPLETED") handleClose();
  }, [modalStatus]);

  const handleCreateCoupon = () => {
    setSubmittedForm(true);
    if (!handleError(code, discount)) {
      let bodyFormData = new FormData();
      bodyFormData.append("code", code);
      bodyFormData.append("discount", discount);
      bodyFormData.append("start_date", startDate.toISOString());
      bodyFormData.append("end_date", endDate.toISOString());
      bodyFormData.append("is_active", "True");
      createCoupon(bodyFormData);
    }
  };

  const handleUpdateCoupon = () => {
    setSubmittedForm(true);
    if (!handleError(code, discount)) {
      const body = {
        id: initialData.id,
        code: code,
        discount: discount,
        start_date: startDate.toISOString(),
        end_date: endDate.toISOString(),
        is_active: initialData.is_active,
      };
      dispatch(
        updateDiscountCoupon(
          body,
          {
            page: page + 1,
            page_size: rowsPerPage,
          },
          1 //Type normal PUT petition
        )
      );
    }
  };

  const handleChangeInput = (e, setInput) => {
    setInput(e.target.value);
  };

  const handleError = (field1, field2) =>
    field1 === "" || field2 === "" ? true : false;

  const handleClose = () => {
    setCode("");
    setDiscount("");
    setStartDate(moment());
    setEndDate(moment());
    setSubmittedForm(false);
    closeModal();
  };

  const disableButtonValidation = () => {
    if (createMode) {
      return modalStatus === "LOADING";
    } else {
      return (
        initialData?.code === code &&
        initialData?.discount === discount &&
        initialData?.start_date === startDate &&
        initialData?.end_date === endDate
      );
    }
  };

  return (
    <Modal isOpen={isOpenModal} centered className="createCategoryModal">
      <div className="containButton">
        <IconButton onClick={handleClose} className="closeButton">
          <Icon path={mdiClose} color="#131F2A" size="23px" />
        </IconButton>
      </div>
      <ModalBody>
        <form>
          <Input
            placeholder="Nombre del cupón"
            onChange={(e) => handleChangeInput(e, setCode)}
            invalid={handleError(code) && submittedForm}
            value={code}
          />
          <LocalizationProvider dateAdapter={AdapterMoment}>
            <Grid container spacing={2}>
              <Grid item sm={4}>
                <InputGroup>
                  <Input
                    placeholder="Descuento"
                    type="number"
                    onChange={(e) => handleChangeInput(e, setDiscount)}
                    invalid={handleError(code) && submittedForm}
                    value={discount}
                  />
                  <InputGroupText className="inputGroupRight">%</InputGroupText>
                </InputGroup>
              </Grid>
              <Grid item sm={4} className="datePickerStyles">
                <DesktopDatePicker
                  inputFormat="DD/MM/YYYY"
                  value={startDate}
                  minDate={moment()}
                  onChange={(e) => setStartDate(e)}
                  renderInput={(params) => <TextField {...params} />}
                />
              </Grid>
              <Grid item sm={4} className="datePickerStyles">
                <DesktopDatePicker
                  inputFormat="DD/MM/YYYY"
                  value={endDate}
                  minDate={moment()}
                  onChange={(e) => setEndDate(e)}
                  renderInput={(params) => <TextField {...params} />}
                />
              </Grid>
            </Grid>
          </LocalizationProvider>
          <div className="buttons">
            <Button className="secondarybutton" onClick={handleClose}>
              Cancelar
            </Button>
            <Button
              className="principalbutton"
              onClick={createMode ? handleCreateCoupon : handleUpdateCoupon}
              disabled={disableButtonValidation()}
            >
              Guardar
            </Button>
          </div>
        </form>
      </ModalBody>
    </Modal>
  );
};

export default CreateDiscountCouponModal;
