import { get, put, putFormData, postFormData, deleteMethod } from '../base/index';

export default {
    getCourses: (params) => {
        return get(`${process.env.REACT_APP_URL_BASE}/courses/`, params)
    },
    createCoursey: (body) => {
        return postFormData(`${process.env.REACT_APP_URL_BASE}/courses/`, body)
    },
    updateCourse: (body, typePutPetition) => {
        return typePutPetition === 2
            ? putFormData(`${process.env.REACT_APP_URL_BASE}/courses/`, body)
            : put(`${process.env.REACT_APP_URL_BASE}/courses/`, body)
    },
    deleteCourse: (id) => {
        return deleteMethod(`${process.env.REACT_APP_URL_BASE}/courses/${id}/`)
    },
    getAdvertisements: () => {
        return get(`${process.env.REACT_APP_URL_BASE}/advertisements/`)
    },
    updateAdvertisements: (body) => {
        return put(`${process.env.REACT_APP_URL_BASE}/advertisements/`, body)
    },
    getPodcasts: (params) => {
        return get(`${process.env.REACT_APP_URL_BASE}/podcasts/`, params)
    },
    createPodcast: (body) => {
        return postFormData(`${process.env.REACT_APP_URL_BASE}/podcasts/`, body)
    },
    updatePodcast: (body, typePutPetition) => {
        return typePutPetition === 2
            ? putFormData(`${process.env.REACT_APP_URL_BASE}/podcasts/`, body)
            : put(`${process.env.REACT_APP_URL_BASE}/podcasts/`, body)
    },
    deletePodcast: (id) => {
        return deleteMethod(`${process.env.REACT_APP_URL_BASE}/podcasts/${id}/`)
    },
    generateDownloadSignedUrl: (body) => {
        return postFormData(`${process.env.REACT_APP_URL_BASE}/generate-download-signed-url/`, body)
    },
    makePublicObjectGoogleStorage: (body) => {
        return postFormData(`${process.env.REACT_APP_URL_BASE}/make-public-object-google-storage/`, body)
    },
}