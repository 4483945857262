import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Spinner, Input } from "reactstrap";
import moment from "moment";
import "moment/locale/es";
import Table from "../../commons/components/Table";
import DownloadButton from "../../commons/components/tableComponents/DownloadButton";
import { Box, IconButton, Tab, Tabs } from "@mui/material";
import Icon from "@mdi/react";
import { Modal, ModalBody, Button, ModalHeader, ModalFooter } from "reactstrap";
import { mdiClose } from "@mdi/js";
import {
  getCategories,
  getSubcategories,
} from "../../redux/actions/categoriesActions";
import { getCourses } from "../../redux/actions/coursesActions";
import { getSubscriptions } from "../../redux/actions/subscriptionsActions";
import { toast } from "react-toastify";
import downloadDocument from "../../commons/functions/downloadDocument";

import {
  getUser,
  addUserSubscriptions,
  addUserCourse,
} from "../../redux/actions/usersActions";
import { getAttendances } from "../../redux/actions/commonsActions";

const columnsTable = [
  {
    value: "id",
    title: "No",
    isCustom: false,
  },
  {
    value: "title",
    title: "Curso",
    isCustom: false,
  },
  {
    value: "date",
    title: "Fecha",
    isCustom: false,
  },
];
const columnsTableAttendances = [
  {
    value: "id",
    title: "No",
    isCustom: false,
  },
  {
    value: "title",
    title: "Curso",
    isCustom: false,
  },
  {
    value: "pre_test",
    title: "Descargar pre-prueba",
    isCustom: true,
    CustomElement: DownloadButton,
    state: "users.userById",
    fieldValidation: null,
  },
  {
    value: "post_test",
    title: "Descargar post-prueba",
    isCustom: true,
    CustomElement: DownloadButton,
    state: "users.userById",
    fieldValidation: null,
  },
];

const handleData = (data) =>
  data.map(({ id, course, status, is_deleted, created_at }) => ({
    id,
    title: course?.title,
    date: moment(created_at).format("DD/MM/YYYY"),
  }));

const handleDataAttendance = (data) =>
  data.map(({ id, course, status, is_deleted, created_at }) => ({
    id,
    title: course?.title,
    status,
    is_deleted,
  }));

const TabPanel = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`course-tabpanel-${index}`}
      aria-labelledby={`course-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
};

const a11yProps = (index) => {
  return {
    id: `course-tab-${index}`,
    "aria-controls": `course-tabpanel-${index}`,
  };
};

const User = () => {
  const { userId } = useParams();
  const dispatch = useDispatch();
  const user = useSelector((state) => state.users.userById);
  const isFetching = useSelector((state) => state.users.isFetching);
  const categoriesList = useSelector(
    (state) => state.categories.categories?.data
  );
  const subcategoriesList = useSelector(
    (state) => state.categories.subcategories?.data
  );
  const isLoadingCategories = useSelector(
    (state) => state.categories.isFetching
  );
  const subscriptionsList = useSelector(
    (state) => state.subscriptions.subscriptions?.data
  );
  const isLoadingSubscriptions = useSelector(
    (state) => state.subscriptions.isFetching
  );
  const coursesList = useSelector((state) => state.courses.courses?.data);

  const [isAddingSubscription, setIsAddingSubscription] = useState(false);
  const [addSubscription, setAddSubscription] = useState(false);
  const [addCourse, setAddCourse] = useState(false);
  const [submitAddSubscription, setSubmitAddSubscription] = useState(false);
  const [categorySubscriptionSelected, setCategorySubscriptionSelected] =
    useState("");
  const [subscriptionTypeSelected, setSubscriptionTypeSelected] = useState("");
  const [subscriptionPeriodSelected, setSubscriptionPeriodSelected] =
    useState("");
  const [isAddingCourse, setIsAddingCourse] = useState(false);
  const [submitdAddCourse, setSubmitdAddCourse] = useState(false);
  const [categoryCourseSelected, setCategoryCourseSelected] = useState("");
  const [subcategoryCourseSelected, setSubcategoryCourseSelected] =
    useState("");
  const [courseSelected, setCourseSelected] = useState("");
  const AttendancesList = useSelector((state) => state.commons.attendances);
  const [isLoading, setIsLoading] = useState(true);
  const [value, setValue] = React.useState(0);

  useEffect(() => {
    dispatch(getUser(userId));
    if (userId) {
      dispatch(
        getAttendances(
          {
            data_all: "YES",
            user_id: userId,
          },
          () => setIsLoading(false)
        )
      );
    }
  }, []);

  useEffect(() => {
    if (categoryCourseSelected !== "") {
      setSubcategoryCourseSelected("");
      setCourseSelected("");
      dispatch(
        getSubcategories({
          data_all: "YES",
          category_id: categoryCourseSelected,
        })
      );
    }
  }, [categoryCourseSelected]);

  useEffect(() => {
    if (subcategoryCourseSelected !== "") {
      setCourseSelected("");
      dispatch(
        getCourses({
          data_all: "YES",
          subcategory_id: subcategoryCourseSelected,
          course_type_id: 2,
        })
      );
    }
  }, [subcategoryCourseSelected]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const onAddSubscription = () => {
    setAddSubscription(true);
    dispatch(
      getSubscriptions({
        data_all: "YES",
      })
    );
    dispatch(
      getCategories({
        data_all: "YES",
      })
    );
  };

  const onAddCourse = () => {
    setAddCourse(true);
    dispatch(
      getCategories({
        data_all: "YES",
      })
    );
  };

  const handleAddSuscription = () => {
    setSubmitAddSubscription(true);
    if (
      categorySubscriptionSelected === "" ||
      subscriptionTypeSelected === "" ||
      subscriptionPeriodSelected === "annual_price"
    ) {
      toast.error("Todos los campos son obligatorios", {
        autoClose: 3000,
        theme: "colored",
      });
    } else {
      setIsAddingSubscription(true);
      const body = {
        user_id: userId,
        subscription_type_id: subscriptionTypeSelected,
        category_id: categorySubscriptionSelected,
        start_date: `${moment().format("YYYY-MM-DD")}T05:00:00Z`,
        end_date: `${moment()
          .add(1, subscriptionPeriodSelected === "MONTHLY" ? "months" : "years")
          .format("YYYY-MM-DD")}T05:00:00Z`,
        subscription_period: subscriptionPeriodSelected,
        status: "ACTIVE",
        price: subscriptionsList?.filter((sub) => sub.id == 1)?.[0]?.[
          subscriptionPeriodSelected === "MONTHLY"
            ? "monthly_price"
            : "annual_price"
        ],
      };
      dispatch(addUserSubscriptions(body, userId, onCloseModalAddSubscription));
    }
  };

  const onCloseModalAddSubscription = () => {
    setIsAddingSubscription(false);
    setAddSubscription(false);
    setSubmitAddSubscription(false);
    setCategorySubscriptionSelected("");
    setSubscriptionTypeSelected("");
    setSubscriptionPeriodSelected("");
  };

  const onCloseModalAddCourse = () => {
    setIsAddingCourse(false);
    setAddCourse(false);
    setSubmitdAddCourse(false);
    setCourseSelected("");
    setSubcategoryCourseSelected("");
    setCategoryCourseSelected("");
  };

  const handleAddCourse = () => {
    setSubmitdAddCourse(true);
    if (
      categoryCourseSelected === "" ||
      subcategoryCourseSelected === "" ||
      courseSelected === ""
    ) {
      toast.error("Todos los campos son obligatorios", {
        autoClose: 3000,
        theme: "colored",
      });
    } else {
      const body = {
        user_id: userId,
        course_id: courseSelected,
        status: "ACTIVE",
        price: coursesList.filter(
          (course) => course.id === parseInt(courseSelected)
        )[0]?.course_price,
      };
      setIsAddingCourse(true);
      dispatch(addUserCourse(body, userId, onCloseModalAddCourse));
    }
  };

  const handleDownloadReport = (courseId, { value }) => {
    console.log("value,courseId 💦💤", courseId, value);
    downloadDocument("assessmentPurchasedCourseReport", {
      user_id: userId,
      course_id: AttendancesList?.data.filter(
        (course) => course.id === courseId
      )[0]?.course?.id,
      type: value,
    });
  };

  return (
    <div className="userContainer">
      {user && !isFetching ? (
        <div>
          <div className="headerContainer">
            <div>
              <h4 className="title">Usuario</h4>
              <div className="leftHeader">
                <div className="personalData">
                  <h6 className="name">
                    {user?.name} {user?.first_name}
                  </h6>
                  <h5 className="numberCourses">
                    {" "}
                    {user?.fk_purchased_course_user?.length} Cursos comprados
                  </h5>
                  <div className="categories">
                    {user?.categories.map((category, index) => (
                      <h5 key={`category${index}`}>
                        {category?.name}
                        {index + 1 < user.categories.length && ","}
                      </h5>
                    ))}
                  </div>
                  <button
                    onClick={onAddSubscription}
                    style={{ minWidth: "180px" }}
                  >
                    Añadir suscripción
                  </button>
                  <button onClick={onAddCourse} style={{ minWidth: "180px" }}>
                    Añadir curso
                  </button>
                </div>
              </div>
            </div>
            <div className="rightHeader" style={{ marginBottom: "50px" }}>
              <h6 style={{ fontWeight: 600, padding: "22px 0px 22px 55px" }}>
                Datos del Usuario
              </h6>
              <div className="rowTable">
                <h6>Usuario desde: </h6>
                <h6>
                  {moment(user?.date_joined).format("DD")} de{" "}
                  {moment(user?.date_joined).format("MMMM")}{" "}
                  {moment(user?.date_joined).year()}
                </h6>
              </div>
              <div className="rowTable">
                <h6>Correo Electrónico: </h6>
                <h6>{user?.email}</h6>
              </div>
              <div className="rowTable">
                <h6>ID: </h6>
                <h6>{user?.professional_id}</h6>
              </div>
              <div className="rowTable">
                <h6>Suscripción: </h6>
                <h6>
                  {user?.fk_user_subscription_user.length > 0
                    ? user?.fk_user_subscription_user
                        .map((sus) => sus?.category?.name)
                        .join(", ")
                    : "Sin suscripciones"}
                </h6>
              </div>
            </div>
          </div>
          <div style={{ marginBottom: "50px", width: "max-content" }}>
            <Box sx={{ bgcolor: "background.paper", width: 500 }}>
              <Tabs
                value={value}
                onChange={handleChange}
                aria-label="course"
                textColor="secondary"
                indicatorColor="secondary"
                className="tabsContent"
              >
                <Tab label="Cursos comprados" {...a11yProps(0)} />
                <Tab label="Cursos reproducidos" {...a11yProps(1)} />
              </Tabs>
            </Box>
            <TabPanel value={value} index={0}>
              <Table
                columns={columnsTable}
                data={handleData(user?.fk_purchased_course_user)}
                paginationDisabled={true}
                handleDownload={handleDownloadReport}
              />
            </TabPanel>
            <TabPanel value={value} index={1}>
              {AttendancesList && (
                <Table
                  columns={columnsTableAttendances}
                  data={handleDataAttendance(AttendancesList.data)}
                  paginationDisabled={true}
                  handleDownload={handleDownloadReport}
                />
              )}
            </TabPanel>
          </div>
          <Modal
            isOpen={addSubscription}
            centered
            className="deleteElementModal"
          >
            <ModalHeader>
              <span>Añadir suscripción</span>
              <IconButton
                className="closeButton"
                onClick={onCloseModalAddSubscription}
              >
                <Icon path={mdiClose} color="#131F2A" size="23px" />
              </IconButton>
            </ModalHeader>
            <ModalBody>
              {!isLoadingCategories &&
              !isLoadingSubscriptions &&
              categoriesList &&
              subscriptionsList ? (
                <div>
                  <Input
                    id="categorySelect"
                    type="select"
                    onChange={(e) =>
                      setCategorySubscriptionSelected(e.target.value)
                    }
                    invalid={
                      submitAddSubscription &&
                      categorySubscriptionSelected === ""
                    }
                  >
                    <option key={`CategoryDefault`} style={{ display: "none" }}>
                      Seleccionar Categoría
                    </option>
                    {categoriesList?.map((category, index) => (
                      <option key={`Category${index}`} value={category.id}>
                        {category.name}
                      </option>
                    ))}
                  </Input>
                  <Input
                    id="subscriptionSelect"
                    type="select"
                    onChange={(e) =>
                      setSubscriptionTypeSelected(e.target.value)
                    }
                    style={{ marginTop: 10 }}
                    invalid={
                      submitAddSubscription && subscriptionTypeSelected === ""
                    }
                  >
                    <option
                      key={`subscriptionDefault`}
                      style={{ display: "none" }}
                    >
                      Seleccionar Suscripción
                    </option>
                    {subscriptionsList?.map((subscription, index) => (
                      <option
                        key={`subscription${index}`}
                        value={subscription.id}
                      >
                        {subscription.name}
                      </option>
                    ))}
                  </Input>
                  <Input
                    id="periodSelect"
                    type="select"
                    onChange={(e) =>
                      setSubscriptionPeriodSelected(e.target.value)
                    }
                    style={{ marginTop: 10 }}
                    invalid={
                      submitAddSubscription && subscriptionPeriodSelected === ""
                    }
                  >
                    <option style={{ display: "none" }}>
                      {" "}
                      Seleccionar periodo{" "}
                    </option>
                    <option value="ANNUAL"> Anual </option>
                    <option value="MONTHLY"> Mensual </option>
                  </Input>
                </div>
              ) : (
                <Spinner style={{ marginLeft: "45%", marginTop: "46px" }} />
              )}
            </ModalBody>
            <ModalFooter>
              <Button
                color="primary"
                outline
                onClick={onCloseModalAddSubscription}
              >
                Cancelar
              </Button>
              <Button
                color="primary"
                disabled={
                  isLoadingCategories ||
                  isLoadingSubscriptions ||
                  isAddingSubscription
                }
                onClick={handleAddSuscription}
              >
                Guardar
              </Button>
            </ModalFooter>
          </Modal>
          <Modal isOpen={addCourse} centered className="deleteElementModal">
            <ModalHeader>
              <span> Añadir curso </span>
              <IconButton
                className="closeButton"
                onClick={onCloseModalAddCourse}
              >
                <Icon path={mdiClose} color="#131F2A" size="23px" />
              </IconButton>
            </ModalHeader>
            <ModalBody>
              {!!categoriesList ? (
                <div>
                  <Input
                    id="categoryCourseSelect"
                    type="select"
                    onChange={(e) => setCategoryCourseSelected(e.target.value)}
                    invalid={submitdAddCourse && categoryCourseSelected === ""}
                  >
                    <option key={`CategoryDefault`} style={{ display: "none" }}>
                      Seleccionar Categoría
                    </option>
                    {categoriesList?.map((category, index) => (
                      <option key={`Category${index}`} value={category.id}>
                        {category.name}
                      </option>
                    ))}
                  </Input>
                  <Input
                    id="subcategoryCourseSelect"
                    type="select"
                    onChange={(e) =>
                      setSubcategoryCourseSelected(e.target.value)
                    }
                    style={{ marginTop: 10 }}
                    invalid={
                      submitdAddCourse && subcategoryCourseSelected === ""
                    }
                    disabled={
                      categoryCourseSelected === "" || isLoadingCategories
                    }
                    value={subcategoryCourseSelected}
                  >
                    <option
                      key={`subcategoryDefault`}
                      style={{ display: "none" }}
                      value=""
                    >
                      Seleccionar Subcategoría
                    </option>
                    {subcategoriesList?.map((subcategory, index) => (
                      <option
                        key={`subcategory${index}`}
                        value={subcategory.id}
                      >
                        {subcategory.name}
                      </option>
                    ))}
                  </Input>
                  <Input
                    id="courseSelect"
                    type="select"
                    onChange={(e) => setCourseSelected(e.target.value)}
                    style={{ marginTop: 10 }}
                    invalid={submitdAddCourse && courseSelected === ""}
                    disabled={
                      subcategoryCourseSelected === "" || isLoadingCategories
                    }
                    value={courseSelected}
                  >
                    <option
                      key={`courseDefault`}
                      style={{ display: "none" }}
                      value=""
                    >
                      Seleccionar Curso
                    </option>
                    {coursesList?.map((course, index) => (
                      <option key={`course${index}`} value={course.id}>
                        {course.title}
                      </option>
                    ))}
                  </Input>
                  <h6 style={{ marginTop: "15px" }}>
                    <span style={{ fontWeight: 700 }}>Precio: </span>
                    {courseSelected
                      ? coursesList.filter(
                          (course) => course.id === parseInt(courseSelected)
                        )[0]?.course_price
                      : ""}
                  </h6>
                </div>
              ) : (
                <Spinner style={{ marginLeft: "45%", marginTop: "46px" }} />
              )}
            </ModalBody>
            <ModalFooter>
              <Button color="primary" outline onClick={onCloseModalAddCourse}>
                Cancelar
              </Button>
              <Button
                color="primary"
                disabled={isLoadingCategories || isAddingCourse}
                onClick={handleAddCourse}
              >
                Guardar
              </Button>
            </ModalFooter>
          </Modal>
        </div>
      ) : (
        <Spinner style={{ marginLeft: "45%", marginTop: "46px" }} />
      )}
    </div>
  );
};

export default User;
