import { createAction } from "redux-actions";
import api from "../../utils/api/apiClient";
import { toast } from "react-toastify";

export const fetchUsersRequest = createAction('FETCH_USERS_REQUEST');
export const fetchUpdateUserRequest = createAction('FETCH_UPDATE_USER_REQUEST');
export const fetchUsersSucess = createAction('FETCH_USERS_SUCCESS');
export const fetchMentorsSucess = createAction('FETCH_MENTORS_SUCCESS');
export const fetchUserByIdRequest = createAction('FETCH_USER_BY_ID_REQUEST');
export const fetchModalUserRequest = createAction('FETCH_MODAL_USER_REQUEST');
export const fetchModalUserSuccess = createAction('FETCH_MODAL_USER_SUCCESS');
export const fetchUsersError = createAction('FETCH_USERS_ERROR');

export const getUsers = (params) => async (dispatch) => {
    try {
        dispatch(fetchUsersRequest());
        const { data } = await api.users.getUsers({ params });
        dispatch(fetchUsersSucess(data));
    } catch (error) {
        errorFunction(error, dispatch);
    }
};

export const updateUser = (body, paramsGetUsers, id) => async (dispatch) => {
    try {
        await api.users.updateUser(body, id);
        toast.success('Usuario actualizado de forma exitosa', { autoClose: 3000, theme: "colored" });
        dispatch(getUsers(paramsGetUsers))
    } catch (error) {
        errorFunction(error, dispatch);
    }
};

export const getUser = (id) => async (dispatch) => {
    try {
        dispatch(fetchUsersRequest());
        const { data } = await api.users.getUser(id);
        dispatch(fetchUserByIdRequest(data));
    } catch (error) {
        errorFunction(error, dispatch);
    }
}

export const getMentors = (params) => async (dispatch) => {
    try {
        dispatch(fetchUsersRequest());
        const { data } = await api.users.getMentors({ params });
        dispatch(fetchMentorsSucess(data));
    } catch (error) {
        errorFunction(error, dispatch);
    }
};

export const createMentor = (body, paramsGetMentors, resetPagination) => async (dispatch) => {
    try {
        dispatch(fetchModalUserRequest());
        await api.users.createMentor(body);
        dispatch(fetchModalUserSuccess());
        toast.success('Mentor creado de forma exitosa', { autoClose: 3000, theme: "colored" });
        await dispatch(getMentors(paramsGetMentors))
        resetPagination();
    } catch (error) {
        errorFunction(error, dispatch);
    }
}

export const deleteMentor = (id, paramsGetMentors) => async (dispatch) => {
    try {
        await api.users.deleteMentor(id);
        toast.success('Conferenciante eliminado de forma exitosa', { autoClose: 3000, theme: "colored" });
        dispatch(getMentors(paramsGetMentors))
    } catch (error) {
        errorFunction(error, dispatch);
    }
}

export const updateMentor = (body, paramsGetMentors, typePutPetition) => async (dispatch) => {
    try {
        dispatch(fetchModalUserRequest());
        await api.users.updateMentor(body, typePutPetition);
        dispatch(fetchModalUserSuccess());
        toast.success('Conferenciante actualizado de forma exitosa', { autoClose: 3000, theme: "colored" });
        dispatch(getMentors(paramsGetMentors))
    } catch (error) {
        errorFunction(error, dispatch);
    }
}

export const addUserSubscriptions = (body, idUser, handleClose) => async (dispatch) => {
    try {
        await api.users.addUserSubscription(body);
        toast.success('Suscripción añadida de forma exitosa', { autoClose: 3000, theme: "colored" });
        handleClose();
        dispatch(getUser(idUser));
    } catch (error) {
        errorFunction(error, dispatch);
    }
}

export const addUserCourse = (body, idUser, handleClose) => async (dispatch) => {
    try {
        await api.users.addUserCourse(body);
        toast.success('Curso añadido de forma exitosa', { autoClose: 3000, theme: "colored" });
        handleClose();
        dispatch(getUser(idUser));
    } catch (error) {
        errorFunction(error, dispatch);
    }
}

const errorFunction = (error, dispatch) => {
    const errorMessage = error?.response?.data?.message ? error.response.data.message : error.message
    dispatch(fetchUsersError(errorMessage));
    toast.error(errorMessage, { autoClose: 3000, theme: "colored" });
}