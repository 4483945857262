import React, { useRef } from 'react'
import { Input } from 'reactstrap';
import Icon from '@mdi/react';
import { mdiDrag, mdiCheckboxBlankCircleOutline, mdiClose, mdiCheckboxBlankOutline } from '@mdi/js';
import { useDrag, useDrop } from 'react-dnd'
import { toast } from "react-toastify";

const ResponseOptionItem = ({ indexQuestion, index, formik, moveListItem, type, setOptionsListToDelete }) => {
    const [{ isDragging }, dragRef] = useDrag({
        type: 'item',
        item: { index },
        collect: (monitor) => ({
            isDragging: monitor.isDragging(),
        }),
    });

    const [spec, dropRef] = useDrop({
        accept: 'item',
        hover: (item, monitor) => {
            const dragIndex = item.index
            const hoverIndex = index
            const hoverBoundingRect = ref.current?.getBoundingClientRect()
            const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2
            const hoverActualY = monitor.getClientOffset().y - hoverBoundingRect.top

            if (dragIndex < hoverIndex && hoverActualY < hoverMiddleY) return
            if (dragIndex > hoverIndex && hoverActualY > hoverMiddleY) return

            moveListItem(indexQuestion, dragIndex, hoverIndex)
            item.index = hoverIndex
        },
    })

    const ref = useRef(null)
    const dragDropRef = dragRef(dropRef(ref))

    const HandleDeleteOption = () => {
        if (formik.values.questions[indexQuestion].multiResponses.length > 2) {
            if (!!formik.values.questions[indexQuestion].multiResponses[index].id) {
                setOptionsListToDelete(formik.values.questions[indexQuestion].multiResponses[index].id)
            }
            formik.setFieldValue(
                `questions[${indexQuestion}].multiResponses`,
                formik.values.questions[indexQuestion].multiResponses.filter((response, i) => i !== index)
            )
        }
        else {
            toast.error("Se deben de tener mínimo 2 opciones", { autoClose: 3000, theme: "colored" });
        }
    }

    return (
        <div className='responseOptionContainer'>
            <div className='dragIcon' ref={dragDropRef}>
                <Icon path={mdiDrag} color="#131F2A" size="30px" />
            </div>
            <Icon path={type === "single_option" ? mdiCheckboxBlankCircleOutline : mdiCheckboxBlankOutline} size="20px" className="indicatorOption" />
            <Input
                id={`questions[${indexQuestion}].multiResponses[${index}].text`}
                onChange={formik.handleChange}
                value={formik.values.questions[indexQuestion].multiResponses[index].text}
                placeholder='Escribe una opción'
                invalid={formik.submitCount > 0 && !!formik.errors?.questions?.[indexQuestion]?.multiResponses?.[index]}
            />
            <div className='deleteElement' onClick={HandleDeleteOption}>
                <Icon path={mdiClose} size="20px" />
            </div>
        </div>
    )
}

export default ResponseOptionItem;