import { configureStore } from "@reduxjs/toolkit"
import { authReducer, usersReducer, categoriesReducer, coursesReducer, commonsReducer, subscriptionsReducer, assessmentsReducer, dashboardReducer, discountsReducer } from "../../redux/reducers/index";

const reducer = {
    auth: authReducer,
    users: usersReducer,
    categories: categoriesReducer,
    courses: coursesReducer,
    commons: commonsReducer,
    subscriptions: subscriptionsReducer,
    assessments: assessmentsReducer,
    dashboard: dashboardReducer,
    discounts: discountsReducer,
};

const store = configureStore({
    reducer,
    devTools: process.env.NODE_ENV !== 'production'
});

export default store;