import React, { useState, useEffect } from 'react';
import { Modal, Input, ModalBody, Button } from 'reactstrap';
import DropZone from '../../../commons/components/DropZone';
import { IconButton } from '@mui/material';
import Icon from '@mdi/react';
import { mdiClose } from '@mdi/js';

import { useDispatch, useSelector } from 'react-redux';
import { updateSubcategory } from "../../../redux/actions/categoriesActions";

const CreateSubcategoryModal = ({ isOpenModal, closeModal, createSubcategory, createMode, initialData, page, rowsPerPage, dataFilter, categorySelected }) => {
    const dispatch = useDispatch();
    const modalStatus = useSelector((state) => state.categories.modalActionStatus);
    const [name, setName] = useState("");
    const [photo, setPhoto] = useState([]);
    const [submittedForm, setSubmittedForm] = useState(false);

    useEffect(() => {
        if (!createMode) {
            setName(initialData?.name)
            setPhoto([{
                name: initialData?.image_url?.slice(initialData?.image_url.lastIndexOf('/') + 1)
            }])
        }
    }, [isOpenModal])

    useEffect(() => {
        if (modalStatus === "COMPLETED")
            handleClose();
    }, [modalStatus])

    const handleCreateSubcategory = () => {
        setSubmittedForm(true);
        if (!handleError(name) && photo.length > 0) {
            let bodyFormData = new FormData();
            bodyFormData.append("name", name);
            bodyFormData.append("image_url", photo[0]);
            bodyFormData.append("is_active", 'True');
            bodyFormData.append("is_featured", 'True');
            bodyFormData.append("category_id", categorySelected);

            createSubcategory(bodyFormData);
        }
    }

    const handleUpdateSubcategory = () => {
        setSubmittedForm(true);
        if (!handleError(name) && photo.length > 0) {
            if (photo[0]?.name === initialData?.image_url.slice(initialData?.image_url.lastIndexOf('/') + 1)) { //if the photo is the same of initialData
                const body = {
                    id: initialData?.id,
                    name,
                    image_url: initialData?.image_url,
                    is_active: initialData?.is_active,
                    is_featured: initialData?.is_featured,
                    category_id: initialData?.category?.id
                }

                dispatch(updateSubcategory(
                    body,
                    {
                        page: page + 1,
                        page_size: rowsPerPage,
                        ...dataFilter
                    },
                    1 //Type normal PUT petition
                ))
            }
            else {
                let bodyFormData = new FormData();
                bodyFormData.append("id", initialData?.id);
                bodyFormData.append("name", name);
                bodyFormData.append("image_url", photo[0]);
                bodyFormData.append("is_active", initialData?.is_active);
                bodyFormData.append("is_featured", initialData?.is_featured);
                bodyFormData.append("category_id", initialData?.category?.id);

                dispatch(updateSubcategory(
                    bodyFormData,
                    {
                        page: page + 1,
                        page_size: rowsPerPage.Button,
                        ...dataFilter
                    },
                    2 //Type formData PUT petition
                ))
            }
        }
    }

    const handleChangeInput = (e, setInput) => {
        setInput(e.target.value)
    }

    const handleError = (field1, field2) => (
        field1 === '' || field2 === '' ? true : false
    )

    const handleClose = () => {
        setName("");
        setPhoto([]);
        setSubmittedForm(false);
        closeModal();
    }

    const disableButtonValidation = () => {
        if (createMode) {
            return modalStatus === "LOADING"
        }
        else {
            return (initialData?.name === name && photo[0]?.name === initialData?.image_url.slice(initialData?.image_url.lastIndexOf('/') + 1))
        }
    }

    return (
        <Modal isOpen={isOpenModal} centered className='createCategoryModal'>
            <div className='containButton'>
                <IconButton onClick={handleClose} className="closeButton">
                    <Icon path={mdiClose} color="#131F2A" size="23px" />
                </IconButton>
            </div>
            <ModalBody>
                <form>
                    <Input
                        placeholder='Nombre de la subcategoría'
                        onChange={(e) => handleChangeInput(e, setName)}
                        invalid={handleError(name) && submittedForm}
                        value={name}
                    />
                    <DropZone
                        onChange={setPhoto}
                        pictures={photo}
                        multiple={false}
                        typeFiles={["image/jpeg", "image/png"]}
                        title={"Subir Portada"}
                        invalid={photo.length === 0 && submittedForm}
                    />
                    <div className='buttons'>
                        <Button className='secondarybutton' onClick={handleClose}>Cancelar</Button>
                        <Button className='principalbutton' onClick={createMode ? handleCreateSubcategory : handleUpdateSubcategory} disabled={disableButtonValidation()}>Guardar</Button>
                    </div>
                </form>
            </ModalBody>
        </Modal>
    )
}

export default CreateSubcategoryModal;