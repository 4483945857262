import React from 'react';

const TwoRowsText = ({ initialValue }) => {
    return (
        <h6 className='twoRowsText'>
            {initialValue}
        </h6>
    )
}

export default TwoRowsText;