import React, { useState } from 'react';
import { Button, Input, Spinner } from 'reactstrap';

import mainLogo from '../../commons/img/logo.svg';
import { useSelector, useDispatch } from 'react-redux';
import { login } from '../../redux/actions/authActions';

const Login = () => {
    const dispatch = useDispatch();
    const isFetching = useSelector((state) => state.auth.isFetching);
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [submittedForm, setSubmittedForm] = useState(false);

    const HandleLogin = (e) => {
        e.preventDefault();
        setSubmittedForm(true);
        if (!handleError(email, password)) dispatch(login({
            "email": email,
            "password": password
        }));
    }

    const handleError = (field1, field2) => (
        field1 === '' || field2 === '' ? true : false
    )

    return (
        <div className='loginContainer'>
            <img src={mainLogo} />
            <div className='loginForm'>
                <form onSubmit={(e) => HandleLogin(e)}>
                    <Input className='emailInput' placeholder='Correo Electrónico' invalid={handleError(email) && submittedForm} onChange={(e) => setEmail(e.target.value)} />
                    <Input className='passwordInput' placeholder='Contraseña' type='password' invalid={handleError(password) && submittedForm} onChange={(e) => setPassword(e.target.value)} />
                    {
                        isFetching
                            ? <Button className="loadingButton">
                                <Spinner className="spinnerLoadingButton" />
                            </Button>
                            : <Button color='primary' type='submit' style={{ width: "100%" }}>Iniciar Sesión</Button>
                    }
                </form>
                {handleError(email, password) && submittedForm && <h6 className='errorWarning'>*Campos obligatorios</h6>}
            </div>
        </div>
    )
}

export default Login;