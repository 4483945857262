import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { Spinner, Input } from 'reactstrap';
import Table from '../../commons/components/Table';
import DownloadButton from '../../commons/components/tableComponents/DownloadButton';
import { rowsPerPageOptions } from "../../commons/constants/pagination";
import moment from 'moment';

import { getCategories, getSubcategories } from "../../redux/actions/categoriesActions";
import { getAttendances, getCourseCertificates } from "../../redux/actions/commonsActions";
import getUniqueValuesFromArray from '../../commons/functions/getUniqueValuesFromArray';

const columnsTable = [
    {
        value: 'id',
        title: 'No',
        isCustom: false
    },
    {
        value: 'userName',
        title: 'Nombre',
        isCustom: false
    },
    {
        value: 'first_name',
        title: 'apellidoPAT',
        isCustom: false
    },
    {
        value: 'last_name',
        title: 'apellidoMAT',
        isCustom: false
    },
    {
        value: 'email',
        title: 'Email',
        isCustom: false
    },
    {
        value: 'course',
        title: 'Curso',
        isCustom: false
    },
    {
        value: 'attendance_1',
        title: 'Asistencia 1',
        isCustom: false
    },
    {
        value: 'attendance_2',
        title: 'Asistencia 2',
        isCustom: false
    },
    {
        value: 'attendance_2',
        title: 'Fecha de finalización',
        isCustom: false
    },
    {
        value: 'categoría',
        title: 'Certificado',
        isCustom: true,
        CustomElement: DownloadButton,
        fieldValidation: 'end_date',
        state: "commons.attendances.data"
    }
]

const handleData = (data) => (
    data.map(({ id,
        user,
        course,
        attendance_1,
        attendance_2,
        end_date,
        categoría }) => ({
            id,
            userName: user.name,
            first_name: user.first_name,
            last_name: user.last_name,
            email: user.email,
            course: course.title,
            attendance_1: attendance_1 === null ? "" : moment(attendance_1).format("DD/MM/YYYY"),
            attendance_2: attendance_2 === null ? "" : moment(attendance_2).format("DD/MM/YYYY"),
            end_date: end_date === null ? "" : moment(end_date).format("DD/MM/YYYY"),
            categoría
        })
    )
)

const Attendance = () => {
    const dispatch = useDispatch();
    const categoriesList = useSelector((state) => state.categories.categories?.data);
    const subcategoriesList = useSelector((state) => state.categories.subcategories?.data);
    const AttendancesList = useSelector((state) => state.commons.attendances);
    const refTable = useRef(null);
    const [categorySelected, setCategorySelected] = useState("");
    const [subCategorySelected, setSubCategorySelected] = useState("");
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        dispatch(getCategories({
            data_all: "YES"
        }))
    }, [])

    useEffect(() => {
        if (categoriesList) {
            setCategorySelected(categoriesList[0]?.name)
        }
    }, [categoriesList])

    useEffect(() => {
        if (categorySelected !== "") {
            setIsLoading(true);
            dispatch(getSubcategories({
                data_all: "YES",
                category_id: categoriesList.filter(category => category?.name === categorySelected)[0]?.id
            },
                false
            ))
        }
    }, [categorySelected]);

    useEffect(() => {
        if (subcategoriesList) {
            setIsLoading(true);
            if (subcategoriesList.length === 0) {
                setSubCategorySelected("")
                attendancesRequest(1, rowsPerPageOptions[0], "")
            }
            else {
                setSubCategorySelected(subcategoriesList[0]?.name);
                attendancesRequest(1, rowsPerPageOptions[0], subcategoriesList[0]?.name)
            }
        }
    }, [subcategoriesList])

    const attendancesRequest = (page, page_size, subcategoryUser) => {
        if (categorySelected !== "") dispatch(getAttendances(
            subcategoryUser === "" ? {
                course__subcategory__category_id: categoriesList?.filter(category => category?.name === categorySelected)[0]?.id
            } : {
                page,
                page_size,
                course__subcategory_id: subcategoriesList?.filter(subcategory => subcategory?.name === subcategoryUser)[0]?.id
            },
            () => setIsLoading(false)
        ))
    }

    const handleDownload = (id) => {
        const element = AttendancesList.data.filter(el => el.id === id)[0];
        dispatch(getCourseCertificates({
            user_id: element?.user?.id,
            course_id: element?.course?.id
        }))
    }

    return (
        <div className='categoriesContainer'>
            <div className='headerContainer'>
                <div className='leftHeaderContainer'>
                    <h4>Asistencia</h4>
                    {categoriesList &&
                        <Input
                            id="categorySelect"
                            type="select"
                            onChange={e => setCategorySelected(e.target.value)}
                        >
                            {getUniqueValuesFromArray(categoriesList, d => d.name)?.map((category, index) => (
                                <option key={`Category${index}`}>
                                    {category.name}
                                </option>
                            ))}
                        </Input>
                    }
                    {subcategoriesList &&
                        <Input
                            id="categorySelect"
                            type="select"
                            onChange={e => {
                                setIsLoading(true);
                                setSubCategorySelected(e.target.value);
                                attendancesRequest(1, rowsPerPageOptions[0], e.target.value)
                            }}
                            style={{ marginLeft: 15 }}
                            value={subCategorySelected}
                        >
                            {getUniqueValuesFromArray(subcategoriesList, d => d.name)?.map((subcategory, index) => (
                                <option key={`Subcategory${index}`}>
                                    {subcategory.name}
                                </option>
                            ))}
                        </Input>
                    }
                </div>
            </div>
            {
                AttendancesList?.data && !isLoading
                    ? <Table
                        columns={columnsTable}
                        data={handleData(AttendancesList.data)}
                        changePage={(newPage, pageSize) => attendancesRequest(newPage + 1, pageSize, subCategorySelected)}
                        changeRowsPerPage={(pageSize) => attendancesRequest(1, pageSize, subCategorySelected)}
                        count={AttendancesList.count}
                        ref={refTable}
                        handleDownload={handleDownload}
                    />
                    : <Spinner style={{ marginLeft: "45%", marginTop: "46px" }} />
            }
        </div>
    )
}

export default Attendance;