import { createAction } from "redux-actions";
import { toast } from 'react-toastify';
import api from "../../utils/api/apiClient";

export const fetchAuthRequest = createAction("FETCH_AUTH_REQUEST");
export const fetchLoginSuccess = createAction("FETCH_LOGIN_SUCCESS");
export const fetchTokenRenovation = createAction("FETCH_TOKEN_RENOVATION");
export const fetchUserByTokenSuccess = createAction("FETCH_USER_BY_TOKEN_SUCCESS");
export const fetchPasswordResetSuccess = createAction("FETCH_PASSWORD_RESET_SUCCESS");
export const fetchlogout = createAction("LOGOUT");
export const fetchAuthError = createAction("FETCH_AUTH_ERROR");

export const login = (body) => async (dispatch) => {
  try {
    dispatch(fetchAuthRequest());
    const { data: { access } } = await api.auth.login(body);
    const response = await api.users.getUserByToken(access)
    if (response.data.groups.find(group => group.name === "admin") === undefined) {
      throw {
        message: "Usuario sin permisos de administrador"
      };
    }
    else {
      localStorage.setItem("token", access);
      dispatch(fetchLoginSuccess());
    }
  } catch (error) {
    const errorMessage = error?.response?.data?.detail ? error.response.data.detail : error.message
    dispatch(fetchAuthError(errorMessage));
    toast.error(errorMessage, { autoClose: 3000, theme: "colored" });
  }
};

export const logout = () => async (dispatch) => {
  dispatch(fetchlogout());
};

export const tokenRenovation = () => async (dispatch) => {
  dispatch(fetchLoginSuccess());
};

export const isFetchingTokenRenovation = () => async (dispatch) => {
  dispatch(fetchTokenRenovation());
};

export const getUserByToken = () => async (dispatch) => {
  try {
    const { data } = await api.auth.getUserByToken();
    dispatch(fetchUserByTokenSuccess(data));
  } catch (error) {
    const errorMessage = error?.response?.data?.detail ? error.response.data.detail : error.message
    toast.error(errorMessage, { autoClose: 3000, theme: "colored" });
  }
}

export const passwordReset = (body, callbackFunction) => async (dispatch) => {
  try {
    dispatch(fetchAuthRequest());
    const { data } = await api.auth.passwordResetConfirm(body);
    dispatch(fetchPasswordResetSuccess(data));
    callbackFunction();
  } catch (error) {
    const errorMessage = error?.response?.data?.password[0] ? error.response.data.password[0] : error.message
    toast.error(errorMessage, { autoClose: 3000, theme: "colored" });
    dispatch(fetchAuthError(errorMessage));
  }
}