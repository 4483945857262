import authApi from "./request/AuthApi";
import usersApi from "./request/UsersApi";
import CategoriesApi from "./request/CategoriesApi";
import CoursesApi from "./request/CoursesApi";
import Commons from "./request/Commons"
import SubscriptionsApi from "./request/SubscriptionsApi";
import AssessmentsApi from "./request/AssessmentsApi";
import DashboardApi from "./request/DashboardApi"
import DiscountsApi from "./request/DiscountsApi";

const api = {
    auth: authApi,
    users: usersApi,
    categories: CategoriesApi,
    courses: CoursesApi,
    commons: Commons,
    subscription: SubscriptionsApi,
    assessmentsApi: AssessmentsApi,
    dashboardApi: DashboardApi,
    discounts: DiscountsApi,
};

export default api;