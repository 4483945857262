import { handleActions } from 'redux-actions';
import {
    fetchCoursesRequest,
    fetchCoursesSuccess,
    fetchCoursesError,
    fetchModalCoursesRequest,
    fetchModalCoursesSuccess,
    fetchAdvertisementsSuccess,
    fetchPodcastSuccess
} from '../actions/coursesActions';

const defaultState = {
    courses: null,
    podcasts: null,
    advertisements: null,
    isFetching: false,
    modalActionStatus: 'STARTED',
    error: null,
};

export default handleActions(
    {
        [fetchCoursesRequest](state) {
            return {
                ...state,
                isFetching: true,
            };
        },
        [fetchCoursesSuccess](state, { payload: { results, count } }) {
            return {
                ...state,
                courses: {
                    data: results,
                    count
                },
                isFetching: false,
                modalActionStatus: 'STARTED',
                error: null
            };
        },
        [fetchCoursesError](state, { payload }) {
            return {
                ...state,
                isFetching: false,
                error: payload,
                modalActionStatus: 'STARTED',
            };
        },
        [fetchModalCoursesRequest](state) {
            return {
                ...state,
                modalActionStatus: 'LOADING',
            };
        },
        [fetchModalCoursesSuccess](state) {
            return {
                ...state,
                modalActionStatus: 'COMPLETED',
            };
        },
        [fetchAdvertisementsSuccess](state, { payload }) {
            return {
                ...state,
                isFetching: false,
                advertisements: payload
            };
        },
        [fetchPodcastSuccess](state, { payload: { results, count } }) {
            return {
                ...state,
                isFetching: false,
                modalActionStatus: 'STARTED',
                podcasts: {
                    data: results,
                    count
                }
            };
        }
    },
    defaultState,
);