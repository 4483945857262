import { get, post, deleteMethod, put } from '../base/index';

export default {
    getSubscriptions: (params) => {
        return get(`${process.env.REACT_APP_URL_BASE}/subscription_types/`, params)
    },
    createSubscription: (body) => {
        return post(`${process.env.REACT_APP_URL_BASE}/subscription_types/`, body)
    },
    deleteSubscription: (id) => {
        return deleteMethod(`${process.env.REACT_APP_URL_BASE}/subscription_types/${id}/`)
    },
    updateSubscription: (body) => {
        put(`${process.env.REACT_APP_URL_BASE}/subscription_types/`, body)
    }
}