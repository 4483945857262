import React from 'react';
import { useNavigate } from 'react-router-dom';

const Checkbox = ({ initialValue, id }) => {
    const navigate = useNavigate();

    const handleRedirect = () => {
        navigate(`/usuarios/Usuario/${id}`)
    }

    return (
        <h6 style={{ color: "#7C7C7C", textDecorationLine: "underline", cursor: "pointer" }} onClick={handleRedirect}>
            {initialValue}
        </h6>
    )
}

export default Checkbox;