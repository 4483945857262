import React, { useState, useEffect } from "react";
import {
  Modal,
  Input,
  ModalBody,
  Button,
  InputGroup,
  InputGroupText,
} from "reactstrap";
import { Grid, IconButton } from "@mui/material";
import Icon from "@mdi/react";
import { mdiClose } from "@mdi/js";

import { useDispatch, useSelector } from "react-redux";
import { updateDiscountRate } from "../../../redux/actions/discountsActions";

const CreateDiscountRateModal = ({
  isOpenModal,
  closeModal,
  createDiscountRate,
  createMode,
  initialData,
  page,
  rowsPerPage,
}) => {
  const dispatch = useDispatch();
  const modalStatus = useSelector((state) => state.discounts.modalActionStatus);
  const [amount, setAmount] = useState("");
  const [discount, setDiscount] = useState("");
  const [submittedForm, setSubmittedForm] = useState(false);

  useEffect(() => {
    if (!createMode) {
      setAmount(initialData?.amount);
      setDiscount(initialData?.discount);
    }
  }, [isOpenModal]);

  useEffect(() => {
    if (modalStatus === "COMPLETED") handleClose();
  }, [modalStatus]);

  const handleCreateDiscountRate = () => {
    setSubmittedForm(true);
    if (!handleError(amount, discount)) {
      let bodyFormData = new FormData();
      bodyFormData.append("amount", amount);
      bodyFormData.append("discount", discount);
      bodyFormData.append("is_active", "True");
      createDiscountRate(bodyFormData);
    }
  };

  const handleUpdateDiscountRate = () => {
    setSubmittedForm(true);
    if (!handleError(amount, discount)) {
      const body = {
        id: initialData.id,
        amount: amount,
        discount: discount,
        is_active: initialData.is_active,
      };
      dispatch(
        updateDiscountRate(
          body,
          {
            page: page + 1,
            page_size: rowsPerPage,
          },
          1 //Type normal PUT petition
        )
      );
    }
  };

  const handleChangeInput = (e, setInput) => {
    setInput(e.target.value);
  };

  const handleError = (field1, field2) =>
    field1 === "" || field2 === "" ? true : false;

  const handleClose = () => {
    setAmount("");
    setDiscount("");
    setSubmittedForm(false);
    closeModal();
  };

  const disableButtonValidation = () => {
    if (createMode) {
      return modalStatus === "LOADING";
    } else {
      return (
        initialData?.amount === amount && initialData?.discount === discount
      );
    }
  };

  return (
    <Modal isOpen={isOpenModal} centered className="createCategoryModal">
      <div className="containButton">
        <IconButton onClick={handleClose} className="closeButton">
          <Icon path={mdiClose} color="#131F2A" size="23px" />
        </IconButton>
      </div>
      <ModalBody>
        <form>
          <Input
            type="number"
            placeholder="# de cursos"
            onChange={(e) => handleChangeInput(e, setAmount)}
            invalid={handleError(amount) && submittedForm}
            value={amount}
          />
          <Grid container spacing={2}>
            <Grid item sm={12}>
              <InputGroup>
                <Input
                  placeholder="Descuento"
                  type="number"
                  onChange={(e) => handleChangeInput(e, setDiscount)}
                  invalid={handleError(amount) && submittedForm}
                  value={discount}
                />
                <InputGroupText className="inputGroupRight">%</InputGroupText>
              </InputGroup>
            </Grid>
          </Grid>
          <div className="buttons">
            <Button className="secondarybutton" onClick={handleClose}>
              Cancelar
            </Button>
            <Button
              className="principalbutton"
              onClick={
                createMode ? handleCreateDiscountRate : handleUpdateDiscountRate
              }
              disabled={disableButtonValidation()}
            >
              Guardar
            </Button>
          </div>
        </form>
      </ModalBody>
    </Modal>
  );
};

export default CreateDiscountRateModal;
