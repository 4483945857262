import { handleActions } from 'redux-actions';
import {
    fetchSubscriptionsRequest,
    fetchSubscriptionsSuccess,
    fetchSubscriptionsError
} from '../actions/subscriptionsActions';

const defaultState = {
    subscriptions: null,
    isFetching: false,
    error: null,
};

export default handleActions(
    {
        [fetchSubscriptionsRequest](state) {
            return {
                ...state,
                isFetching: true,
            };
        },
        [fetchSubscriptionsSuccess](state, { payload: { results, count } }) {
            return {
                ...state,
                subscriptions: {
                    data: results,
                    count
                },
                isFetching: false,
                error: null
            };
        },
        [fetchSubscriptionsError](state, { payload }) {
            return {
                ...state,
                isFetching: false,
                error: payload,
            };
        }
    },
    defaultState,
);