import React, { useRef, useCallback } from 'react'
import { Input } from 'reactstrap';
import Icon from '@mdi/react';
import { mdiTrashCanOutline, mdiDragHorizontal, mdiCheckboxBlankCircleOutline, mdiDrag, mdiCheckboxBlankOutline } from '@mdi/js';
import { useDrag, useDrop } from 'react-dnd'
import ResponseOptionItem from "./ResponseOptionItem"

const questionsTypeList = [{
    title: "Obligatoria",
    value: "required"
},
{
    title: "Opcional",
    value: "not_required"
}]

const responsesTypeList = [{
    title: "Campo de texto",
    value: "text_field"
},
{
    title: "Opción única",
    value: "single_option"
},
{
    title: "Varias opciones",
    value: "multiple_options"
}]

const QuestionItem = ({ index, moveListItem, formik, setQuestionsListToDelete, setOptionsListToDelete }) => {
    const [{ isDragging }, dragRef] = useDrag({
        type: 'item',
        item: { index },
        collect: (monitor) => ({
            isDragging: monitor.isDragging(),
        }),
    });

    const [spec, dropRef] = useDrop({
        accept: 'item',
        hover: (item, monitor) => {
            const dragIndex = item.index
            const hoverIndex = index
            const hoverBoundingRect = ref.current?.getBoundingClientRect()
            const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2
            const hoverActualY = monitor.getClientOffset().y - hoverBoundingRect.top

            if (dragIndex < hoverIndex && hoverActualY < hoverMiddleY) return
            if (dragIndex > hoverIndex && hoverActualY > hoverMiddleY) return

            moveListItem(dragIndex, hoverIndex)
            item.index = hoverIndex
        },
    })

    const ref = useRef(null)
    const dragDropRef = dragRef(dropRef(ref))

    const deleteQuestion = (indexDelete) => {
        if (!!formik.values.questions[indexDelete].id) {
            setQuestionsListToDelete(formik.values.questions[indexDelete].id)
        }
        formik.setFieldValue(
            "questions",
            formik.values.questions.filter((question, i) => i !== indexDelete)
        )
    }

    const handleResponseType = (index, value) => {
        if (value !== formik.values.questions[index].responseType) {
            formik.setFieldValue(`questions[${index}].responseType`, value)
            if (value === "text_field") {
                formik.setFieldValue(`questions[${index}].multiResponses`, [])
            }
            if (value === "multiple_options" || value === "single_option") {
                formik.setFieldValue(`questions[${index}].multiResponses`, [
                    {
                        text: "",
                        id: null
                    }, {
                        text: "",
                        id: null
                    }
                ])
            }
        }
    }

    const handleAddOption = () => {
        formik.setFieldValue(
            `questions[${index}].multiResponses`,
            [
                ...formik.values.questions[index].multiResponses,
                {
                    text: "",
                    id: null
                }
            ]
        )
    }

    const moveListResponse = useCallback((questionIndex, dragIndex, hoverIndex) => {
        const dragItem = formik.values.questions[questionIndex].multiResponses[dragIndex]
        const hoverItem = formik.values.questions[questionIndex].multiResponses[hoverIndex]
        const updatedQuestions = [...formik.values.questions[questionIndex].multiResponses]
        updatedQuestions[dragIndex] = hoverItem
        updatedQuestions[hoverIndex] = dragItem
        formik.setFieldValue(`questions[${questionIndex}].multiResponses`, updatedQuestions)
    }, [formik.values.questions])

    return (
        <div
            className='questionsContainer'
            style={{ opacity: isDragging ? 0.2 : 1 }}
        >
            <div className='dragIcon' ref={dragDropRef}>
                <Icon path={mdiDragHorizontal} color="#131F2A" size="30px" />
            </div>
            <div className='questionHeader'>
                <Input
                    id={`questions[${index}].questionText`}
                    onChange={formik.handleChange}
                    value={formik.values.questions[index].questionText}
                    placeholder='Escribe tu pregunta'
                    invalid={formik.submitCount > 0 && !!formik.errors?.questions?.[index]?.questionText}
                    style={{ marginRight: "15px" }}
                />
                <Input
                    id={`questions[${index}].questionType`}
                    type="select"
                    onChange={formik.handleChange}
                    value={formik.values.questions[index].questionType}
                    invalid={formik.submitCount > 0 && !!formik.errors?.questions?.[index]?.questionType}
                >
                    {
                        questionsTypeList.map(type => (
                            <option key={`type_${type.value}`} value={type.value} className="selectOption">
                                {type.title}
                            </option>
                        ))
                    }
                </Input>
                <Input
                    id={`questions[${index}].responseType`}
                    type="select"
                    onChange={e => handleResponseType(index, e.target.value)}
                    invalid={formik.submitCount > 0 && !!formik.errors?.questions?.[index]?.responseType}
                    value={formik.values.questions[index].responseType}
                >
                    {
                        responsesTypeList.map(type => (
                            <option key={`type_${type.value}`} value={type.value} className="selectOption">
                                {type.title}
                            </option>
                        ))
                    }
                </Input>
            </div>
            {(formik.values.questions[index].responseType === "multiple_options" || formik.values.questions[index].responseType === "single_option") &&
                <div>
                    {
                        formik.values.questions[index]?.multiResponses.map((response, indexResponse) =>
                            <ResponseOptionItem
                                indexQuestion={index}
                                index={indexResponse}
                                formik={formik}
                                moveListItem={moveListResponse}
                                key={`responseOption${indexResponse}`}
                                type={formik.values.questions[index].responseType}
                                setOptionsListToDelete={setOptionsListToDelete}
                            />
                        )
                    }
                    <div className="addOption">
                        <div className='dragIcon'>
                            <Icon path={mdiDrag} size="30px" />
                        </div>
                        <Icon path={formik.values.questions[index].responseType === "single_option" ? mdiCheckboxBlankCircleOutline : mdiCheckboxBlankOutline} size="17px" className='checkIndicator' />
                        <h6 onClick={handleAddOption}>Añadir otra opción</h6>
                    </div>
                </div>
            }
            <div className='dismissButton'>
                <button onClick={() => deleteQuestion(index)} disabled={formik.values.questions.length === 1} type="button">
                    Descartar
                    <Icon path={mdiTrashCanOutline} color="#7C7C7C" size="20px" style={{ marginLeft: "10px" }} />
                </button>
            </div>
        </div>
    )
}

export default QuestionItem;