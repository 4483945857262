import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { Spinner } from 'reactstrap';
import { getGeneralStatistics, getStatisticsUserRegister } from "../../redux/actions/dashboardActions";
import Icon from '@mdi/react';
import { mdiAccountCircleOutline, mdiContactlessPayment, mdiCashMultiple } from '@mdi/js';
import { LineChart, Line, XAxis, YAxis, Tooltip, CartesianGrid, ResponsiveContainer } from 'recharts';
import { DesktopDatePicker } from "@mui/x-date-pickers";
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import TextField from '@mui/material/TextField';
import moment from 'moment';

const cardStatic = [{
    title: "Usuarios",
    color: "#EB9218",
    icon: mdiAccountCircleOutline,
    value: "num_users"
}, {
    title: "Suscripciones",
    color: "#812E31",
    icon: mdiContactlessPayment,
    value: "num_subscriptions"
}, {
    title: "Cursos vendidos",
    color: "#131F2A",
    icon: mdiCashMultiple,
    value: "num_purchased_courses"
}]

const Dashboard = () => {
    const dispatch = useDispatch();
    const isLoading = useSelector(state => state.dashboard.isFetching);
    const generalStatistics = useSelector(state => state.dashboard.generalStatistics);
    const userRegisterStatistics = useSelector(state => state.dashboard.userRegisterStatistics);
    const [monthSelected, setMonthSelected] = useState(moment())

    useEffect(() => {
        dispatch(getGeneralStatistics());
        dispatch(getStatisticsUserRegister({
            start_date: moment(monthSelected).startOf('month').set({ hour: 0, minute: 0, second: 0 }).toISOString(),
            end_date: moment(monthSelected).endOf('month').set({ hour: 23, minute: 59, second: 59 }).toISOString(),
        }))
    }, []);

    useEffect(() => {
        dispatch(getStatisticsUserRegister({
            start_date: moment(monthSelected).startOf('month').set({ hour: 0, minute: 0, second: 0 }).toISOString(),
            end_date: moment(monthSelected).endOf('month').set({ hour: 23, minute: 59, second: 59 }).toISOString(),
        }))
    }, [monthSelected])

    const CustomTooltip = ({ active, payload, label }) => {
        if (active && payload && payload.length) {
            return (
                <div style={{ backgroundColor: "white", padding: "10px", border: "1px solid #E6E6E6" }}>
                    <p style={{ fontWeight: 600, fontSize: 14 }}>{`${moment(label, "DD-MM-YYYY").format("DD")} de ${moment(label, "DD-MM-YYYY").format("MMM")}`}</p>
                    <p style={{ fontWeight: 400, fontSize: 12 }}>{`${payload[0].value} usuarios nuevos`}</p>
                </div>
            );
        }

        return null;
    };

    const CustomizedTick = ({ payload, x, y }) => {
        return (
            <g transform={`translate(${x},${y})`}>
                <text
                    x={0}
                    y={0}
                    dy={16}
                    textAnchor="end"
                    fill="#666"
                >
                    {moment(payload.value, "DD-MM-YYYY").format("DD")}
                </text>
            </g>
        )
    }


    return (
        <div className='dashboardContainer'>
            {
                !isLoading && generalStatistics ?
                    <div>
                        <div style={{ display: "flex", gap: "27px", marginBottom: 50 }}>
                            {
                                cardStatic.map((card, index) =>
                                    <div className="cardContainer" key={`card${index}`}>
                                        <div className='iconContainer' style={{ backgroundColor: card.color }}>
                                            <Icon path={card.icon} className='icon' />
                                        </div>
                                        <div className='contentContainer'>
                                            <h6>{card.title}</h6>
                                            <h6>{generalStatistics[card.value]}</h6>
                                        </div>
                                    </div>
                                )
                            }
                        </div>
                        <div style={{ width: "100%", display: "flex", flexDirection: "column", alignItems: "flex-end", paddingRight: "75px" }}>
                            <div className='datePickerContainer'>
                                <LocalizationProvider dateAdapter={AdapterMoment}>
                                    <DesktopDatePicker
                                        inputFormat="MMMM YYYY"
                                        views={['month', 'year']}
                                        value={monthSelected}
                                        maxDate={moment()}
                                        onChange={e => setMonthSelected(e)}
                                        renderInput={(params) => <TextField {...params} />}
                                    />
                                </LocalizationProvider>
                            </div>
                            <div className='graphContainer'>
                                <h6 >Usuarios</h6>
                                <ResponsiveContainer width={"100%"} aspect={3}>
                                    <LineChart
                                        data={userRegisterStatistics}
                                    >
                                        <XAxis dataKey="day" tick={<CustomizedTick />} />
                                        <YAxis dataKey="amount" />
                                        <Tooltip content={<CustomTooltip />} />
                                        <CartesianGrid fill='white' horizontal={false} vertical={false} />
                                        <Line type="monotone" dataKey="amount" stroke="#131F2A" />
                                    </LineChart>
                                </ResponsiveContainer>
                            </div>
                        </div>
                    </div>
                    : <Spinner style={{ marginLeft: "45%", marginTop: "46px" }} />
            }
        </div>
    );
}

export default Dashboard;