import { handleActions } from 'redux-actions';
import {
    fetchCommonsRequest,
    fetchTermsAndConditionsSuccess,
    fetchNotificationsSuccess,
    fetchAttendancesSuccess,
    fetchReportsSuccess,
    fetchPublicParametersSuccess,
    fetchCommonsError
} from '../actions/commonsActions';

const defaultState = {
    termsAndConditions: null,
    notifications: null,
    attendances: null,
    reports: null,
    isFetching: false,
    publicParameters: null,
    error: null,
};

export default handleActions(
    {
        [fetchCommonsRequest](state) {
            return {
                ...state,
                isFetching: true,
            };
        },
        [fetchTermsAndConditionsSuccess](state, { payload }) {
            return {
                ...state,
                isFetching: false,
                termsAndConditions: payload
            };
        },
        [fetchNotificationsSuccess](state, { payload: { results, count } }) {
            return {
                ...state,
                notifications: {
                    data: results,
                    count
                },
                isFetching: false,
                error: null,
            };
        },
        [fetchAttendancesSuccess](state, { payload: { results, count } }) {
            return {
                ...state,
                attendances: {
                    data: results,
                    count
                },
                isFetching: false,
                error: null,
            };
        },
        [fetchReportsSuccess](state, { payload: { results, count } }) {
            return {
                ...state,
                reports: {
                    data: results,
                    count
                },
                isFetching: false,
                error: null,
            };
        },
        [fetchPublicParametersSuccess](state, { payload: { results } }) {
            return {
                publicParameters: results,
                isFetching: false,
                error: null,
            };
        },
        [fetchCommonsError](state, { payload }) {
            return {
                ...state,
                isFetching: false,
                error: payload,
            };
        }
    },
    defaultState,
);