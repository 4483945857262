import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Spinner } from 'reactstrap';
import ActionsPanel from "../../commons/components/tableComponents/ActionsPanel";
import ActiveTextIndicator from "../../commons/components/tableComponents/ActiveTextIndicator";
import ToggleSwitch from "../../commons/components/tableComponents/ToggleSwitch";
import Table from '../../commons/components/Table';
import { Input } from 'reactstrap';
import CreateSubcategoryModal from "./components/CreateSubcategoryModal"

import { getSubcategories, getCategories, createSubcategory, deleteSubcategory, updateSubcategory } from "../../redux/actions/categoriesActions";
import { rowsPerPageOptions } from "../../commons/constants/pagination";
import getUniqueValuesFromArray from '../../commons/functions/getUniqueValuesFromArray';

const columnsTable = [
    {
        value: 'id',
        title: 'No',
        isCustom: false
    },
    {
        value: 'name',
        title: 'Subcategoría',
        isCustom: false
    },
    {
        value: 'is_active',
        title: 'Estado',
        isCustom: true,
        CustomElement: ActiveTextIndicator
    },
    {
        value: 'is_featured',
        title: 'Destacado',
        isCustom: true,
        CustomElement: ToggleSwitch,
        state: 'categories.subcategories',
    },
    {
        value: 'is_active',
        title: 'Acciones',
        isCustom: true,
        notIsSortable: true,
        CustomElement: ActionsPanel,
        editModalElement: CreateSubcategoryModal,
        state: 'categories.subcategories',
        deleteOption: true,
        deleteDispatchAction: deleteSubcategory
    }
]

const handleData = (data) => (
    data.map(({ id, name, is_featured, is_active, is_deleted }) => ({
        id,
        name,
        is_featured,
        is_active,
        is_deleted
    }))
)


const Subcategories = () => {
    const dispatch = useDispatch();
    const [isOpenModal, setIsOpenModal] = useState(false);
    const subcategoriesList = useSelector((state) => state.categories.subcategories);
    const categoriesList = useSelector((state) => state.categories.categories?.data);
    const [categorySelected, setCategorySelected] = useState(1);
    const refTable = useRef(null);

    useEffect(() => {
        if (categoriesList) {
            subcategoriesRequest(1, rowsPerPageOptions[0], getUniqueValuesFromArray(categoriesList, d => d.name)[0]?.id)
            setCategorySelected(getUniqueValuesFromArray(categoriesList, d => d.name)[0]?.id)
        }
    }, [categoriesList]);

    useEffect(() => {
        dispatch(getCategories({
            data_all: "YES"
        }))
    }, [])


    const subcategoriesRequest = (page, page_size, category) => {
        dispatch(getSubcategories(
            {
                page,
                page_size,
                category_id: category
            },
            false
        ))
    }

    const handleCategoryChange = (e) => {
        const selectedValue = getUniqueValuesFromArray(categoriesList, d => d.name).filter(category => category.name === e.target.value) //Get selected value
        subcategoriesRequest(1, rowsPerPageOptions[0], selectedValue[0]?.id)
        setCategorySelected(selectedValue[0]?.id)
    }

    const handleCreateSubcategory = (body) => {
        dispatch(createSubcategory(
            body,
            {
                page: 1,
                page_size: refTable.current?.rowsPerPage,
                category_id: categorySelected
            },
            refTable.current.resetPagination //Reset pagination on data reload in the page 1
        ));
    }

    const handleUpdateSubcategory = (newValues, subcategoryInitialData) => {
        const body = {
            name: subcategoryInitialData.name,
            image_url: subcategoryInitialData.image_url,
            is_active: subcategoryInitialData.is_active,
            is_featured: subcategoryInitialData.is_featured,
            course_material_url: subcategoryInitialData.course_material_url,
            category_id: subcategoryInitialData.category.id,
            id: subcategoryInitialData.id
        }
        dispatch(updateSubcategory(
            {
                ...body,
                ...newValues
            },
            {
                page: refTable.current.page + 1,
                page_size: refTable.current.rowsPerPage,
                category_id: categorySelected
            },
            1
        ))
    }

    return (
        <div className='categoriesContainer'>
            <div className='headerContainer'>
                <div className='leftHeaderContainer'>
                    <h4>Subcategorías</h4>
                    {categoriesList &&
                        <Input
                            id="categorySelect"
                            type="select"
                            onChange={handleCategoryChange}
                        >
                            {getUniqueValuesFromArray(categoriesList, d => d.name)?.map((category, index) => (
                                <option key={`Category${index}`}>
                                    {category.name}
                                </option>
                            ))}
                        </Input>
                    }
                </div>
                <button className='addItemButton' onClick={() => setIsOpenModal(true)}>
                    Añadir subcategoria
                </button>
            </div>
            {
                subcategoriesList && categoriesList
                    ? <Table
                        columns={columnsTable}
                        data={handleData(subcategoriesList.data)}
                        changePage={(newPage, pageSize) => subcategoriesRequest(newPage + 1, pageSize, categorySelected)}
                        changeRowsPerPage={(pageSize) => subcategoriesRequest(1, pageSize, categorySelected)}
                        count={subcategoriesList.count}
                        ref={refTable}
                        dataFilter={{
                            "category_id": categorySelected //Aditional params for send when it update any value
                        }}
                        updateData={handleUpdateSubcategory}
                    />
                    : <Spinner style={{ marginLeft: "45%", marginTop: "46px" }} />
            }
            <CreateSubcategoryModal
                isOpenModal={isOpenModal}
                closeModal={() => setIsOpenModal(false)}
                createSubcategory={handleCreateSubcategory}
                createMode={true}
                initialData={null}
                categorySelected={categorySelected}
            />
        </div>
    )
}

export default Subcategories;