import React, { useEffect, useState, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { Spinner, Input } from 'reactstrap';
import DownloadButton from '../../commons/components/tableComponents/DownloadButton';
import ActiveTextIndicator from '../../commons/components/tableComponents/ActiveTextIndicator';
import Table from '../../commons/components/Table';
import { DesktopDatePicker } from "@mui/x-date-pickers";
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import TextField from '@mui/material/TextField';
import moment from 'moment';
import { rowsPerPageOptions } from "../../commons/constants/pagination";
import downloadDocument from "../../commons/functions/downloadDocument"

import { getCategories, getSubcategories } from "../../redux/actions/categoriesActions";
import { getCourses } from '../../redux/actions/coursesActions';

const columnsTable = [
    {
        value: 'id',
        title: 'No',
        isCustom: false
    },
    {
        value: 'title',
        title: 'Curso',
        isCustom: false
    },
    {
        value: 'is_active',
        title: 'Estado',
        isCustom: true,
        CustomElement: ActiveTextIndicator
    },
    {
        value: 'is_deleted',
        title: 'Respuestas',
        isCustom: true,
        CustomElement: DownloadButton,
        state: "courses.courses.data",
        fieldValidation: null
    }
]

const handleData = (data) => (
    data.map(({ id, title, is_active, code, is_deleted }) => ({
        id,
        title,
        is_active,
        code,
        is_deleted
    }))
)

const Responses = () => {
    const dispatch = useDispatch();
    const subcategoriesList = useSelector((state) => state.categories.subcategories?.data);
    const categoriesList = useSelector((state) => state.categories.categories?.data);
    const coursesList = useSelector((state) => state.courses.courses);

    const [subCategorySelected, setSubCategorySelected] = useState(null);
    const [initialDate, setInitialDate] = useState(moment().subtract(1, 'months'));
    const [endDate, setEndDate] = useState(moment());
    const refTable = useRef(null);

    useEffect(() => {
        dispatch(getCategories({
            data_all: "YES"
        }))
    }, [])

    useEffect(() => {
        if (categoriesList && categoriesList.length > 0) {
            subcategoriesRequest(1, rowsPerPageOptions[0], categoriesList[0]?.id)
        }
    }, [categoriesList])

    useEffect(() => {
        if (subcategoriesList && subcategoriesList.length > 0) {
            coursesRequest(subcategoriesList[0].id)
            setSubCategorySelected(subcategoriesList[0].id)
        }
    }, [subcategoriesList])

    const subcategoriesRequest = (page, page_size, category) => {
        dispatch(getSubcategories(
            {
                page,
                page_size,
                category_id: category
            },
            false
        ))
    }

    const coursesRequest = (subCategoryId) => {
        dispatch(getCourses({
            data_all: "YES",
            subcategory_id: subCategoryId,
            course_type_id: 2
        }));
    }

    const handleCategoryChange = (e) => {
        const selectedValue = e.target.value //Get selected value
        subcategoriesRequest(1, rowsPerPageOptions[0], selectedValue)
    }

    const handleSubCategoryChange = (e) => {
        const selectedValue = e.target.value //Get selected value
        coursesRequest(selectedValue)
        setSubCategorySelected(selectedValue)
    }

    const handleDownload = (id) => {
        downloadDocument(
            "eveluationResponsesByCourse",
            {
                start_date: moment(initialDate).set({ hour: 0, minute: 0, second: 0 }).toISOString(),
                end_date: moment(endDate).set({ hour: 23, minute: 59, second: 59 }).toISOString(),
                course_id: id
            }
        )
    }

    return (
        <div className='assessmentsContainer'>
            <div className='headerContainer'>
                <div className='leftHeaderContainer' style={{ justifyContent: "left" }}>
                    <h4>Respuestas</h4>

                </div>
            </div>
            <div>
                <div className='selectorsContainer'>
                    <Input
                        id="categorySelect"
                        type="select"
                        onChange={handleCategoryChange}
                        style={{ maxWidth: "200px" }}
                    >
                        {categoriesList?.map((category, index) => (
                            <option key={`Category${index}`} value={category.id} >
                                {category.name}
                            </option>
                        ))}
                    </Input>
                    <Input
                        id="subCategorySelect"
                        type="select"
                        onChange={handleSubCategoryChange}
                        style={{ maxWidth: "200px" }}
                    >
                        {subcategoriesList?.map((subCategory, index) => (
                            <option key={`SubCategory${index}`} value={subCategory.id} >
                                {subCategory.name}
                            </option>
                        ))}
                    </Input>
                    <div className='datePickerContainer'>
                        <LocalizationProvider dateAdapter={AdapterMoment}>
                            <DesktopDatePicker
                                inputFormat="DD/MM/YYYY"
                                value={initialDate}
                                maxDate={moment()}
                                onChange={e => setInitialDate(e)}
                                renderInput={(params) => <TextField {...params} />}
                            />
                            <DesktopDatePicker
                                inputFormat="DD/MM/YYYY"
                                value={endDate}
                                maxDate={moment()}
                                onChange={e => setEndDate(e)}
                                renderInput={(params) => <TextField {...params} />}
                            />
                        </LocalizationProvider>
                    </div>
                </div>
            </div>
            <div className="assessmentsCoursesTable">
                {
                    coursesList
                        ? <Table
                            columns={columnsTable}
                            data={handleData(coursesList.data)}
                            changePage={() => coursesRequest(subCategorySelected)}
                            changeRowsPerPage={() => coursesRequest(subCategorySelected)}
                            count={coursesList.count}
                            ref={refTable}
                            handleDownload={handleDownload}
                        />
                        : <Spinner style={{ marginLeft: "45%", marginTop: "46px" }} />
                }
            </div>
        </div>
    )
}

export default Responses;