import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { Spinner } from 'reactstrap';
import SunEditor, { buttonList } from 'suneditor-react';
import 'suneditor/dist/css/suneditor.min.css';

import { getTermsAndConditions, updateTermsAndConditions } from "../../redux/actions/commonsActions";

const editorOptions = [
    ['undo', 'redo', 'font', 'fontSize', 'formatBlock'],
    ['bold', 'underline', 'italic', 'strike', 'subscript', 'superscript', 'removeFormat'],
    ['fontColor', 'hiliteColor', 'outdent', 'indent', 'align', 'horizontalRule', 'list', 'table'],
    ['link', 'image', 'video', 'fullScreen', 'showBlocks', 'codeView', 'preview', 'print', 'save']
]

const TermsAndConditions = () => {
    const dispatch = useDispatch();
    const isLoading = useSelector((state) => state.commons.isFetching);
    const termsAndConditions = useSelector((state) => state.commons.termsAndConditions);
    const [editorContent, setEditorContent] = useState(null)

    useEffect(() => {
        dispatch(getTermsAndConditions())
    }, [])

    const handleChangeEditor = (content) => {
        setEditorContent(content)
    }

    const handleUpdate = () => {
        if (editorContent !== "<p><br></p>") dispatch(updateTermsAndConditions({
            "id": termsAndConditions.id,
            "code": termsAndConditions.code,
            "description": editorContent,
        }))
    }

    return (
        <div className='termsAndConditionsContainer'>
            <div className='header'>
                <h6>Términos y Condiciones </h6>
            </div>
            <div className='content'>
                {
                    !isLoading && termsAndConditions?.description
                        ? <div className='editorContainer'>
                            <SunEditor
                                lang="es"
                                defaultValue={termsAndConditions?.description}
                                setOptions={{
                                    height: 200,
                                    buttonList: editorOptions
                                }}
                                onChange={handleChangeEditor}
                            />
                            <div style={{ display: "flex", justifyContent: "flex-end" }}>
                                <button
                                    onClick={handleUpdate}
                                    disabled={editorContent === "<p><br></p>"}
                                    className="updateButton"
                                >
                                    Actualizar
                                </button>
                            </div>
                        </div>
                        : <Spinner style={{ marginLeft: "45%" }} />
                }
            </div>
        </div >
    )
}

export default TermsAndConditions;