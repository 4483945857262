import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Spinner } from "reactstrap";

import Table from "../../commons/components/Table";
import ActionsPanel from "../../commons/components/tableComponents/ActionsPanel";
import { rowsPerPageOptions } from "../../commons/constants/pagination";
import CreateDiscountRateModal from "./components/CreateDiscountRateModal";
import {
  createDiscountRate,
  deleteDiscountRate,
  getDiscountRates,
  updateDiscountRate,
} from "../../redux/actions/discountsActions";

const columnsTable = [
  {
    value: "id",
    title: "No",
    isCustom: false,
  },
  {
    value: "amount",
    title: "# de cursos",
    isCustom: false,
  },
  {
    value: "discount",
    title: "Descuento",
    isCustom: false,
  },
  {
    value: "is_active",
    title: "Acciones",
    isCustom: true,
    notIsSortable: true,
    CustomElement: ActionsPanel,
    editModalElement: CreateDiscountRateModal,
    updateDispatchAction: updateDiscountRate,
    state: "discounts.discountRates",
    deleteOption: true,
    deleteDispatchAction: deleteDiscountRate,
  },
];

const handleData = (data) =>
  data.map(({ id, amount, discount, is_deleted }) => ({
    id,
    amount,
    discount: `${discount}%`,
    is_deleted,
  }));

const DiscountRates = () => {
  const dispatch = useDispatch();
  const [isOpenModal, setIsOpenModal] = useState(false);
  const discountRateList = useSelector(
    (state) => state.discounts.discountRates
  );
  const refTable = useRef(null);

  useEffect(() => {
    discountRatesRequest(1, rowsPerPageOptions[0]);
  }, []);

  const discountRatesRequest = (page, page_size) => {
    dispatch(
      getDiscountRates({
        page,
        page_size,
      })
    );
  };

  const handleCreateDiscountRate = (body) => {
    dispatch(
      createDiscountRate(
        body,
        {
          page: 1,
          page_size: refTable.current?.rowsPerPage,
        },
        refTable.current.resetPagination //Reset pagination on data reload in the page 1
      )
    );
  };

  const handleUpdateDiscountRate = (newValues, discountRateInitialData) => {
    const body = {
      id: discountRateInitialData.id,
      amount: discountRateInitialData.amount,
      discount: discountRateInitialData.discount,
      is_active: discountRateInitialData.is_active,
    };
    dispatch(
      updateDiscountRate(
        {
          ...body,
          ...newValues,
        },
        {
          page: refTable.current.page + 1,
          page_size: refTable.current.rowsPerPage,
        },
        1
      )
    );
  };

  return (
    <div className="categoriesContainer">
      <div className="headerContainer">
        <div className="leftHeaderContainer">
          <h4>Descuentos</h4>
        </div>
        <button className="addItemButton" onClick={() => setIsOpenModal(true)}>
          Añadir descuento
        </button>
      </div>
      {discountRateList ? (
        <Table
          columns={columnsTable}
          data={handleData(discountRateList.data)}
          changePage={(newPage, pageSize) =>
            discountRatesRequest(newPage + 1, pageSize)
          }
          changeRowsPerPage={(pageSize) => discountRatesRequest(1, pageSize)}
          count={discountRateList.count}
          ref={refTable}
          updateData={handleUpdateDiscountRate}
        />
      ) : (
        <Spinner style={{ marginLeft: "45%", marginTop: "46px" }} />
      )}
      <CreateDiscountRateModal
        isOpenModal={isOpenModal}
        closeModal={() => setIsOpenModal(false)}
        createDiscountRate={handleCreateDiscountRate}
        createMode={true}
        initialData={null}
      />
    </div>
  );
};

export default DiscountRates;
