import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Spinner } from 'reactstrap';
import ActionsPanel from "../../commons/components/tableComponents/ActionsPanel";
import ActiveTextIndicator from "../../commons/components/tableComponents/ActiveTextIndicator";
import ToggleSwitch from "../../commons/components/tableComponents/ToggleSwitch";
import Table from '../../commons/components/Table';
import { Input } from 'reactstrap';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { getCourseType } from "../../redux/actions/categoriesActions";
import { getCourses, updateCourse, deleteCourse } from "../../redux/actions/coursesActions";
import { rowsPerPageOptions } from "../../commons/constants/pagination";
import getUniqueValuesFromArray from '../../commons/functions/getUniqueValuesFromArray';

const columnsTable = [
    {
        value: 'code',
        title: 'Cod',
        isCustom: false
    },
    {
        value: 'title',
        title: 'Nombre del curso',
        isCustom: false
    },
    {
        value: 'subcategory',
        title: 'Subcategoría',
        isCustom: false
    },
    {
        value: 'courseType',
        title: 'Tipo de curso',
        isCustom: false
    },
    {
        value: 'subcategory',
        title: 'Categoría',
        isCustom: false
    },
    {
        value: 'is_free',
        title: 'Gratuito',
        isCustom: true,
        CustomElement: ToggleSwitch,
        state: 'courses.courses',
    },
    {
        value: 'is_free_public_user',
        title: 'Gratuito P.',
        isCustom: true,
        CustomElement: ToggleSwitch,
        state: 'courses.courses',
    },
    {
        value: 'is_featured',
        title: 'Destacado',
        isCustom: true,
        CustomElement: ToggleSwitch,
        state: 'courses.courses',
    },
    {
        value: 'is_active',
        title: 'Estado',
        isCustom: true,
        CustomElement: ActiveTextIndicator
    },
    {
        value: 'is_active',
        title: 'Acciones',
        isCustom: true,
        notIsSortable: true,
        CustomElement: ActionsPanel,
        updateDispatchAction: updateCourse,
        editionRedirect: true,
        editionRedirectURL: '/cursos/editarCurso',
        state: 'courses.courses',
        deleteOption: true,
        deleteDispatchAction: deleteCourse
    }
]

const handleData = (data) => (
    data.map(({ id, code, title, subcategory, course_type, is_free, is_free_public_user, is_featured, is_active, is_deleted }) => ({
        code,
        title,
        subcategory: subcategory.name,
        courseType: course_type.name,
        category: subcategory?.category?.name,
        is_free,
        is_free_public_user,
        is_featured,
        is_active,
        id
    }))
)


const Courses = () => {
    const [searchParams, setSearchParams] = useSearchParams();
    const pageParam = searchParams.get('page');
    const rowsPerPageParam = searchParams.get('rowsPerPage');
    const filterParam = searchParams.get('filter');

    const dispatch = useDispatch();
    const coursesList = useSelector((state) => state.courses.courses);
    const courseTypeList = useSelector((state) => state.categories.courseType?.data); //typecourses
    const isLoading = useSelector(state => state.courses.isFetching);
    const [courseTypeSelected, setCourseTypeSelected] = useState(filterParam ? parseInt(filterParam) : 1);
    const refTable = useRef(null);
    const navigate = useNavigate();

    useEffect(() => {
        if (courseTypeList) {
            coursesRequest(
                pageParam ? (parseInt(pageParam) + 1) : 1,
                rowsPerPageParam ? rowsPerPageParam : rowsPerPageOptions[0],
                filterParam ? filterParam : getUniqueValuesFromArray(courseTypeList, d => d.name)[0]?.id
            )
            setSearchParams({
                filter: getUniqueValuesFromArray(courseTypeList, d => d.name)[0]?.id,
                ...(pageParam ? { page: pageParam } : {}),
                ...(rowsPerPageParam ? { rowsPerPage: rowsPerPageParam } : {})
            })
            setCourseTypeSelected(getUniqueValuesFromArray(courseTypeList, d => d.name)[0]?.id)
        }
    }, [courseTypeList]);

    useEffect(() => {
        dispatch(getCourseType({
            data_all: "YES"
        }))
    }, [])


    const coursesRequest = (page, page_size, courseType) => {
        if (!isLoading)
            dispatch(getCourses(
                {
                    page,
                    page_size,
                    course_type_id: courseType
                },
                false
            ))
    }

    const handleCourseChange = (e) => {
        setSearchParams({ page: 0, rowsPerPage: rowsPerPageOptions[0], filter: e.target.value });
        coursesRequest(1, rowsPerPageOptions[0], e.target.value);
        setCourseTypeSelected(e.target.value);
        refTable.current.resetPagination();
        refTable.current.resetRowsPerPage();
    }

    const handleUpdateCourse = (newValues, { id, code, title, description, course_material_url, course_audio_url, estimated_duration, course_cover_url, course_price, is_free, is_free_public_user, is_active, is_featured, has_pre_test, has_post_test, course_type, subcategory, mentor }) => {
        const body = {
            id,
            code,
            title,
            description,
            course_material_url,
            course_audio_url,
            estimated_duration,
            course_cover_url,
            course_price,
            is_free,
            is_free_public_user,
            is_active,
            is_featured,
            has_pre_test,
            has_post_test,
            course_type_id: course_type.id,
            subcategory_id: subcategory.id,
            mentor_id: mentor.id
        }
        dispatch(updateCourse(
            {
                ...body,
                ...newValues
            },
            {
                page: refTable.current.page + 1,
                page_size: refTable.current.rowsPerPage,
                course_type_id: courseTypeSelected
            },
            1
        ))
    }

    return (
        <div className='coursesContainer'>
            <div className='headerContainer'>
                <div className='leftHeaderContainer'>
                    <h4>Mis Cursos</h4>
                    {courseTypeList &&
                        <Input
                            id="categorySelect"
                            type="select"
                            onChange={handleCourseChange}
                            value={courseTypeSelected}
                        >
                            {getUniqueValuesFromArray(courseTypeList, d => d.name)?.map((courseType, index) => (
                                <option key={`courseType${index}`} value={courseType.id}>
                                    {courseType.name}
                                </option>
                            ))}
                        </Input>
                    }
                </div>
                <button className='addItemButton' onClick={() => navigate(`/cursos/crearCurso`)}>
                    Subir un curso
                </button>
            </div>
            {
                coursesList && courseTypeList
                    ? <Table
                        columns={columnsTable}
                        data={handleData(coursesList.data)}
                        changePage={(newPage, pageSize) => coursesRequest(newPage + 1, pageSize, courseTypeSelected)}
                        changeRowsPerPage={(pageSize) => coursesRequest(1, pageSize, courseTypeSelected)}
                        count={coursesList.count}
                        ref={refTable}
                        updateData={handleUpdateCourse}
                        dataFilter={{
                            "course_type_id": courseTypeSelected //Aditional params for send when it update any value
                        }}
                    />
                    : <Spinner style={{ marginLeft: "45%", marginTop: "46px" }} />
            }
        </div>
    )
}

export default Courses;