import { handleActions } from 'redux-actions';
import {
    fetchUsersRequest,
    fetchUsersSucess,
    fetchUsersError,
    fetchUpdateUserRequest,
    fetchUserByIdRequest,
    fetchMentorsSucess,
    fetchModalUserRequest,
    fetchModalUserSuccess
} from '../actions/usersActions';

const defaultState = {
    users: null,
    mentors: null,
    userById: null,
    isFetching: false,
    modalActionStatus: 'STARTED',
    error: null,
};

export default handleActions(
    {
        [fetchUsersRequest](state) {
            return {
                ...state,
                isFetching: true,
            };
        },
        [fetchUsersSucess](state, { payload: { results, count } }) {
            return {
                users: {
                    data: results,
                    count
                },
                isFetching: false,
                error: null,
                modalActionStatus: 'STARTED',
            };
        },
        [fetchUsersError](state, { payload }) {
            return {
                ...state,
                isFetching: false,
                error: payload,
            };
        },
        [fetchUserByIdRequest](state, { payload }) {
            return {
                ...state,
                userById: payload,
                isFetching: false,
                error: null
            }
        },
        [fetchMentorsSucess](state, { payload: { results, count } }) {
            return {
                ...state,
                mentors: {
                    data: results,
                    count
                },
                isFetching: false,
                error: null,
                modalActionStatus: 'STARTED',
            }
        },
        [fetchModalUserRequest](state) {
            return {
                ...state,
                modalActionStatus: 'LOADING',
            };
        },
        [fetchModalUserSuccess](state) {
            return {
                ...state,
                modalActionStatus: 'COMPLETED',
            };
        }
    },
    defaultState,
);