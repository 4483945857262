import { createAction } from "redux-actions";
import api from "../../utils/api/apiClient";
import { toast } from "react-toastify";

export const fetchCoursesRequest = createAction('FETCH_COURSES_REQUEST');
export const fetchCoursesSuccess = createAction('FETCH_COURSES_SUCCESS');
export const fetchCoursesError = createAction('FETCH_COURSES_ERROR');
export const fetchModalCoursesRequest = createAction('FETCH_MODAL_COURSES_REQUEST');
export const fetchModalCoursesSuccess = createAction('FETCH_MODAL_COURSES_SUCCESS');
export const fetchAdvertisementsSuccess = createAction('FETCH_ADVERTISEMENTS_SUCCESS');
export const fetchPodcastSuccess = createAction('FETCH_PODCAST_SUCCESS');

export const getCourses = (params) => async (dispatch) => {
    try {
        dispatch(fetchCoursesRequest());
        const { data } = await api.courses.getCourses({ params });
        dispatch(fetchCoursesSuccess(data));
    } catch (error) {
        errorFunction(error, dispatch);
    }
};

export const createCourse = (body, file) => async (dispatch) => {
    try {
        dispatch(fetchModalCoursesRequest());
        const courseResponse = await api.courses.createCoursey(body);
        const signedUrlResponse = await api.courses.generateDownloadSignedUrl({
            is_course: true,
            id: courseResponse.data.id,
            file_name: file.name
        });
        const formData = new FormData();
        formData.append('file', file);
        await fetch(signedUrlResponse.data.signed_url, {
            method: 'PUT',
            body: formData
        });
        await api.courses.makePublicObjectGoogleStorage({
            is_course: true,
            id: courseResponse.data.id,
            file_name: file.name
        });
        dispatch(fetchModalCoursesSuccess());
        toast.success('Curso creado de forma exitosa', { autoClose: 3000, theme: "colored" });
    } catch (error) {
        errorFunction(error, dispatch);
    }
}

export const updateCourse = (body, paramsGetCourses, typePutPetition) => async (dispatch) => {
    try {
        dispatch(fetchModalCoursesRequest());
        await api.courses.updateCourse(body, typePutPetition);
        dispatch(fetchModalCoursesSuccess());
        toast.success('Curso actualizado de forma exitosa', { autoClose: 3000, theme: "colored" });
        dispatch(getCourses(paramsGetCourses))
    } catch (error) {
        errorFunction(error, dispatch);
    }
}

export const updateCourseComplete = (body, file = null) => async (dispatch) => {
    try {
        dispatch(fetchModalCoursesRequest());
        const courseResponse = await api.courses.updateCourse(body, 2);
        if (!file) {
            dispatch(fetchModalCoursesSuccess());
            toast.success('Curso actualizado de forma exitosa', { autoClose: 3000, theme: "colored" });
            return;
        }
        const signedUrlResponse = await api.courses.generateDownloadSignedUrl({
            is_course: true,
            id: courseResponse.data.id,
            file_name: file.name
        });
        const formData = new FormData();
        formData.append('file', file);
        await fetch(signedUrlResponse.data.signed_url, {
            method: 'PUT',
            body: formData
        });
        await api.courses.makePublicObjectGoogleStorage({
            is_course: true,
            id: courseResponse.data.id,
            file_name: file.name
        });
        dispatch(fetchModalCoursesSuccess());
        toast.success('Curso actualizado de forma exitosa', { autoClose: 3000, theme: "colored" });
    } catch (error) {
        errorFunction(error, dispatch);
    }
}

export const deleteCourse = (id, paramsGetCourses) => async (dispatch) => {
    try {
        await api.courses.deleteCourse(id);
        toast.success('Curso eliminado de forma exitosa', { autoClose: 3000, theme: "colored" });
        dispatch(getCourses(paramsGetCourses, true))
    } catch (error) {
        errorFunction(error, dispatch);
    }
}

export const getAdvertisements = () => async (dispatch) => {
    try {
        dispatch(fetchCoursesRequest());
        const { data } = await api.courses.getAdvertisements();
        dispatch(fetchAdvertisementsSuccess(data?.results[0]));
    } catch (error) {
        errorFunction(error, dispatch);
    }
}

export const updateAdvertisements = (body) => async (dispatch) => {
    try {
        dispatch(fetchCoursesRequest());
        await api.courses.updateAdvertisements(body);
        toast.success('Anuncio actualizado de forma exitosa', { autoClose: 3000, theme: "colored" });
        dispatch(getAdvertisements())
    } catch (error) {
        errorFunction(error, dispatch);
    }
}

export const getPodcasts = (params) => async (dispatch) => {
    try {
        dispatch(fetchCoursesRequest());
        const { data } = await api.courses.getPodcasts({ params });
        dispatch(fetchPodcastSuccess(data));
    } catch (error) {
        errorFunction(error, dispatch);
    }
};

export const createPodcast = (body, file) => async (dispatch) => {
    try {
        dispatch(fetchModalCoursesRequest());
        const podcastResponse = await api.courses.createPodcast(body);
        const signedUrlResponse = await api.courses.generateDownloadSignedUrl({
            is_course: false,
            id: podcastResponse.data.id,
            file_name: file.name
        });
        const formData = new FormData();
        formData.append('file', file);
        await fetch(signedUrlResponse.data.signed_url, {
            method: 'PUT',
            body: formData
        });
        await api.courses.makePublicObjectGoogleStorage({
            is_course: false,
            id: podcastResponse.data.id,
            file_name: file.name
        });
        dispatch(fetchModalCoursesSuccess());
        toast.success('Podcast creado de forma exitosa', { autoClose: 3000, theme: "colored" });
    } catch (error) {
        errorFunction(error, dispatch);
    }
}

export const updatePodcast = (body, paramsGetPodcasts, typePutPetition) => async (dispatch) => {
    try {
        dispatch(fetchModalCoursesRequest());
        await api.courses.updatePodcast(body, typePutPetition);
        dispatch(fetchModalCoursesSuccess());
        toast.success('Podcast actualizado de forma exitosa', { autoClose: 3000, theme: "colored" });
        dispatch(getPodcasts(paramsGetPodcasts))
    } catch (error) {
        errorFunction(error, dispatch);
    }
}

export const updatePodcastComplete = (body, file = null) => async (dispatch) => {
    try {
        dispatch(fetchModalCoursesRequest());
        const podcastResponse = await api.courses.updatePodcast(body, 2);
        if (!file) {
            dispatch(fetchModalCoursesSuccess());
            toast.success('Podcast actualizado de forma exitosa', { autoClose: 3000, theme: "colored" });
            return;
        }
        const signedUrlResponse = await api.courses.generateDownloadSignedUrl({
            is_course: false,
            id: podcastResponse.data.id,
            file_name: file.name
        });
        const formData = new FormData();
        formData.append('file', file);
        await fetch(signedUrlResponse.data.signed_url, {
            method: 'PUT',
            body: formData
        });
        await api.courses.makePublicObjectGoogleStorage({
            is_course: false,
            id: podcastResponse.data.id,
            file_name: file.name
        });
        dispatch(fetchModalCoursesSuccess());
        toast.success('Podcast actualizado de forma exitosa', { autoClose: 3000, theme: "colored" });
    } catch (error) {
        errorFunction(error, dispatch);
    }
}

export const deletePodcast = (id, paramsGetPodcasts) => async (dispatch) => {
    try {
        await api.courses.deletePodcast(id);
        toast.success('Podcast eliminado de forma exitosa', { autoClose: 3000, theme: "colored" });
        dispatch(getPodcasts(paramsGetPodcasts, true))
    } catch (error) {
        errorFunction(error, dispatch);
    }
}

const errorFunction = (error, dispatch) => {
    const errorMessage = error?.response?.data?.message ? error.response.data.message : error.message
    dispatch(fetchCoursesError(errorMessage));
    toast.error(errorMessage, { autoClose: 3000, theme: "colored" });
}