import { get, postFormData, deleteMethod, put, putFormData } from '../base/index';

export default {
    getCategories: (params) => {
        return get(`${process.env.REACT_APP_URL_BASE}/categories/`, params)
    },
    createCategory: (body) => {
        return postFormData(`${process.env.REACT_APP_URL_BASE}/categories/`, body)
    },
    deleteCategory: (id) => {
        return deleteMethod(`${process.env.REACT_APP_URL_BASE}/categories/${id}/`)
    },
    updateCategory: (body, typePutPetition) => {
        return typePutPetition === 2
            ? putFormData(`${process.env.REACT_APP_URL_BASE}/categories/`, body)
            : put(`${process.env.REACT_APP_URL_BASE}/categories/`, body)
    },
    getSubcategories: (params) => {
        return get(`${process.env.REACT_APP_URL_BASE}/subcategories/`, params)
    },
    createSubcategory: (body) => {
        return postFormData(`${process.env.REACT_APP_URL_BASE}/subcategories/`, body)
    },
    deleteSubcategory: (id) => {
        return deleteMethod(`${process.env.REACT_APP_URL_BASE}/subcategories/${id}/`)
    },
    updateSubcategory: (body, typePutPetition) => {
        return typePutPetition === 2
            ? putFormData(`${process.env.REACT_APP_URL_BASE}/subcategories/`, body)
            : put(`${process.env.REACT_APP_URL_BASE}/subcategories/`, body)
    },
    getCourseType: (params) => {
        return get(`${process.env.REACT_APP_URL_BASE}/course_types/`, params)
    },
    updateCourseType: (body) => {
        return put(`${process.env.REACT_APP_URL_BASE}/course_types/`, body)
    }
}