import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Icon from '@mdi/react';
import { mdiMagnify } from '@mdi/js';
import { Input } from '@mui/material';

const AutoSearch = ({ placeholder, setFilter }) => {
    const [filterInput, setFilterInput] = useState("");
    const [firstRender, setFirstRender] = useState(true);

    useEffect(() => {
        const delayDebounce = setTimeout(() => {
            if (!firstRender) {
                setFilter(filterInput)
            }
        }, 1000)

        setFirstRender(false);
        return () => clearTimeout(delayDebounce)
    }, [filterInput]);


    return (
        <Input
            placeholder={placeholder}
            onChange={e => setFilterInput(e.target.value)}
            endAdornment={<Icon path={mdiMagnify} color="#444444" />}
            className="autoSearchInput"

        />
    )
}

AutoSearch.propTypes = {
    placeholder: PropTypes.string.isRequired,
    setFilter: PropTypes.func.isRequired
}

export default AutoSearch;