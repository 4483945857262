import React, { useState, useEffect } from 'react';
import { Modal, Input, ModalBody, Button } from 'reactstrap';
import { IconButton } from '@mui/material';
import Icon from '@mdi/react';
import { mdiClose } from '@mdi/js';

import { useDispatch, useSelector } from 'react-redux';
import { createNotification } from "../../../redux/actions/commonsActions";

const listModules = [
    {
        title: "Cápsula Informatica",
        value: "information_capsule"
    }, {
        title: "Curso",
        value: "course"
    }, {
        title: "Podcast",
        value: "podcast"
    }
]

const CreateNotificationModal = ({ isOpen, closeModal, paramsGetNotifications }) => {
    const dispatch = useDispatch();
    const [title, setTitle] = useState("");
    const [description, setDescription] = useState("");
    const [module, setModule] = useState("");
    const [submittedForm, setSubmittedForm] = useState(false);
    const isLoading = useSelector((state) => state.commons.isFetching);
    const user = useSelector((state) => state.auth.userInfo)

    const handleChangeInput = (e, setInput) => {
        setInput(e.target.value)
    }

    const handleCloseModal = () => {
        setTitle("");
        setDescription("");
        setModule("");
        setSubmittedForm(false);
        closeModal()
    }

    const handleSave = () => {
        setSubmittedForm(true)
        if (description !== "" && module != "" && title != "") {
            dispatch(createNotification(
                {
                    "title": title,
                    "description": description,
                    "module": module,
                    "user_id": user?.id
                },
                paramsGetNotifications,
                handleCloseModal,
            ))
        }
    }

    return (
        <Modal isOpen={isOpen} centered className='createCategoryModal'>
            <div className='containButton'>
                <IconButton onClick={handleCloseModal} className="closeButton">
                    <Icon path={mdiClose} color="#131F2A" size="23px" />
                </IconButton>
            </div>
            <ModalBody>
                <form>
                    <Input
                        placeholder='Título de la notificación'
                        onChange={(e) => handleChangeInput(e, setTitle)}
                        invalid={title === "" && submittedForm}
                        value={title}
                    />
                    <Input
                        placeholder='Descripción de la notificación'
                        onChange={(e) => handleChangeInput(e, setDescription)}
                        invalid={description === "" && submittedForm}
                        value={description}
                    />
                    <Input
                        type="select"
                        value={module}
                        onChange={(e) => handleChangeInput(e, setModule)}
                        style={{ color: module === "" ? "#9ea6ab" : "#131F2A", borderColor: "#131F2A" }}
                        invalid={module === "" && submittedForm}
                    >
                        <option key="select" value="" style={{ display: "none" }}>
                            Selecciona el módulo
                        </option>
                        {listModules?.map((module, index) => (
                            <option key={`module${index}`} value={module.value} style={{ color: "#131F2A" }}>
                                {module.title}
                            </option>
                        ))}
                    </Input>

                    <div className='buttons'>
                        <Button className='secondarybutton' onClick={handleCloseModal}>Cancelar</Button>
                        <Button className='principalbutton' onClick={handleSave} disabled={isLoading} style={{ width: "135px" }}>Crear</Button>
                    </div>
                </form>
            </ModalBody>
        </Modal>
    )
}

export default CreateNotificationModal;