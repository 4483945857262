import { handleActions } from 'redux-actions';
import {
    fetchAuthRequest,
    fetchLoginSuccess,
    fetchlogout,
    fetchAuthError,
    fetchTokenRenovation,
    fetchUserByTokenSuccess,
    fetchPasswordResetSuccess
} from '../actions/authActions';

const defaultState = {
    isAuth: false,
    isFetching: false,
    isFetchingTokenRenovation: true,
    userInfo: null,
    error: null,
};

export default handleActions(
    {
        [fetchAuthRequest](state) {
            return {
                ...state,
                isFetching: true,
            };
        },
        [fetchLoginSuccess]() {
            return {
                isAuth: true,
                isFetching: false,
                error: null,
                isFetchingTokenRenovation: false,
            };
        },
        [fetchUserByTokenSuccess](state, { payload }) {
            return {
                ...state,
                userInfo: payload,
            };
        },
        [fetchlogout]() {
            return {
                isAuth: false,
                isFetching: false,
                error: null,
                isFetchingTokenRenovation: false,
            }
        },
        [fetchTokenRenovation](state) {
            return {
                ...state,
                isFetchingTokenRenovation: false,
            }
        },
        [fetchPasswordResetSuccess](state) {
            return {
                isFetching: false,
                error: null
            }
        },
        [fetchAuthError](state, { payload }) {
            return {
                isAuth: false,
                isFetching: false,
                error: payload,
                isFetchingTokenRenovation: false,
            };
        }
    },
    defaultState,
);