import React, { useEffect, useState, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { Spinner, Input } from 'reactstrap';
import DownloadButton from '../../commons/components/tableComponents/DownloadButton';
import ActiveTextIndicator from '../../commons/components/tableComponents/ActiveTextIndicator';
import Table from '../../commons/components/Table';
import { DesktopDatePicker } from "@mui/x-date-pickers";
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import TextField from '@mui/material/TextField';
import moment from 'moment';
import { rowsPerPageOptions } from "../../commons/constants/pagination";
import getUniqueValuesFromArray from '../../commons/functions/getUniqueValuesFromArray';
import downloadDocument from "../../commons/functions/downloadDocument"

import { getCategories, getSubcategories } from "../../redux/actions/categoriesActions";

const columnsTable = [
    {
        value: 'id',
        title: 'No',
        isCustom: false
    },
    {
        value: 'name',
        title: 'Subcategoría',
        isCustom: false
    },
    {
        value: 'is_active',
        title: 'Estado',
        isCustom: true,
        CustomElement: ActiveTextIndicator
    },
    {
        value: 'is_deleted',
        title: 'Respuestas',
        isCustom: true,
        CustomElement: DownloadButton,
        state: "categories.subcategories.data",
        fieldValidation: null
    }
]

const handleData = (data) => (
    data.map(({ id, name, is_featured, is_active, is_deleted }) => ({
        id,
        name,
        is_active,
        is_featured,
        is_deleted
    }))
)

const CompletedCourses = () => {
    const dispatch = useDispatch();
    const subcategoriesList = useSelector((state) => state.categories.subcategories);
    const categoriesList = useSelector((state) => state.categories.categories?.data);

    const [categorySelected, setCategorySelected] = useState("");
    const [initialDate, setInitialDate] = useState(moment().subtract(1, 'months'));
    const [endDate, setEndDate] = useState(moment());
    const refTable = useRef(null);

    useEffect(() => {
        dispatch(getCategories({
            data_all: "YES"
        }))
    }, [])

    useEffect(() => {
        if (categoriesList) {
            subcategoriesRequest(1, rowsPerPageOptions[0], getUniqueValuesFromArray(categoriesList, d => d.name)[0]?.id)
            setCategorySelected(categoriesList[0]?.name)
        }
    }, [categoriesList])

    const subcategoriesRequest = (page, page_size, category) => {
        dispatch(getSubcategories(
            {
                page,
                page_size,
                category_id: category
            },
            false
        ))
    }

    const handleCategoryChange = (e) => {
        const selectedValue = getUniqueValuesFromArray(categoriesList, d => d.name).filter(category => category.name === e.target.value) //Get selected value
        subcategoriesRequest(1, rowsPerPageOptions[0], selectedValue[0]?.id)
        setCategorySelected(selectedValue[0]?.id)
    }

    const handleDownload = (id) => {
        downloadDocument(
            "reproducedCourseBySubcategory",
            {
                start_date: moment(initialDate).set({ hour: 0, minute: 0, second: 0 }).toISOString(),
                end_date: moment(endDate).set({ hour: 23, minute: 59, second: 59 }).toISOString(),
                subcategory_id: id
            }
        )
    }

    return (
        <div className='assessmentsContainer'>
            <div className='headerContainer'>
                <div className='leftHeaderContainer' style={{ justifyContent: "left" }}>
                    <h4>Cursos finalizados</h4>
                    {categoriesList &&
                        <Input
                            id="categorySelect"
                            type="select"
                            onChange={handleCategoryChange}
                            style={{ maxWidth: "200px" }}
                        >
                            {getUniqueValuesFromArray(categoriesList, d => d.name)?.map((category, index) => (
                                <option key={`Category${index}`}>
                                    {category.name}
                                </option>
                            ))}
                        </Input>
                    }
                    <div className='datePickerContainer'>
                        <LocalizationProvider dateAdapter={AdapterMoment}>
                            <DesktopDatePicker
                                inputFormat="DD/MM/YYYY"
                                value={initialDate}
                                maxDate={moment()}
                                onChange={e => setInitialDate(e)}
                                renderInput={(params) => <TextField {...params} />}
                            />
                            <DesktopDatePicker
                                inputFormat="DD/MM/YYYY"
                                value={endDate}
                                maxDate={moment()}
                                onChange={e => setEndDate(e)}
                                renderInput={(params) => <TextField {...params} />}
                            />
                        </LocalizationProvider>
                    </div>
                </div>
            </div>
            <div className="assessmentsCoursesTable">
                {
                    subcategoriesList && categoriesList
                        ? <Table
                            columns={columnsTable}
                            data={handleData(subcategoriesList.data)}
                            changePage={(newPage, pageSize) => subcategoriesRequest(newPage + 1, pageSize, categorySelected)}
                            changeRowsPerPage={(pageSize) => subcategoriesRequest(1, pageSize, categorySelected)}
                            count={subcategoriesList.count}
                            ref={refTable}
                            handleDownload={handleDownload}
                        />
                        : <Spinner style={{ marginLeft: "45%", marginTop: "46px" }} />
                }
            </div>
        </div>
    )
}

export default CompletedCourses;