import Dashboard from "../containers/Dashboard/index";

import Users from "../containers/Users/index";
import User from "../containers/Users/user";
import Mentors from "../containers/Users/Mentors";

import Categories from "../containers/Categories/index";
import Subcategories from "../containers/Categories/Subcategories";
import CourseType from "../containers/Categories/CourseType";

import Courses from "../containers/Courses/index";
import CreateCourse from "../containers/Courses/CreateCourse";
import Advertisements from "../containers/Courses/Advertisements";
import Podcast from "../containers/Courses/Podcast";
import CreatePodcast from "../containers/Courses/CreatePodcast";

import TermsAndConditions from "../containers/TermsAndConditions/index";

import Notifications from "../containers/Notifications/index";

import Attendance from "../containers/Attendance/index";

import Reports from "../containers/Reports/index";
import CompletedCourses from "../containers/Reports/CompletedCourses";

import Subscriptions from "../containers/Subscriptions/index";

import Assessments from "../containers/Assessments/index";
import AssessmentsCourses from "../containers/Assessments/courses";
import Responses from "../containers/Assessments/Responses";
import CreateAssessment from "../containers/Assessments/CreateAssessment";
import AssessmentByCourse from "../containers/Assessments/AssessmentByCourse";
import CreateAssessmentByCourse from "../containers/Assessments/CreateAssessmentByCourse";
import DiscountCoupons from "../containers/DiscountCoupons";
import DiscountRates from "../containers/DiscountRates";

export default [
  {
    path: "/dashboard",
    component: <Dashboard />,
  },
  {
    path: "/categorias",
    component: <Categories />,
  },
  {
    path: "/categorias/subcategorias",
    component: <Subcategories />,
  },
  {
    path: "/categorias/TipoDeCurso",
    component: <CourseType />,
  },
  {
    path: "/cursos",
    component: <Courses />,
  },
  {
    path: "/cursos/editarCurso/:courseId",
    component: <CreateCourse />,
  },
  {
    path: "/cursos/crearCurso",
    component: <CreateCourse />,
  },
  {
    path: "/cursos/anuncios",
    component: <Advertisements />,
  },
  {
    path: "/cursos/podcasts",
    component: <Podcast />,
  },
  {
    path: "/cursos/editarPodcast/:courseId",
    component: <CreatePodcast />,
  },
  {
    path: "/cursos/crearPodcast",
    component: <CreatePodcast />,
  },
  {
    path: "/Usuarios",
    component: <Users />,
  },
  {
    path: "/Usuarios/Usuario/:userId",
    component: <User />,
  },
  {
    path: "/Usuarios/Conferenciante",
    component: <Mentors />,
  },
  {
    path: "/terminosCondiciones",
    component: <TermsAndConditions />,
  },
  {
    path: "/notificaciones",
    component: <Notifications />,
  },
  {
    path: "/asistencias",
    component: <Attendance />,
  },
  {
    path: "/reportes/cursosComprados",
    component: <Reports />,
  },
  {
    path: "/reportes/cursosFinalizados",
    component: <CompletedCourses />,
  },
  {
    path: "/suscripciones",
    component: <Subscriptions />,
  },
  {
    path: "/evaluaciones/respuestas",
    component: <Responses />,
  },
  {
    path: "/evaluaciones/cursos",
    component: <AssessmentByCourse />,
  },
  {
    path: "/evaluaciones/prePostEvaluacion",
    component: <AssessmentsCourses />,
  },
  {
    path: "/evaluaciones",
    component: <Assessments />,
  },
  {
    path: "/evaluaciones/crearEvaluacion",
    component: <CreateAssessment />,
  },
  {
    path: "/evaluaciones/editarEvaluacion/:assessmentId",
    component: <CreateAssessment />,
  },
  {
    path: "/evaluaciones/crearEvaluacionPorCurso",
    component: <CreateAssessmentByCourse />,
  },
  {
    path: "/evaluaciones/editarEvaluacionPorCurso/:assessmentId",
    component: <CreateAssessmentByCourse />,
  },
  {
    path: "/discount-coupons",
    component: <DiscountCoupons />,
  },
  {
    path: "/discount-rates",
    component: <DiscountRates />,
  },
];
