import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { DndProvider } from 'react-dnd';

import store from './store';
import Router from '../../routers/Router';

import '../scss/app.scss';

const App = () => {
  return (
    <Provider store={store}>
      <BrowserRouter>
        <DndProvider backend={HTML5Backend}>
          <ToastContainer />
          <Router />
        </DndProvider>
      </BrowserRouter>
    </Provider>
  );
}

export default App;
