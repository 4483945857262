import React from 'react';
import { Switch } from "@mui/material";
import { useSelector } from 'react-redux';
import getObjectFromString from '../../functions/getObjectFromString';

const ToggleSwitch = ({ id, dataColumn, updateData }) => {
    const dataState = useSelector((state) => getObjectFromString(state, dataColumn?.state));
    const initialDataFiltered = dataState?.data.find(element => element.id === id) //filter the data with the user required

    const handleChange = (e) => {
        e.preventDefault();
        updateData(
            {
                [dataColumn.value]: e.target.checked
            },
            initialDataFiltered
        )
    }

    return (
        <Switch
            size="medium"
            onClick={handleChange}
            className="toggleSwitch"
            checked={initialDataFiltered[dataColumn.value]}
        />
    )
}

export default ToggleSwitch;