import React from 'react';
import Dropzone from 'react-dropzone';
import PropTypes from 'prop-types';
import Icon from '@mdi/react';
import { mdiTransferUp } from '@mdi/js';
import { toast } from 'react-toastify';

const DropZoneField = ({ customHeight, name, onChange, pictures, multiple, typeFiles, title, invalid }) => {
    let files = [];

    const onDrop = (file) => {
        if (typeFiles.find(typeFile => typeFile === file[0].type)) {
            onChange(
                multiple
                    ? [...pictures, Object.assign(file[0], { preview: URL.createObjectURL(file[0]) })]
                    : [Object.assign(file[0], { preview: URL.createObjectURL(file[0]) })]
            )
        }
        else toast.warning("Tipo de archivo no permitido", { autoClose: 3000, theme: "colored" });
    };

    return (
        <div className={`dropzone dropzone--single${customHeight ? ' dropzone--custom-height' : ''}`} style={{ borderColor: invalid ? "#dc3545" : "#131F2A" }}>
            <Dropzone
                noDrag={true}
                accept="text/*,"
                name={name}
                multiple={multiple}
                onDrop={(fileToUpload) => {
                    onDrop(fileToUpload);
                }}
            >
                {({ getRootProps, getInputProps }) => (
                    <div {...getRootProps()} className="dropzone__input">
                        {(!files || files?.length === 0)
                            && (
                                <div className="dropzone__drop-here" style={{ color: pictures?.length > 0 ? "#131F2A" : "#9ea3a8" }}>
                                    <span className="lnr lnr-upload" /> {pictures?.length > 0 ? pictures[0].name : title}
                                </div>
                            )}
                        <input {...getInputProps()} />
                        <div className="icon" style={{ backgroundColor: invalid ? "#dc3545" : "#131F2A" }}>
                            <Icon path={mdiTransferUp} color="#fff" size="18px" />
                        </div>
                    </div>
                )}
            </Dropzone>
        </div>
    );
};

DropZoneField.propTypes = {
    name: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    customHeight: PropTypes.bool,
    typeFiles: PropTypes.array.isRequired,
    title: PropTypes.string.isRequired
};

DropZoneField.defaultProps = {
    customHeight: false,
};

const renderDropZoneField = ({ input, customHeight, onChange, pictures, multiple, typeFiles, title, invalid }) => (
    <DropZoneField
        {...input}
        customHeight={customHeight}
        onChange={onChange}
        pictures={pictures}
        multiple={multiple}
        typeFiles={typeFiles}
        title={title}
        invalid={invalid}
    />
);

renderDropZoneField.propTypes = {
    input: PropTypes.shape({
        name: PropTypes.string,
        onChange: PropTypes.func,
    }),
    customHeight: PropTypes.bool,
};

renderDropZoneField.defaultProps = {
    customHeight: false,
};

export default renderDropZoneField;
