import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Spinner } from 'reactstrap';

import { getCategories, createCategory, updateCategory, deleteCategory } from "../../redux/actions/categoriesActions";
import Table from '../../commons/components/Table';
import ActiveTextIndicator from "../../commons/components/tableComponents/ActiveTextIndicator";
import ActionsPanel from "../../commons/components/tableComponents/ActionsPanel";
import { rowsPerPageOptions } from "../../commons/constants/pagination";
import CreateCategoryModal from "./components/CreateCategoryModal"
import ToggleSwitch from '../../commons/components/tableComponents/ToggleSwitch';

const columnsTable = [
    {
        value: 'id',
        title: 'No',
        isCustom: false
    },
    {
        value: 'name',
        title: 'Categoría',
        isCustom: false
    },
    {
        value: 'enable_subscription',
        title: 'Activar suscripción',
        isCustom: true,
        CustomElement: ToggleSwitch,
        state: 'categories.categories',
    },
    {
        value: 'is_active',
        title: 'Estado',
        isCustom: true,
        CustomElement: ActiveTextIndicator
    },
    {
        value: 'is_active',
        title: 'Acciones',
        isCustom: true,
        notIsSortable: true,
        CustomElement: ActionsPanel,
        editModalElement: CreateCategoryModal,
        updateDispatchAction: updateCategory,
        state: 'categories.categories',
        deleteOption: true,
        deleteDispatchAction: deleteCategory
    }
]

const handleData = (data) => (
    data.map(({ id, name, is_active, enable_subscription, is_deleted }) => ({
        id,
        name,
        is_active,
        enable_subscription,
        is_deleted
    }))
)

const Categories = () => {
    const dispatch = useDispatch();
    const [isOpenModal, setIsOpenModal] = useState(false);
    const categoriesList = useSelector((state) => state.categories.categories);
    const refTable = useRef(null);

    useEffect(() => {
        categoriesRequest(1, rowsPerPageOptions[0])
    }, [])

    const categoriesRequest = (page, page_size) => {
        dispatch(getCategories({
            page, page_size
        }))
    }

    const handleCreateCategory = (body) => {
        dispatch(createCategory(
            body,
            {
                page: 1,
                page_size: refTable.current?.rowsPerPage
            },
            refTable.current.resetPagination //Reset pagination on data reload in the page 1
        ));
    }

    const handleUpdateCategory = (newValues, categoryInitialData) => {
        const body = {
            id: categoryInitialData.id,
            name: categoryInitialData.name,
            color: categoryInitialData.color,
            icon_url: categoryInitialData.icon_url,
            is_active: categoryInitialData.is_active,
            enable_subscription: categoryInitialData.enable_subscription
        }
        dispatch(updateCategory(
            {
                ...body,
                ...newValues
            },
            {
                page: refTable.current.page + 1,
                page_size: refTable.current.rowsPerPage,
            },
            1
        ))
    }

    return (
        <div className='categoriesContainer'>
            <div className='headerContainer'>
                <div className='leftHeaderContainer'>
                    <h4>Categorías</h4>
                </div>
                <button className='addItemButton' onClick={() => setIsOpenModal(true)}>
                    Añadir Categorías
                </button>
            </div>
            {
                categoriesList
                    ? <Table
                        columns={columnsTable}
                        data={handleData(categoriesList.data)}
                        changePage={(newPage, pageSize) => categoriesRequest(newPage + 1, pageSize)}
                        changeRowsPerPage={(pageSize) => categoriesRequest(1, pageSize)}
                        count={categoriesList.count}
                        ref={refTable}
                        updateData={handleUpdateCategory}
                    />
                    : <Spinner style={{ marginLeft: "45%", marginTop: "46px" }} />
            }
            <CreateCategoryModal
                isOpenModal={isOpenModal}
                closeModal={() => setIsOpenModal(false)}
                createCategory={handleCreateCategory}
                createMode={true}
                initialData={null}
            />
        </div>
    )
}

export default Categories;