import React, { useState, useImperativeHandle, forwardRef } from 'react';
import { Modal, ModalBody, Button, ModalHeader, ModalFooter } from 'reactstrap';
import { IconButton } from '@mui/material';
import PropTypes from 'prop-types';
import Icon from '@mdi/react';
import { mdiClose } from '@mdi/js';
import moment from 'moment';
import { DesktopDatePicker } from "@mui/x-date-pickers";
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import TextField from '@mui/material/TextField';

const DownloadMentorReportModal = forwardRef(({ isOpenModal, closeModal, onDownload, isLoading }, ref) => {
    const [initialDate, setInitialDate] = useState(moment().subtract(1, 'months'));
    const [endDate, setEndDate] = useState(moment());
    const [mentorIdSelected, setMentorIdSelected] = useState(null)

    const handleCloseModal = () => {
        setInitialDate(moment().subtract(1, 'months'));
        setEndDate(moment());
        setMentorIdSelected(null);
        closeModal();
    }

    const onHandleDownload = () => {
        onDownload(initialDate, endDate, handleCloseModal);
    }

    useImperativeHandle(ref,
        () => ({ mentorIdSelected, setMentorIdSelected })
    );

    return (
        <Modal isOpen={isOpenModal} centered className='deleteElementModal'>
            <ModalHeader>
                <span>
                    Escoge el rango de tiempo
                </span>
                <IconButton className="closeButton" onClick={handleCloseModal}>
                    <Icon path={mdiClose} color="#131F2A" size="23px" />
                </IconButton>
            </ModalHeader>
            <ModalBody>
                <div className='datePickerContainer' style={{ justifyContent: "center" }}>
                    <LocalizationProvider dateAdapter={AdapterMoment}>
                        <DesktopDatePicker
                            inputFormat="DD/MM/YYYY"
                            value={initialDate}
                            maxDate={moment()}
                            onChange={e => setInitialDate(e)}
                            renderInput={(params) => <TextField {...params} />}
                        />
                        <DesktopDatePicker
                            inputFormat="DD/MM/YYYY"
                            value={endDate}
                            maxDate={moment()}
                            onChange={e => setEndDate(e)}
                            renderInput={(params) => <TextField {...params} />}
                        />
                    </LocalizationProvider>
                </div>
            </ModalBody>
            <ModalFooter>
                <Button color='primary' outline onClick={handleCloseModal}>Cancelar</Button>
                <Button color='primary' onClick={onHandleDownload} disabled={isLoading}>Descargar</Button>
            </ModalFooter>
        </Modal>
    )
});

DownloadMentorReportModal.propTypes = {
    isOpenModal: PropTypes.bool.isRequired,
    isLoading: PropTypes.bool.isRequired,
    closeModal: PropTypes.func.isRequired,
    onDownload: PropTypes.func.isRequired,
}

export default DownloadMentorReportModal;