import React, { useState, useEffect } from 'react';
import { Modal, Input, ModalBody, Button } from 'reactstrap';
import { IconButton } from '@mui/material';
import Icon from '@mdi/react';
import { mdiClose } from '@mdi/js';
import { useFormik } from "formik";
import { Switch } from "@mui/material";

import { useDispatch, useSelector } from 'react-redux';
import { createSubscription, updateSubscription } from "../../../redux/actions/subscriptionsActions";
import { getCategories } from '../../../redux/actions/categoriesActions';
import getUniqueValuesFromArray from '../../../commons/functions/getUniqueValuesFromArray';
const yup = require("yup");

const CreateSubscription = ({ isOpenModal, closeModal, createMode, initialData, paramsGet }) => {
    const dispatch = useDispatch();
    const categoriesList = useSelector((state) => state.categories.categories?.data);
    const isLoading = useSelector((state) => state.subscriptions.isFetching);

    useEffect(() => {
        dispatch(getCategories({
            data_all: "YES"
        }))
    }, [])

    const formik = useFormik({
        initialValues: {
            name: createMode ? "" : initialData?.name,
            description: createMode ? "" : initialData?.description,
            annualPrice: createMode ? "" : initialData?.annual_price,
            monthlyPrice: createMode ? "" : initialData?.monthly_price,
            freeMonth: createMode ? true : initialData?.has_free_month,
            category: createMode ? "" : initialData?.category.id,
        },
        validationSchema: yup.object({
            name: yup.string().required(),
            description: yup.string().required(),
            annualPrice: yup.number().required(),
            monthlyPrice: yup.number().required(),
            category: yup.string().notOneOf([""]).required(),
        }),
        onSubmit: (values) => {
            if (createMode) {
                dispatch(createSubscription(
                    {
                        name: values.name,
                        description: values.description,
                        annual_price: values.annualPrice,
                        monthly_price: values.monthlyPrice,
                        has_free_month: values.freeMonth,
                        is_active: true,
                        category_id: values.category,
                    },
                    paramsGet,
                    handleClose
                ))
            }
            else {
                dispatch(updateSubscription(
                    {
                        name: values.name,
                        description: values.description,
                        annual_price: values.annualPrice,
                        monthly_price: values.monthlyPrice,
                        has_free_month: values.freeMonth,
                        is_active: true,
                        id: initialData.id,
                        category_id: values.category,
                    },
                    paramsGet,
                    handleClose
                ))
            }
        }
    });

    const handleClose = () => {
        formik.resetForm();
        closeModal();
    }

    return (
        <Modal isOpen={isOpenModal} centered className='createCategoryModal'>
            <div className='containButton'>
                <IconButton onClick={handleClose} className="closeButton">
                    <Icon path={mdiClose} color="#131F2A" size="23px" />
                </IconButton>
            </div>
            <ModalBody>
                <form onSubmit={formik.handleSubmit}>
                    <Input
                        placeholder='Nombre de la suscripción'
                        name={'name'}
                        value={formik.values.name}
                        onChange={formik.handleChange}
                        invalid={formik.submitCount > 0 && formik.errors.name}
                        style={{ width: '100%' }}
                    />
                    <Input
                        placeholder='Descripción'
                        name={'description'}
                        value={formik.values.description}
                        onChange={formik.handleChange}
                        invalid={formik.submitCount > 0 && formik.errors.description}
                        style={{ width: '100%' }}
                        type="textarea"
                    />
                    <Input
                        placeholder='Precio Anual'
                        name={'annualPrice'}
                        value={formik.values.annualPrice}
                        onChange={formik.handleChange}
                        invalid={formik.submitCount > 0 && formik.errors.annualPrice}
                        style={{ width: '100%' }}
                        type="number"
                    />
                    <Input
                        placeholder='Precio Mensual'
                        name={'monthlyPrice'}
                        value={formik.values.monthlyPrice}
                        onChange={formik.handleChange}
                        invalid={formik.submitCount > 0 && formik.errors.monthlyPrice}
                        style={{ width: '100%' }}
                        type="number"
                    />
                    {categoriesList &&
                        <Input
                            id="category"
                            type="select"
                            onChange={formik.handleChange}
                            value={formik.values.category}
                            invalid={formik.submitCount > 0 && !!formik.errors.category}
                            style={{ color: formik.values.category === "" ? "#9ea6ab" : "#131F2A" }}
                        >
                            <option key="select" value="" style={{ display: "none" }}>
                                Selecciona Categoría
                            </option>
                            {
                                getUniqueValuesFromArray(categoriesList, d => d.name)?.map((category, index) => (
                                    <option key={`Category${index}`} value={category.id} style={{ color: "#131F2A" }}>
                                        {category.name}
                                    </option>
                                ))
                            }
                        </Input>
                    }
                    <div style={{ marginLeft: 10, display: "flex", alignItems: "center" }}>
                        <h6>Mes Gratuito</h6>
                        <Switch
                            size="medium"
                            name={'freeMonth'}
                            value={formik.values.freeMonth}
                            onClick={formik.handleChange}
                            className="toggleSwitch"
                            checked={formik.values.freeMonth}
                        />
                    </div>
                    <div className='buttons' style={{ marginTop: 0 }}>
                        <Button className='secondarybutton' onClick={handleClose}>Cancelar</Button>
                        <Button className='principalbutton' type='submit' disabled={isLoading}>Guardar</Button>
                    </div>
                </form>
            </ModalBody>
        </Modal>
    )
}

export default CreateSubscription;