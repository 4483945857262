import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Spinner } from 'reactstrap';
import ActionsPanel from "../../commons/components/tableComponents/ActionsPanel";
import TwoRowsText from "../../commons/components/tableComponents/TwoRowsText";
import ActiveTextIndicator from "../../commons/components/tableComponents/ActiveTextIndicator";
import Table from '../../commons/components/Table';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { getPodcasts, updatePodcast, deletePodcast } from "../../redux/actions/coursesActions";
import { rowsPerPageOptions } from "../../commons/constants/pagination";

const columnsTable = [
    {
        value: 'code',
        title: 'Cod',
        isCustom: false
    },
    {
        value: 'title',
        title: 'Nombre del curso',
        isCustom: false
    },
    {
        value: 'description',
        title: 'Descripción',
        isCustom: true,
        CustomElement: TwoRowsText
    },
    {
        value: 'category',
        title: 'Categoría',
        isCustom: false
    },
    {
        value: 'is_active',
        title: 'Estado',
        isCustom: true,
        CustomElement: ActiveTextIndicator
    },
    {
        value: 'is_active',
        title: 'Acciones',
        isCustom: true,
        notIsSortable: true,
        CustomElement: ActionsPanel,
        updateDispatchAction: updatePodcast,
        editionRedirect: true,
        editionRedirectURL: '/cursos/editarPodcast',
        state: 'courses.podcasts',
        deleteOption: true,
        deleteDispatchAction: deletePodcast
    }
]

const handleData = (data) => (
    data.map(({ id, code, title, description, category, is_active, is_deleted }) => ({
        code,
        title,
        description,
        category: category?.name,
        is_active,
        id
    }))
)
const Podcast = () => {
    const dispatch = useDispatch();
    const podcastsList = useSelector((state) => state.courses.podcasts);
    const refTable = useRef(null);
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const pageParam = searchParams.get('page');
    const rowsPerPageParam = searchParams.get('rowsPerPage');

    useEffect(() => {
        podcastsRequest(
            pageParam ? (parseInt(pageParam) + 1) : 1,
            rowsPerPageParam ? rowsPerPageParam : rowsPerPageOptions[0]
        )
    }, []);

    const podcastsRequest = (page, page_size) => {
        dispatch(getPodcasts({
            page,
            page_size
        }))
    }

    const handleUpdatePodcast = (newValues, { id, code, category, estimated_duration, podcast_cover_url, podcast_audio_url, description, title }) => {
        const body = {
            title,
            description,
            podcast_audio_url,
            podcast_cover_url,
            estimated_duration,
            category_id: category.id,
            code,
            id,
            is_active: newValues.is_active
        }
        dispatch(updatePodcast(
            body,
            {
                page: refTable.current.page + 1,
                page_size: refTable.current.rowsPerPage,
            },
            1
        ))
    }

    return (
        <div className='coursesContainer'>
            <div className='headerContainer'>
                <div className='leftHeaderContainer'>
                    <h4>Mis Podcast</h4>
                </div>
                <button className='addItemButton' onClick={() => navigate(`/cursos/crearPodcast`)}>
                    Subir un podcast
                </button>
            </div>
            {
                podcastsList
                    ? <Table
                        columns={columnsTable}
                        data={handleData(podcastsList.data)}
                        changePage={(newPage, pageSize) => podcastsRequest(newPage + 1, pageSize)}
                        changeRowsPerPage={(pageSize) => podcastsRequest(1, pageSize)}
                        count={podcastsList.count}
                        ref={refTable}
                        updateData={handleUpdatePodcast}
                    />
                    : <Spinner style={{ marginLeft: "45%", marginTop: "46px" }} />
            }
        </div>
    )
}

export default Podcast;