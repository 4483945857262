import axios, { defaultParams } from './axios';

export default async (url, body, params) => {
  try {
    return await axios.put(url, body, { ...defaultParams(), ...params });
  } catch (e) {
    if (!e || !e.response || e.response.status !== 401) throw e;
    if (e.response.status === 401) {
      localStorage.removeItem("token")
      window.location.replace("/")
    }
    localStorage.setItem('url', `${window.location.pathname}${window.location.search}`);
    try {
      return axios.put(url, body, { ...defaultParams(), ...params });
    } catch (err) {
      return null;
    }
  }
};

export const putFormData = async (url, body, params) => {
  try {
    const defParams = defaultParams();
    defParams.headers["Content-Type"] = "multipart/form-data";
    return await axios.put(url, body, { ...defParams, ...params });
  } catch (e) {
    if (!e || !e.response || e.response.status !== 401) throw e;
    if (e.response.status === 401) {
      localStorage.removeItem("token")
      window.location.replace("/")
    }
    localStorage.setItem("url", `${window.location.pathname}${window.location.search}`);
    try {
      return axios.put(url, body, { ...defaultParams(), ...params });
    } catch (err) {
      return null;
    }
  }
};