import React, { useState } from 'react';
import { IconButton, Switch } from '@mui/material';
import Icon from '@mdi/react';
import { mdiDelete, mdiSquareEditOutline, mdiDownload, mdiClose } from '@mdi/js';
import { useDispatch, useSelector } from 'react-redux';
import getObjectFromString from '../../functions/getObjectFromString';
import { Modal, ModalBody, Button, ModalHeader, ModalFooter } from 'reactstrap';

const ActionsPanelComplete = ({ id, page, rowsPerPage, dataColumn, handleDownload }) => {
    const dispatch = useDispatch();
    const dataState = useSelector((state) => getObjectFromString(state, dataColumn?.state));//get state sending name as string
    const dataFiltered = dataState?.data.find(element => element.id === id) //filter the data with the user required
    const [isOpenEditModal, setIsOpenEditModal] = useState(false);
    const [isOpenDeleteModal, setIsOpenDeleteModal] = useState(false);

    const handleChangeStatus = (e) => {
        e.preventDefault();
        dispatch(dataColumn.updateDispatchAction(
            {
                ...dataFiltered,
                is_active: e.target.checked
            },
            {
                page: page + 1,
                page_size: rowsPerPage
            },
            1
        ))
    }

    const handleEdit = () => {
        setIsOpenEditModal(true)
    }

    const handleDelete = () => {
        dispatch(dataColumn.deleteDispatchAction(
            id,
            {
                page: page + 1,
                page_size: rowsPerPage
            }
        ))
        setIsOpenDeleteModal(false)
    }

    const download = () => {
        if (handleDownload) {
            handleDownload(id)
        }
    }

    return (
        <div style={{ padding: '0px 15px' }}>
            <IconButton onClick={() => setIsOpenDeleteModal(true)}>
                <Icon path={mdiDelete} color="#000" size="23px" />
            </IconButton>
            <IconButton onClick={() => setIsOpenEditModal(true)}>
                <Icon path={mdiSquareEditOutline} color="#000" size="23px" />
            </IconButton>
            <IconButton onClick={download}>
                <Icon path={mdiDownload} color="#000" size="23px" />
            </IconButton>
            <Switch
                size="small"
                onClick={handleChangeStatus}
                className="toggleSwitch"
                checked={dataFiltered?.is_active}
            />
            {
                React.createElement( //Custom modal defined in table`s columns
                    dataColumn.editModalElement,
                    {
                        isOpenModal: isOpenEditModal,
                        closeModal: () => setIsOpenEditModal(false),
                        createCategory: handleEdit,
                        createMode: false,
                        initialData: dataFiltered,
                        page,
                        rowsPerPage
                    }
                )
            }
            <Modal isOpen={isOpenDeleteModal} centered className='deleteElementModal'>
                <ModalHeader>
                    <span>
                        Eliminar elemento
                    </span>
                    <IconButton className="closeButton" onClick={() => setIsOpenDeleteModal(false)}>
                        <Icon path={mdiClose} color="#131F2A" size="23px" />
                    </IconButton>
                </ModalHeader>
                <ModalBody>
                    Estás seguro que quieres eliminar este elemento?
                </ModalBody>
                <ModalFooter>
                    <Button color='danger' outline onClick={() => setIsOpenDeleteModal(false)}>Cancelar</Button>
                    <Button color='danger' onClick={handleDelete}>Confirmar</Button>
                </ModalFooter>
            </Modal>
        </div>
    )
}

export default ActionsPanelComplete;