import React, { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Spinner } from 'reactstrap';
import ActionsPanel from "../../commons/components/tableComponents/ActionsPanel";
import ActiveTextIndicator from "../../commons/components/tableComponents/ActiveTextIndicator";
import Table from '../../commons/components/Table';
import CreateCourseTypeModal from "./components/CreateCourseTypeModal"

import { getCourseType, updateCourseType } from "../../redux/actions/categoriesActions";
import { rowsPerPageOptions } from "../../commons/constants/pagination";

const columnsTable = [
    {
        value: 'id',
        title: 'No',
        isCustom: false
    },
    {
        value: 'name',
        title: 'Tipo de Curso',
        isCustom: false
    },
    {
        value: 'is_active',
        title: 'Estado',
        isCustom: true,
        CustomElement: ActiveTextIndicator
    },
    {
        value: 'is_active',
        title: 'Acciones',
        isCustom: true,
        notIsSortable: true,
        CustomElement: ActionsPanel,
        editModalElement: CreateCourseTypeModal,
        updateDispatchAction: updateCourseType,
        state: 'categories.courseType',
        deleteOption: false
    }
]

const handleData = (data) => (
    data.map(({ id, name, is_active, is_deleted }) => ({
        id,
        name,
        is_active,
        is_deleted
    }))
)


const CourseType = () => {
    const dispatch = useDispatch();
    const CoursesTypesList = useSelector((state) => state.categories?.courseType);
    const refTable = useRef(null);

    useEffect(() => {
        courseTypeRequest(1, rowsPerPageOptions[0])
    }, []);

    const courseTypeRequest = (page, page_size) => {
        dispatch(getCourseType(
            {
                page,
                page_size,
            }
        ))
    }

    const handleUpdateCourseType = (newValues, courseTypeInitialData) => {
        const body = {
            id: courseTypeInitialData.id,
            name: courseTypeInitialData.name,
            code: courseTypeInitialData.code,
            is_active: courseTypeInitialData.is_active
        }
        dispatch(updateCourseType(
            {
                ...body,
                ...newValues
            },
            {
                page: refTable.current.page + 1,
                page_size: refTable.current.rowsPerPage,
            },
            1
        ))
    }

    return (
        <div className='categoriesContainer'>
            <div className='headerContainer'>
                <div className='leftHeaderContainer'>
                    <h4>Tipo de curso</h4>
                </div>
            </div>
            {
                CoursesTypesList
                    ? <Table
                        columns={columnsTable}
                        data={handleData(CoursesTypesList.data)}
                        changePage={(newPage, pageSize) => courseTypeRequest(newPage + 1, pageSize)}
                        changeRowsPerPage={(pageSize) => courseTypeRequest(1, pageSize)}
                        count={CoursesTypesList.count}
                        ref={refTable}
                        updateData={handleUpdateCourseType}
                    />
                    : <Spinner style={{ marginLeft: "45%", marginTop: "46px" }} />
            }
        </div>
    )
}

export default CourseType;