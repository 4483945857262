import { handleActions } from 'redux-actions';
import {
    fetchCategoriesRequest,
    fetchCategoriesSuccess,
    fetchSubcategoriesSuccess,
    fetchCourseTypeSuccess,
    fetchModalCategoryRequest,
    fetchModalCategorySuccess,
    fetchCategoriesError
} from '../actions/categoriesActions';

const defaultState = {
    categories: null,
    subcategories: null,
    courseType: null,
    isFetching: false,
    modalActionStatus: 'STARTED',
    error: null,
};

export default handleActions(
    {
        [fetchCategoriesRequest](state) {
            return {
                ...state,
                isFetching: true,
            };
        },
        [fetchCategoriesSuccess](state, { payload: { results, count } }) {
            return {
                ...state,
                categories: {
                    data: results,
                    count
                },
                modalActionStatus: 'STARTED',
                isFetching: false,
                error: null,
            };
        },
        [fetchSubcategoriesSuccess](state, { payload: { results, count, preserveStatusModal } }) {
            return {
                ...state,
                subcategories: {
                    data: results,
                    count
                },
                modalActionStatus: preserveStatusModal ? state.modalActionStatus : 'STARTED',
                isFetching: false,
                error: null,
            };
        },
        [fetchCourseTypeSuccess](state, { payload: { results, count } }) {
            return {
                ...state,
                courseType: {
                    data: results,
                    count
                },
                modalActionStatus: 'STARTED',
                isFetching: false,
                error: null,
            };
        },
        [fetchModalCategoryRequest](state) {
            return {
                ...state,
                modalActionStatus: 'LOADING',
            };
        },
        [fetchModalCategorySuccess](state) {
            return {
                ...state,
                modalActionStatus: 'COMPLETED',
            };
        },
        [fetchCategoriesError](state, { payload }) {
            return {
                ...state,
                isFetching: false,
                error: payload,
            };
        }
    },
    defaultState,
);