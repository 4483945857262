import { handleActions } from 'redux-actions';
import {
    fetchDashboardRequest,
    fetchGeneralStatisticsSuccess,
    fetchUserRegisterStatisticsSuccess,
    fetchDashboardError
} from '../actions/dashboardActions';

const defaultState = {
    isFetching: false,
    error: null,
    generalStatistics: null,
    userRegisterStatistics: null
};

export default handleActions(
    {
        [fetchDashboardRequest](state) {
            return {
                ...state,
                isFetching: true,
            };
        },
        [fetchGeneralStatisticsSuccess](state, { payload }) {
            return {
                ...state,
                generalStatistics: payload,
                isFetching: false,
                error: null
            };
        },
        [fetchUserRegisterStatisticsSuccess](state, { payload }) {
            return {
                ...state,
                userRegisterStatistics: payload,
                error: null
            };
        },
        [fetchDashboardError](state, { payload }) {
            return {
                ...state,
                isFetching: false,
                error: payload,
            };
        }
    },
    defaultState,
);