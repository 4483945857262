import { createAction } from "redux-actions";
import api from "../../utils/api/apiClient";
import { toast } from "react-toastify";

export const fetchDashboardRequest = createAction('FETCH_ASSESSMENTS_REQUEST');
export const fetchGeneralStatisticsSuccess = createAction('FETCH_GENERAL_STATISTICS_SUCCESS');
export const fetchUserRegisterStatisticsSuccess = createAction('FETCH_USER_REGISTER_STATISTICS_SUCCESS');
export const fetchDashboardError = createAction('FETCH_ASSESSMENTS_ERROR');

export const getGeneralStatistics = () => async (dispatch) => {
    try {
        dispatch(fetchDashboardRequest());
        const { data } = await api.dashboardApi.getGeneralStatistics();
        dispatch(fetchGeneralStatisticsSuccess(data));
    } catch (error) {
        errorFunction(error, dispatch);
    }
};

export const getStatisticsUserRegister = (params) => async (dispatch) => {
    try {
        const { data } = await api.dashboardApi.getStatisticsUserRegister(params);
        dispatch(fetchUserRegisterStatisticsSuccess(data));
    } catch (error) {
        errorFunction(error, dispatch);
    }
};

const errorFunction = (error, dispatch) => {
    const errorMessage = error?.response?.data?.message ? error.response.data.message : error.message
    dispatch(fetchDashboardError(errorMessage));
    toast.error(errorMessage, { autoClose: 3000, theme: "colored" });
}