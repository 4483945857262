import React, { useEffect, useState } from 'react';
import { Input, Button, Spinner } from 'reactstrap';
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';

import { getAdvertisements, updateAdvertisements } from "../../redux/actions/coursesActions";

const Advertisements = () => {
    const dispatch = useDispatch();
    const [title, setTitle] = useState("");
    const [description, setDescription] = useState("");
    const [submitted, setSubmitted] = useState(false);

    const isLoading = useSelector(state => state.courses.isFetching);
    const advertisements = useSelector(state => state.courses.advertisements);


    useEffect(() => {
        dispatch(getAdvertisements())
    }, [])

    useEffect(() => {
        if (advertisements) {
            setTitle(advertisements.title);
            setDescription(advertisements.description)
        }
    }, [advertisements])

    const onSubmit = () => {
        setSubmitted(true)
        if (title !== "" && description !== "" && (advertisements.title !== title || advertisements.description !== description)) {
            dispatch(updateAdvertisements({
                title: title,
                description: description,
                is_active: advertisements.is_active,
                id: advertisements.id
            }))
            setSubmitted(false)
        }
        else toast.error("Válida los cambios y campos obligatorios", { autoClose: 3000, theme: "colored", toastId: 'errorFields' })
    }

    return (
        <div className='advertisementsContainer'>
            <div className='header'>
                <h6>Anuncios </h6>
            </div>
            {
                !isLoading && advertisements
                    ? <div>
                        <div>
                            <Input
                                placeholder='Título del comunicado'
                                style={{ marginBottom: 15 }}
                                value={title}
                                onChange={e => setTitle(e.target.value)}
                                invalid={submitted && title === ""}
                            />
                            <Input
                                placeholder='Descripción'
                                type='textarea'
                                value={description}
                                onChange={e => setDescription(e.target.value)}
                                invalid={submitted && description === ""}
                            />
                        </div>
                        <div className='buttons'>
                            <Button
                                color='primary'
                                onClick={onSubmit}
                                disabled={title === advertisements.title && description === advertisements.description}
                            >
                                Actualizar
                            </Button>
                        </div>
                    </div>
                    : <Spinner style={{ marginLeft: "45%" }} />
            }
        </div>
    )
}

export default Advertisements;