import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import Table from '../../commons/components/Table';
import { Spinner } from 'reactstrap';
import TwoRowsText from "../../commons/components/tableComponents/TwoRowsText";
import ActionsPanel from "../../commons/components/tableComponents/ActionsPanel";
import CreateSubcategoryModal from "../Categories/components/CreateSubcategoryModal"
import CreateSubscriptionModal from "./components/CreateSubscription"

import { getSubscriptions, deleteSubscription } from "../../redux/actions/subscriptionsActions";
import { rowsPerPageOptions } from "../../commons/constants/pagination";

const columnsTable = [
    {
        value: 'id',
        title: 'No',
        isCustom: false
    },
    {
        value: 'name',
        title: 'Nombre',
        isCustom: false
    },
    {
        value: 'description',
        title: 'Descripción',
        isCustom: true,
        CustomElement: TwoRowsText
    },
    {
        value: 'monthly_price',
        title: 'Mensual',
        isCustom: false
    },
    {
        value: 'annual_price',
        title: 'Anual',
        isCustom: false
    },
    {
        value: 'num_purchases',
        title: 'Compras',
        isCustom: false
    },
    {
        value: 'category',
        title: 'Categoría',
        isCustom: false
    },
    {
        value: 'is_active',
        title: 'Acciones',
        isCustom: true,
        notIsSortable: true,
        CustomElement: ActionsPanel,
        editModalElement: CreateSubscriptionModal,
        state: 'subscriptions.subscriptions',
        deleteOption: true,
        deleteDispatchAction: deleteSubscription
    }
]

const handleData = (data) => (
    data.map(({ id, name, description, annual_price, monthly_price, num_purchases, category, is_active }) => ({
        id, name, description, monthly_price, annual_price, num_purchases, category: category.name, is_active
    }))
)

const Subscriptions = () => {
    const dispatch = useDispatch();
    const [isOpenModal, setIsOpenModal] = useState(false);
    const subscriptionsList = useSelector((state) => state.subscriptions.subscriptions);
    const refTable = useRef(null);

    useEffect(() => {
        subscriptionsRequest(1, rowsPerPageOptions[0])
    }, []);

    const subscriptionsRequest = (page, page_size) => {
        dispatch(getSubscriptions({
            page,
            page_size
        }))
    }

    return (
        <div className='subscriptionsContainer'>
            <div className='headerContainer'>
                <div className='leftHeaderContainer'>
                    <h4>Suscripciones</h4>
                </div>
                <button className='addItemButton' onClick={() => setIsOpenModal(true)}>
                    Añadir Suscripción
                </button>
            </div>
            {
                subscriptionsList
                    ? <Table
                        columns={columnsTable}
                        data={handleData(subscriptionsList.data)}
                        changePage={(newPage, pageSize) => subscriptionsRequest(newPage + 1, pageSize)}
                        changeRowsPerPage={(pageSize) => subscriptionsRequest(1, pageSize)}
                        count={subscriptionsList.count}
                        ref={refTable}
                    />
                    : <Spinner style={{ marginLeft: "45%", marginTop: "46px" }} />
            }
            <CreateSubscriptionModal
                isOpenModal={isOpenModal}
                closeModal={() => setIsOpenModal(false)}
                createMode={true}
                paramsGet={{
                    page: refTable.current?.page + 1,
                    page_size: refTable.current?.rowsPerPage,
                }}
            />
        </div>
    )
}

export default Subscriptions;