import React, { useEffect, useCallback, useState } from 'react'
import { Spinner, Input, Button } from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useFormik } from "formik";
import QuestionItem from "./components/QuestionItem"
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import { getAssessments, createAssessment, getAssessment, deleteQuestionsAssessment } from "../../redux/actions/assessmentsAction";
import { getCategories } from "../../redux/actions/categoriesActions";
import getUniqueValuesFromArray from '../../commons/functions/getUniqueValuesFromArray';
const yup = require("yup");

const assessmentTypeList = [{
    title: "Pre-prueba",
    value: "pre_test"
},
{
    title: "Post-prueba",
    value: "post_test"
}]

const CreateAssessment = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { assessmentId } = useParams();
    const categoriesList = useSelector((state) => state.categories.categories?.data);
    const assessment = useSelector((state) => state.assessments.assessment);
    const assessmentsIsLoading = useSelector((state) => state.assessments.isFetching);
    const assessmentsList = useSelector((state) => state.assessments.assessments?.data);
    const [questionsListToDelete, setQuestionsListToDelete] = useState([]);
    const [optionsListToDelete, setOptionsListToDelete] = useState([]);

    const formik = useFormik({
        initialValues: {
            assessmentType: "pre_test",
            category: "",
            questions: [{
                questionText: "",
                questionType: "required",
                responseType: "text_field",
                id: null,
                multiResponses: []
            }]
        },
        validationSchema: yup.object({
            assessmentType: yup.string().notOneOf([""]).required(),
            category: yup.string().notOneOf([""]).required(),
            questions: yup.array().of(
                yup.object().shape({
                    questionText: yup.string().required(),
                    questionType: yup.string().required(),
                    responseType: yup.string().required(),
                    multiResponses: yup.array().when('responseType', {
                        is: val => val === "single_option" || val === "multiple_options",
                        then: yup.array().of(
                            yup.object().shape({
                                text: yup.string().required()
                            })
                        )
                    })
                })
            ).required(),
        }),
        onSubmit: (values) => {
            if (assessmentId) {
                const body = {
                    id: assessmentId,
                    type: values.assessmentType,
                    category_id: categoriesList.filter(category => category?.name === values.category)[0]?.id,
                    course_id: null,
                    is_active: assessmentsList?.filter(assessment => assessment?.id == assessmentId)[0]?.is_active,
                    assessment_questions: values.questions.map((question, index) => ({
                        id: question.id,
                        question: question.questionText,
                        type: question.responseType === "text_field" ? "text" : question.responseType === "single_option" ? "radio" : "checkbox",
                        required: question.questionType ? true : false,
                        order: index + 1,
                        assessment_question_options: question.responseType === "text_field" ? [] : question.multiResponses.map((response, index) => ({
                            id: response.id,
                            value: response.text,
                            order: index + 1
                        }))
                    }))
                }
                if (questionsListToDelete.length > 0 || optionsListToDelete.length > 0) dispatch(
                    deleteQuestionsAssessment({
                        "assessment_question_ids": questionsListToDelete,
                        "assessment_question_option_ids": optionsListToDelete
                    },
                        () => dispatch(createAssessment(
                            body,
                            () => navigate(`/evaluaciones`),
                            false
                        ))
                    )
                )
                else dispatch(
                    createAssessment(
                        body,
                        () => navigate(`/evaluaciones`),
                        false
                    )
                )
            }
            else {
                const body = {
                    id: null,
                    type: values.assessmentType,
                    category_id: categoriesList.filter(category => category?.name === values.category)[0]?.id,
                    course_id: null,
                    is_active: true,
                    assessment_questions: values.questions.map((question, index) => ({
                        id: question.id,
                        question: question.questionText,
                        type: question.responseType === "text_field" ? "text" : question.responseType === "single_option" ? "radio" : "checkbox",
                        required: question.questionType ? true : false,
                        order: index + 1,
                        assessment_question_options: question.responseType === "text_field" ? [] : question.multiResponses.map((response, index) => ({
                            id: response.id,
                            value: response.text,
                            order: index + 1
                        }))
                    }))
                }
                dispatch(createAssessment(
                    body,
                    () => navigate(`/evaluaciones`),
                    true
                ))
            }
        }
    });

    useEffect(() => {
        if (!categoriesList) dispatch(getCategories({
            data_all: "YES"
        }))
        if (!assessmentsList) dispatch(getAssessments({
            data_all: "YES"
        }))
        if (assessmentId) dispatch(getAssessment(assessmentId));
    }, [])

    useEffect(() => {
        if (assessmentId && assessment && !assessmentsIsLoading) {
            let initialQuestionsOrdered = assessment;

            initialQuestionsOrdered.sort((a, b) => {
                if (a.order > b.order) return 1;
                if (a.order < b.order) return -1;
                return 0;
            })

            initialQuestionsOrdered.map(element => ({
                ...element,
                "fk_assessment_question_options": element.fk_assessment_question_options.sort((a, b) => {
                    if (a.order > b.order) return 1;
                    if (a.order < b.order) return -1;
                    return 0;
                })
            }))

            const assessmentFiltered = assessmentsList?.filter(assessment => assessment?.id == assessmentId)[0]
            formik.setFieldValue("category", categoriesList?.filter(category => category?.id === assessmentFiltered?.category?.id)[0]?.name)
            formik.setFieldValue("assessmentType", assessmentFiltered?.type)

            const initialQuestions = initialQuestionsOrdered.map(question => ({
                questionText: question.question,
                questionType: question.required ? "required" : "not_required",
                responseType: question.type === "text" ? "text_field" : question.type === "radio" ? "single_option" : "multiple_options",
                id: question.id,
                multiResponses: question.type === "text" ? [] : question.fk_assessment_question_options.map(option => ({
                    id: option.id,
                    text: option.value
                }))
            }));
            formik.setFieldValue("questions", initialQuestions)
        }
    }, [assessment])

    const addQuestion = () => {
        formik.setFieldValue(
            "questions",
            [
                ...formik.values.questions,
                {
                    questionText: "",
                    questionType: "required",
                    responseType: "text_field",
                    id: null,
                    multiResponses: []
                }
            ]
        )
    }

    const moveQuestionItem = useCallback((dragIndex, hoverIndex) => {
        const dragItem = formik.values.questions[dragIndex]
        const hoverItem = formik.values.questions[hoverIndex]
        const updatedQuestions = [...formik.values.questions]
        updatedQuestions[dragIndex] = hoverItem
        updatedQuestions[hoverIndex] = dragItem
        formik.setFieldValue("questions", updatedQuestions)
    }, [formik.values.questions])

    const handleSubmit = () => {
        formik.submitForm();
    }

    return (
        <div className='assessmentsContainer'>
            <div className='headerContainer'>
                <div className='leftHeaderContainer'>
                    <h4>Evaluación</h4>
                </div>
            </div>
            {!assessmentsIsLoading && categoriesList ?
                <div>
                    <div className='selectorsContainer'>
                        <Input
                            id="assessmentType"
                            type="select"
                            onChange={formik.handleChange}
                            value={formik.values.assessmentType}
                        >
                            {
                                assessmentTypeList.map(type => (
                                    <option key={`type_${type.value}`} value={type.value}>
                                        {type.title}
                                    </option>
                                ))
                            }
                        </Input>
                        {categoriesList &&
                            <Input
                                id="category"
                                type="select"
                                onChange={formik.handleChange}
                                value={formik.values.category}
                                invalid={formik.submitCount > 0 && !!formik.errors.category}
                                style={{ color: formik.values.category === "" ? "#9ea6ab" : "#131F2A" }}
                            >
                                <option key="select" value="" style={{ display: "none" }}>
                                    Selecciona Categoría
                                </option>
                                {
                                    getUniqueValuesFromArray(categoriesList, d => d.name)?.map((category, index) => (
                                        <option key={`Category${index}`} value={category.name} style={{ color: "#131F2A" }}>
                                            {category.name}
                                        </option>
                                    ))
                                }
                            </Input>
                        }
                    </div>
                    <div>
                        <h4 style={{ marginTop: "30px" }}>Preguntas</h4>
                        {
                            formik.values.questions.map((question, index) =>
                                <QuestionItem
                                    index={index}
                                    formik={formik}
                                    moveListItem={moveQuestionItem}
                                    key={`question${index}`}
                                    setQuestionsListToDelete={(id) => setQuestionsListToDelete([...questionsListToDelete, id])}
                                    setOptionsListToDelete={(id) => setOptionsListToDelete([...optionsListToDelete, id])}
                                />
                            )
                        }
                        <div className='addQuestion'>
                            <Button onClick={addQuestion} type="button">
                                Añadir otra pregunta
                            </Button>
                            <Button type='button' color='primary' disabled={assessmentsIsLoading} onClick={handleSubmit}>
                                Guardar
                            </Button>
                        </div>
                    </div>
                </div>
                : <Spinner style={{ marginLeft: "45%", marginTop: "46px" }} />
            }
        </div >
    )
}

export default CreateAssessment;