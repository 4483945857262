import React from 'react'
import Sidebar from '../commons/components/Sidebar'

const WrappedRoutes = ({ children }) => {

    return (
        <div style={{ display: "flex" }}>
            <Sidebar />
            {children}
        </div>
    )
}

export default WrappedRoutes;
