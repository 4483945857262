import React, { useEffect } from 'react';
import { Input, Button, Spinner } from 'reactstrap';
import DropZone from '../../commons/components/DropZone';
import { useFormik } from "formik";
import Icon from '@mdi/react';
import { mdiClose } from '@mdi/js';
import TimePicker from 'rc-time-picker';
import 'rc-time-picker/assets/index.css';
import moment from 'moment';
import { toast } from 'react-toastify';
import { useNavigate, useParams } from 'react-router-dom';

import { useDispatch, useSelector } from 'react-redux';
import { getCategories } from "../../redux/actions/categoriesActions";
import { updatePodcastComplete, createPodcast } from "../../redux/actions/coursesActions";
const yup = require("yup");

const convertHM = (seconds) => {
    let hour = Math.floor(seconds / 3600);
    let minute = Math.floor((seconds - (hour * 3600)) / 60);

    return ({
        hour,
        minute
    })
}

const CreatePodcast = () => {
    const dispatch = useDispatch();
    const { courseId } = useParams();
    const categoriesList = useSelector((state) => state.categories.categories?.data);
    const podcastsList = useSelector((state) => state.courses?.podcasts?.data);
    const courseToEdit = courseId && podcastsList ? podcastsList.find(course => course.id == courseId) : null
    const isSendingRequest = useSelector((state) => state.courses.modalActionStatus);
    const navigate = useNavigate();

    const formik = useFormik({
        initialValues: {
            category: courseToEdit ? courseToEdit?.category?.id : "",
            courseAudio: courseToEdit
                ? [{
                    name: courseToEdit?.podcast_audio_url?.slice(courseToEdit?.podcast_audio_url.lastIndexOf('/') + 1).replace(/%20/g, ' ')
                }]
                : [],
            estimatedTime: courseToEdit ? moment().set(convertHM(courseToEdit?.estimated_duration)) : null,
            title: courseToEdit ? courseToEdit.title : "",
            description: courseToEdit ? courseToEdit.description : "",
            coursePicture: courseToEdit
                ? [{
                    name: courseToEdit?.podcast_cover_url?.slice(courseToEdit?.podcast_cover_url.lastIndexOf('/') + 1).replace(/%20/g, ' '),
                    preview: courseToEdit?.podcast_cover_url
                }]
                : [],
            code: courseToEdit ? courseToEdit.code : "",
        },
        validationSchema: yup.object({
            category: yup.string().notOneOf([""]).required(),
            courseAudio: yup.array().min(1).required(),
            estimatedTime: yup.object().required(),
            title: yup.string().required(),
            description: yup.string().required(),
            coursePicture: yup.array().min(1).required(),
            code: yup.string().required()
        }),
        onSubmit: ({ code, courseAudio, coursePicture, description, estimatedTime, title, category }) => {
            let bodyFormData = new FormData();
            if (courseToEdit) {
                const isNewPodcastAudioFile = courseAudio[0]?.name === courseToEdit?.podcast_audio_url?.slice(courseToEdit?.podcast_audio_url.lastIndexOf('/') + 1).replace(/%20/g, ' ')
                    ? false
                    : true;
                bodyFormData.append("code", code);
                bodyFormData.append("title", title);
                bodyFormData.append("description", description);
                bodyFormData.append("podcast_audio_url", courseAudio[0]?.name === courseToEdit?.podcast_audio_url?.slice(courseToEdit?.podcast_audio_url.lastIndexOf('/') + 1).replace(/%20/g, ' ')
                    ? courseToEdit?.podcast_audio_url
                    : '');
                bodyFormData.append("estimated_duration", moment(estimatedTime).hour() * 3600 + moment(estimatedTime).minute() * 60);
                bodyFormData.append("podcast_cover_url", coursePicture[0]?.name === courseToEdit?.podcast_cover_url?.slice(courseToEdit?.podcast_cover_url.lastIndexOf('/') + 1).replace(/%20/g, ' ')
                    ? courseToEdit?.podcast_cover_url
                    : coursePicture[0]);
                bodyFormData.append("is_active", courseToEdit.is_active);
                bodyFormData.append("category_id", category);
                bodyFormData.append("id", courseToEdit.id);
                dispatch(updatePodcastComplete(bodyFormData, isNewPodcastAudioFile ? courseAudio[0] : null))
            }
            else {
                bodyFormData.append("code", code);
                bodyFormData.append("title", title);
                bodyFormData.append("description", description);
                bodyFormData.append("podcast_audio_url", '');
                bodyFormData.append("estimated_duration", moment(estimatedTime).hour() * 3600 + moment(estimatedTime).minute() * 60);
                bodyFormData.append("podcast_cover_url", coursePicture[0]);
                bodyFormData.append("is_active", "True");
                bodyFormData.append("category_id", category);
                dispatch(createPodcast(bodyFormData, courseAudio[0]))
            }
        }
    });

    useEffect(() => {
        if (isSendingRequest === "COMPLETED") navigate(`/cursos/podcasts`)
    }, [isSendingRequest])

    useEffect(() => {
        dispatch(getCategories({
            data_all: "YES"
        }))
    }, [])

    const validateEqualInitialData = () => {
        if (courseToEdit) {
            if (formik.values.category == courseToEdit?.category?.name &&
                formik.values.courseAudio[0].name === courseToEdit?.podcast_audio_url?.slice(courseToEdit?.podcast_audio_url.lastIndexOf('/') + 1).replace(/%20/g, ' ') &&
                //formik.values.estimatedTime === moment().set(convertHM(courseToEdit?.estimated_duration)) &&
                formik.values.title == courseToEdit.title &&
                formik.values.description == courseToEdit.description &&
                formik.values.coursePicture[0].name === courseToEdit?.podcast_cover_url?.slice(courseToEdit?.podcast_cover_url.lastIndexOf('/') + 1).replace(/%20/g, ' ') &&
                formik.values.code == courseToEdit.code
            ) {
                return (true)
            }
            return (false)
        }
        return (false)
    }

    return (
        <div className='coursesContainer'>
            <div className='headerContainer'>
                <div className='leftHeaderContainer'>
                    <h4>{courseId ? 'Editar' : 'Subir'} Podcast</h4>
                </div>
            </div>
            <div className='createCourseContainer'>
                <form className="formContainer" onSubmit={formik.handleSubmit}>
                    <div className='rowFields'>
                        <DropZone
                            onChange={(file) => formik.setFieldValue("courseAudio", file)}
                            pictures={formik.values.courseAudio}
                            multiple={false}
                            typeFiles={["audio/mpeg"]}
                            title={"Selecciona audio"}
                            invalid={formik.submitCount > 0 && formik.errors.courseAudio}
                        />
                        <Input
                            name={'category'}
                            type="select"
                            value={formik.values.category}
                            onChange={formik.handleChange}
                            style={{ color: formik.values.category === "" ? "#9ea6ab" : "#131F2A" }}
                            invalid={formik.submitCount > 0 && formik.errors.category}
                        >
                            <option key="select" value="" style={{ display: "none" }}>
                                Selecciona Categoría
                            </option>
                            {categoriesList?.map((category, index) => (
                                <option key={`category${index}`} value={category.id} style={{ color: "#131F2A" }}>
                                    {category.name}
                                </option>
                            ))}
                        </Input>
                        <TimePicker
                            placeholder='Tiempo estimado'
                            showSecond={false}
                            className={formik.submitCount > 0 && formik.errors.estimatedTime ? 'time-picker-error' : 'time-picker'}
                            onChange={(e) => formik.setFieldValue("estimatedTime", e ? e : null)}
                            defaultOpenValue={moment().set({ hour: 0, minute: 0 })}
                            value={formik.values.estimatedTime}
                            inputReadOnly
                            focusOnOpen
                        />
                    </div>
                    <div className='rowFields'>
                        <Input
                            placeholder='Título del Podcast'
                            name={'title'}
                            value={formik.values.title}
                            onChange={formik.handleChange}
                            invalid={formik.submitCount > 0 && formik.errors.title}
                            style={{ width: '100%' }}
                        />
                    </div>
                    <div className='rowFields'>
                        <Input
                            placeholder='Descripción del Podcast'
                            type='textarea'
                            name={'description'}
                            value={formik.values.description}
                            onChange={formik.handleChange}
                            invalid={formik.submitCount > 0 && formik.errors.description}
                        />
                    </div>
                    <div className='rowFields'>
                        <DropZone
                            onChange={(file) => formik.setFieldValue("coursePicture", file)}
                            pictures={formik.values.coursePicture}
                            multiple={false}
                            typeFiles={["image/jpeg", "image/png"]}
                            title={"Portada del Podcast"}
                            invalid={formik.submitCount > 0 && formik.errors.coursePicture}
                        />
                    </div>
                    <div className='rowFields'>
                        <Input
                            placeholder='Código'
                            name={'code'}
                            value={formik.values.code}
                            onChange={formik.handleChange}
                            invalid={formik.submitCount > 0 && formik.errors.code}
                        />
                    </div>

                    <div className='buttons' style={{ alignItems: "flex-start" }}>
                        <div className='coursePicturePreview'>
                            {formik.values.coursePicture?.length > 0 &&
                                <>
                                    <button onClick={() => formik.setFieldValue("coursePicture", [])}>
                                        <Icon path={mdiClose} color="black" size="16px" />
                                    </button>
                                    <img src={formik.values.coursePicture[0].preview} />
                                </>
                            }
                        </div>
                        <Button
                            type='submit'
                            color='primary'
                            disabled={isSendingRequest === "LOADING" || validateEqualInitialData()}
                        >
                            <span style={{ marginRight: '8px' }}>Subir Podcast</span>
                            {isSendingRequest === "LOADING" && (<Spinner
                                color="light"
                                size="sm"
                            />)}
                        </Button>
                    </div>
                </form>
            </div>
        </div>
    )
}

export default CreatePodcast;