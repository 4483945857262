import { getToken } from "../../utils/api/helpers";
import { toast } from "react-toastify";

export default function (type, params, callbackFuntcion) {
    const typeDocument = {
        eveluationResponses: {
            urlRequest: `${process.env.REACT_APP_URL_BASE}/assessment-report-by-subcategory/?start_date=${params.start_date}&end_date=${params.end_date}&subcategory_id=${params.subcategory_id}`,
            fileName: "Reporte de respuestas de evaluaciones",
            typeFile: "xlsx"
        },
        eveluationResponsesByCourse: {
            urlRequest: `${process.env.REACT_APP_URL_BASE}/assessment-report-by-course/?start_date=${params.start_date}&end_date=${params.end_date}&course_id=${params.course_id}`,
            fileName: "Reporte de respuestas de evaluaciones",
            typeFile: "xlsx"
        },
        purchasedCourses: {
            urlRequest: `${process.env.REACT_APP_URL_BASE}/purchased_courses_reports/?start_date=${params.start_date}&end_date=${params.end_date}`,
            fileName: "Reporte de cursos comprados",
            typeFile: "xlsx"
        },
        mentorReport: {
            urlRequest: `${process.env.REACT_APP_URL_BASE}/mentor-report/?start_date=${params.start_date}&end_date=${params.end_date}&mentor_id=${params.mentorId}`,
            fileName: "Reporte de mentor",
            typeFile: "xlsx"
        },
        assessmentPurchasedCourseReport: {
            urlRequest: `${process.env.REACT_APP_URL_BASE}/assessment-purchased-course-report/?user_id=${params.user_id}&course_id=${params.course_id}&type=${params.type}`,
            fileName: "Curso comprado",
            typeFile: "pdf"
        },
        reproducedCourseBySubcategory: {
            urlRequest: `${process.env.REACT_APP_URL_BASE}/reproduced_courses_report/?start_date=${params.start_date}&end_date=${params.end_date}&subcategory_id=${params.subcategory_id}`,
            fileName: "Reporte de cursos finalizados",
            typeFile: "xlsx"
        }
    }

    fetch(
        typeDocument[type].urlRequest,
        {
            method: "GET",
            headers: {
                "Content-Type": "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
                Authorization: `Bearer ${getToken()}`
            },
        }
    )
        .then((response) => {
            if (response.status >= 400 && response.status < 600) {
                return response.text().then(text => { throw new Error(text) })
            }
            return response.blob();
        })
        .then((blob) => {
            // Create blob link to download
            const url = window.URL.createObjectURL(new Blob([blob]));
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", `${typeDocument[type].fileName}.${typeDocument[type].typeFile}`);

            // Append to html link element page
            document.body.appendChild(link);

            // Start download
            link.click();

            // Clean up and remove the link
            link.parentNode.removeChild(link);

            if (callbackFuntcion) callbackFuntcion()
        })
        .catch(error => {
            toast.error(JSON.parse(error?.message)?.message ? JSON.parse(error?.message)?.message : "Ha ocurrido un error, vuelve a intentarlo", { autoClose: 3000, theme: "colored" })
        })
}