import React from 'react';
import Sidebar from '../../commons/components/Sidebar';
import errorImage from '../../commons/img/error-404.png';

const NotFound = () => {

    return (
        <div style={{ display: "flex" }}>
            <Sidebar />
            <div className='notFound'>
                <img src={errorImage} />
                <h3>Página no encontrada</h3>
            </div>
        </div>
    )
}

export default NotFound;