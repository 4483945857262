import React from 'react';
import { IconButton } from '@mui/material';
import Icon from '@mdi/react';
import { mdiDownload } from '@mdi/js';
import { useSelector } from 'react-redux';
import getObjectFromString from '../../functions/getObjectFromString';

const DownloadButton = ({ dataColumn, id, handleDownload }) => {
    const dataState = useSelector((state) => getObjectFromString(state, dataColumn?.state));
    const initialDataFiltered = Array.isArray(dataState) ? dataState?.find(element => element?.id === id) : ""//filter the data with the user required

    const download = () => {
        if (handleDownload) {
            handleDownload(id, dataColumn)
        }
    }

    return (
        <div>
            {dataColumn?.fieldValidation ?
                !!initialDataFiltered[dataColumn?.fieldValidation] && <IconButton onClick={download}>
                    <Icon path={mdiDownload} color="#444444" size="23px" />
                </IconButton>
                : <IconButton onClick={download}>
                    <Icon path={mdiDownload} color="#444444" size="23px" />
                </IconButton>
            }
        </div>
    )
}

export default DownloadButton;