import { createAction } from "redux-actions";
import api from "../../utils/api/apiClient";
import { toast } from "react-toastify";

export const fetchAssessmentsRequest = createAction('FETCH_ASSESSMENTS_REQUEST');
export const fetchAssessmentsSuccess = createAction('FETCH_ASSESSMENTS_SUCCESS');
export const fetchAssessmentSuccess = createAction('FETCH_ASSESSMENT_SUCCESS');
export const fetchAssessmentsError = createAction('FETCH_ASSESSMENTS_ERROR');

export const getAssessments = (params) => async (dispatch) => {
    try {
        dispatch(fetchAssessmentsRequest());
        const { data } = await api.assessmentsApi.getAssessments({ params });
        dispatch(fetchAssessmentsSuccess(data));
    } catch (error) {
        errorFunction(error, dispatch);
    }
};

export const updateAssessments = (body, paramsGetAssessments) => async (dispatch) => {
    try {
        dispatch(fetchAssessmentsRequest());
        await api.assessmentsApi.updateAssessment(body);
        toast.success('Encuesta actualizada de forma exitosa', { autoClose: 3000, theme: "colored" });
        dispatch(getAssessments(paramsGetAssessments))
    } catch (error) {
        errorFunction(error, dispatch);
    }
}

export const createAssessment = (body, callbackFunction, isCreate) => async (dispatch) => {
    try {
        dispatch(fetchAssessmentsRequest());
        await api.assessmentsApi.createAssessment(body);
        toast.success(`Evaluación ${isCreate ? 'creada' : 'actualizada'} de forma exitosa`, { autoClose: 3000, theme: "colored" });
        callbackFunction();
    } catch (error) {
        errorFunction(error, dispatch);
    }
}

export const deleteAssessment = (id, paramsGetAssessments) => async (dispatch) => {
    try {
        await api.assessmentsApi.deleteAssessment(id);
        toast.success('Evaluación eliminada de forma exitosa', { autoClose: 3000, theme: "colored" });
        dispatch(getAssessments(paramsGetAssessments, true))
    } catch (error) {
        errorFunction(error, dispatch);
    }
}

export const getAssessment = (id) => async (dispatch) => {
    try {
        dispatch(fetchAssessmentsRequest());
        const { data } = await api.assessmentsApi.getAssessment(id);
        dispatch(fetchAssessmentSuccess(data));
    } catch (error) {
        errorFunction(error, dispatch);
    }
};

export const deleteQuestionsAssessment = (body, callbackFunction) => async (dispatch) => {
    try {
        dispatch(fetchAssessmentsRequest());
        await api.assessmentsApi.deleteQuestionsAssessment(body);
        callbackFunction();
    } catch (error) {
        errorFunction(error, dispatch);
    }
}

const errorFunction = (error, dispatch) => {
    const errorMessage = error?.response?.data?.message ? error.response.data.message : error.message
    dispatch(fetchAssessmentsError(errorMessage));
    toast.error(errorMessage, { autoClose: 3000, theme: "colored" });
}