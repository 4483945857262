import React, { useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import Icon from '@mdi/react';
import { mdiPlusCircleOutline } from '@mdi/js';
import { rowsPerPageOptions } from "../../commons/constants/pagination";
import Table from '../../commons/components/Table';
import { Spinner } from 'reactstrap';
import ActionsPanel from "../../commons/components/tableComponents/ActionsPanel";
import { useNavigate, useSearchParams } from 'react-router-dom';

import { getAssessments, updateAssessments, deleteAssessment } from "../../redux/actions/assessmentsAction";

const columnsTable = [
    {
        value: 'id',
        title: 'No',
        isCustom: false
    },
    {
        value: 'category',
        title: 'Categoría',
        isCustom: false
    },
    {
        value: 'type',
        title: 'Tipo',
        isCustom: false
    },
    {
        value: 'is_active',
        title: 'Acciones',
        isCustom: true,
        notIsSortable: true,
        CustomElement: ActionsPanel,
        editionRedirect: true,
        editionRedirectURL: '/evaluaciones/editarEvaluacion',
        state: 'assessments.assessments',
        deleteOption: true,
        deleteDispatchAction: deleteAssessment
    }
]

const handleData = (data) => (
    data.map(({ id, category, type, is_active }) => ({
        id,
        category: category?.name || '',
        type: type === "pre_test" ? "Pre-prueba" : type === "post_test" ? "Post-prueba" : "",
        is_active
    }))
)

const Assessments = () => {
    const dispatch = useDispatch();
    const assessmentsList = useSelector((state) => state.assessments.assessments);
    const assessmentsIsLoading = useSelector((state) => state.assessments.isFetching);
    const refTable = useRef(null);
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const pageParam = searchParams.get('page');
    const rowsPerPageParam = searchParams.get('rowsPerPage');

    useEffect(() => {
        assessmentRequest(
            pageParam ? (parseInt(pageParam) + 1) : 1,
            rowsPerPageParam ? rowsPerPageParam : rowsPerPageOptions[0]
        )
    }, [])

    const assessmentRequest = (page, page_size) => {
        dispatch(getAssessments(
            {
                page,
                page_size,
                category_id__isnull: false,
                course_id__isnull: true,
            }
        ))
    }

    const handleUpdateAssessment = ({ is_active }, { id, type, category }) => {
        dispatch(updateAssessments({
            id,
            type,
            category_id: category.id,
            is_active,
        }, {
            page: refTable.current.page + 1,
            page_size: refTable.current.rowsPerPage
        }))
    }

    return (
        <div className='assessmentsContainer'>
            <div className='headerContainer'>
                <div className='leftHeaderContainer'>
                    <h4>Evaluaciones por categoría</h4>
                    <button className='addItemButton' onClick={() => navigate(`/evaluaciones/crearEvaluacion`)}>
                        <Icon path={mdiPlusCircleOutline} color="white" size="24px" style={{ marginRight: "12px" }} />
                        Añadir nueva Prueba
                    </button>
                </div>
            </div>
            {
                assessmentsList && !assessmentsIsLoading
                    ? <Table
                        columns={columnsTable}
                        data={handleData(assessmentsList.data)}
                        changePage={(newPage, pageSize) => assessmentRequest(newPage + 1, pageSize)}
                        changeRowsPerPage={(pageSize) => assessmentRequest(1, pageSize)}
                        count={assessmentsList.count}
                        ref={refTable}
                        updateData={handleUpdateAssessment}
                    />
                    : <Spinner style={{ marginLeft: "45%", marginTop: "46px" }} />
            }
        </div>
    )
}

export default Assessments;