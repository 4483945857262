import { get, post } from '../base/index';

export default {
    login: (body) => {
        return post(`${process.env.REACT_APP_URL_BASE}/token/`, body)
    },
    getUserByToken: () => {
        return get(`${process.env.REACT_APP_URL_BASE}/users/token`)
    },
    passwordResetConfirm: (body) => {
        return post(`${process.env.REACT_APP_URL_BASE}/password_reset/confirm/`, body)
    }
}