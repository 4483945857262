import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Spinner } from 'reactstrap';

import { getMentors, createMentor, deleteMentor, updateMentor } from "../../redux/actions/usersActions";
import Table from '../../commons/components/Table';
import { rowsPerPageOptions } from "../../commons/constants/pagination";
import ActionsPanelComplete from "../../commons/components/tableComponents/ActionsPanelComplete";
import CreateMentorModal from "./Components/CreateMentorModal";
import DownloadMentorReportModal from "./Components/DownloadMentorReportModal";
import downloadDocument from "../../commons/functions/downloadDocument";
import moment from 'moment';

const columnsTable = [
    {
        value: 'id',
        title: 'No',
        isCustom: false
    },
    {
        value: 'name',
        title: 'Mentor',
        isCustom: false
    },
    {
        value: 'num_courses',
        title: 'Cursos',
        isCustom: false
    },
    {
        value: 'is_active',
        title: 'Acciones',
        isCustom: true,
        notIsSortable: true,
        CustomElement: ActionsPanelComplete,
        editModalElement: CreateMentorModal,
        state: 'users.mentors',
        updateDispatchAction: updateMentor,
        deleteDispatchAction: deleteMentor
    }
]

const handleData = (data) => (
    data.map(({ id, name, is_active, num_courses }) => ({
        id,
        name,
        num_courses,
        is_active
    }))
)

const Mentors = () => {
    const dispatch = useDispatch();
    const mentorsLists = useSelector((state) => state.users.mentors);
    const [isOpenModal, setIsOpenModal] = useState(false);
    const [isOpenDownloadReportModal, setIsOpenDownloadReportModal] = useState(false);
    const [isDownloadingReport, setIsDownloadingReport] = useState(false);
    const refTable = useRef(null);
    const refDownloadReportModal = useRef(null);

    useEffect(() => {
        mentorsRequest(1, rowsPerPageOptions[0])
    }, [])

    const mentorsRequest = (page, page_size) => {
        dispatch(getMentors({
            page, page_size
        }))
    }

    const handleCreateMentor = (body) => {
        dispatch(createMentor(
            body,
            {
                page: 1,
                page_size: refTable.current?.rowsPerPage
            },
            refTable.current.resetPagination //Reset pagination on data reload in the page 1
        ));
    }

    const onOpenDownloadReportModal = (id) => {
        setIsOpenDownloadReportModal(true)
        refDownloadReportModal.current.setMentorIdSelected(id)
    }

    const onHandleDownload = (initialDate, endDate, closeDownloadReportModal) => {
        setIsDownloadingReport(true)
        downloadDocument(
            "mentorReport",
            {
                start_date: moment(initialDate).set({ hour: 0, minute: 0, second: 0 }).toISOString(),
                end_date: moment(endDate).set({ hour: 23, minute: 59, second: 59 }).toISOString(),
                mentorId: refDownloadReportModal.current.mentorIdSelected
            },
            () => {
                setIsDownloadingReport(false)
                closeDownloadReportModal()
            }
        )
    }

    return (
        <div className='usersContainer'>
            <div className='title'>
                <h4>Conferenciante</h4>
                <button className='addItemButton' onClick={() => setIsOpenModal(true)}>
                    Agregar Mentor
                </button>
            </div>
            {
                mentorsLists
                    ? <Table
                        columns={columnsTable}
                        data={handleData(mentorsLists.data)}
                        changePage={(newPage, pageSize) => mentorsRequest(newPage + 1, pageSize)}
                        changeRowsPerPage={(pageSize) => mentorsRequest(1, pageSize)}
                        count={mentorsLists.count}
                        ref={refTable}
                        handleDownload={onOpenDownloadReportModal}
                    />
                    : <Spinner style={{ marginLeft: "45%", marginTop: "46px" }} />
            }
            <CreateMentorModal
                isOpenModal={isOpenModal}
                closeModal={() => setIsOpenModal(false)}
                createMentor={handleCreateMentor}
                createMode={true}
                initialData={null}
            />
            <DownloadMentorReportModal
                isOpenModal={isOpenDownloadReportModal}
                closeModal={() => setIsOpenDownloadReportModal(false)}
                onDownload={onHandleDownload}
                isLoading={isDownloadingReport}
                ref={refDownloadReportModal}
            />
        </div>
    )
}

export default Mentors;