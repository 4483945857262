import React, { useState, useEffect } from 'react';
import { Modal, Input, ModalBody, Button } from 'reactstrap';
import DropZone from '../../../commons/components/DropZone';
import { IconButton, Switch } from '@mui/material';
import Icon from '@mdi/react';
import { mdiClose } from '@mdi/js';

import { updateCategory } from "../../../redux/actions/categoriesActions";
import { useDispatch, useSelector } from 'react-redux';

const CreateCategoryModal = ({ isOpenModal, closeModal, createCategory, createMode, initialData, page, rowsPerPage }) => {
    const dispatch = useDispatch();
    const modalStatus = useSelector((state) => state.categories.modalActionStatus);
    const [nameCategory, setNameCategory] = useState("");
    const [symbolColor, setSymbolColor] = useState("#000000");
    const [icon, setIcon] = useState([]);
    const [certTemplate, setCertTemplate] = useState([]);
    const [submittedForm, setSubmittedForm] = useState(false);
    const [enableSubscription, setEnableSubscription] = useState(true);

    useEffect(() => {
        if (!createMode) {
            setNameCategory(initialData?.name)
            setSymbolColor(initialData?.color)
            setIcon([{
                name: initialData?.icon_url?.slice(initialData?.icon_url.lastIndexOf('/') + 1)
            }])
            setCertTemplate([{
                name: initialData?.certificate_url?.slice(initialData?.certificate_url.lastIndexOf('/') + 1)
            }])
            setEnableSubscription(initialData?.enable_subscription)
        }
    }, [isOpenModal])

    useEffect(() => {
        if (modalStatus === "COMPLETED")
            handleClose();
    }, [modalStatus])

    const handleCreateCategory = () => {
        setSubmittedForm(true);
        if (!handleError(nameCategory, symbolColor) && !handleRegexValidation() && icon.length > 0 && certTemplate.length > 0) {
            let bodyFormData = new FormData();
            bodyFormData.append("name", nameCategory);
            bodyFormData.append("color", symbolColor);
            bodyFormData.append("icon_url", icon[0]);
            bodyFormData.append("certificate_url", certTemplate[0]);
            bodyFormData.append("is_active", 'True');
            bodyFormData.append("enable_subscription", enableSubscription);

            createCategory(bodyFormData);
        }
    }

    const handleUpdateCategory = () => {
        setSubmittedForm(true);
        if (!handleError(nameCategory, symbolColor) && !handleRegexValidation()) {
            if (
                icon[0]?.name === initialData?.icon_url.slice(initialData?.icon_url.lastIndexOf('/') + 1) &&
                certTemplate[0]?.name === initialData?.certificate_url.slice(initialData?.certificate_url.lastIndexOf('/') + 1)
            ) { //If the icon and the certificate template are the same of initialData
                const body = {
                    id: initialData.id,
                    name: nameCategory,
                    color: symbolColor,
                    icon_url: initialData.icon_url,
                    certificate_url: initialData.certificate_url,
                    is_active: initialData.is_active,
                    enable_subscription: enableSubscription,
                }

                dispatch(updateCategory(
                    body,
                    {
                        page: page + 1,
                        page_size: rowsPerPage
                    },
                    1 //Type normal PUT petition
                ))
            }
            else {
                let bodyFormData = new FormData();
                bodyFormData.append("id", initialData.id);
                bodyFormData.append("name", nameCategory);
                bodyFormData.append("color", symbolColor);
                bodyFormData.append("icon_url", icon[0]?.name === initialData?.icon_url.slice(initialData?.icon_url.lastIndexOf('/') + 1) ? initialData.icon_url : icon[0]);
                bodyFormData.append("certificate_url", certTemplate[0]?.name === initialData?.certificate_url.slice(initialData?.certificate_url.lastIndexOf('/') + 1) ? initialData.certificate_url : certTemplate[0]);
                bodyFormData.append("is_active", initialData.is_active);
                bodyFormData.append("enable_subscription", enableSubscription);

                dispatch(updateCategory(
                    bodyFormData,
                    {
                        page: page + 1,
                        page_size: rowsPerPage
                    },
                    2 //Type formData PUT petition
                ))
            }
        }
    }

    const handleChangeInput = (e, setInput) => {
        setInput(e.target.value)
    }

    const handleError = (field1, field2) => (
        field1 === '' || field2 === '' ? true : false
    )

    const handleRegexValidation = () => (
        !/^#([0-9a-f]{3}){1,2}$/i.test(symbolColor)
    )

    const handleClose = () => {
        setNameCategory("");
        setSymbolColor("#000000");
        setIcon([]);
        setCertTemplate([]);
        setSubmittedForm(false);
        closeModal();
    }

    const disableButtonValidation = () => {
        if (createMode) {
            return modalStatus === "LOADING"
        }
        else {
            return (
                initialData?.name === nameCategory &&
                initialData?.color === symbolColor &&
                icon[0]?.name === initialData?.icon_url?.slice(initialData?.icon_url.lastIndexOf('/') + 1) &&
                certTemplate[0]?.name === initialData?.certificate_url?.slice(initialData?.certificate_url.lastIndexOf('/') + 1) &&
                initialData?.enable_subscription === enableSubscription
            )
        }
    }

    return (
        <Modal isOpen={isOpenModal} centered className='createCategoryModal'>
            <div className='containButton'>
                <IconButton onClick={handleClose} className="closeButton">
                    <Icon path={mdiClose} color="#131F2A" size="23px" />
                </IconButton>
            </div>
            <ModalBody>
                <form>
                    <Input
                        placeholder='Nombre de la categoría'
                        onChange={(e) => handleChangeInput(e, setNameCategory)}
                        invalid={handleError(nameCategory) && submittedForm}
                        value={nameCategory}
                    />
                    <div style={{ display: "flex", alignItems: "center", marginBottom: 18 }}>
                        <h6 style={{
                            fontSize: "1rem",
                            fontWeight: 400
                        }}>
                            Color del símbolo:
                        </h6>
                        <input
                            type="color"
                            value={symbolColor}
                            onChange={e => setSymbolColor(e.target.value)}
                            style={{
                                marginBottom: 0,
                                marginLeft: 15
                            }}
                        />
                    </div>
                    {handleRegexValidation() && symbolColor !== "" && submittedForm &&
                        <h6 className='inputError'>* Debes ingresar un color en formato Hexadecimal</h6>
                    }
                    <DropZone
                        onChange={setIcon}
                        pictures={icon}
                        multiple={false}
                        typeFiles={["image/svg+xml"]}
                        title={"Subir Icono"}
                        invalid={icon.length === 0 && submittedForm}
                    />
                    <div style={{ marginTop: "18px" }} />
                    <DropZone
                        onChange={setCertTemplate}
                        pictures={certTemplate}
                        multiple={false}
                        typeFiles={["image/jpeg", "image/png"]}
                        title={"Plantilla del certificado"}
                        invalid={certTemplate.length === 0 && submittedForm}
                    />
                    <div style={{ marginTop: 10, display: "flex", alignItems: "center" }}>
                        <h6>¿Activar suscripción?</h6>
                        <Switch
                            size="medium"
                            name="assessmentType"
                            value={enableSubscription}
                            onClick={() => setEnableSubscription(!enableSubscription)}
                            className="toggleSwitch"
                            checked={enableSubscription}
                        />
                    </div>
                    <div className='buttons'>
                        <Button className='secondarybutton' onClick={handleClose}>Cancelar</Button>
                        <Button className='principalbutton' onClick={createMode ? handleCreateCategory : handleUpdateCategory} disabled={disableButtonValidation()}>Guardar</Button>
                    </div>
                </form>
            </ModalBody>
        </Modal>
    )
}

export default CreateCategoryModal;