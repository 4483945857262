import React, { useState, useEffect } from 'react';
import { Modal, Input, ModalBody, Button, ModalHeader } from 'reactstrap';
import DropZone from '../../../commons/components/DropZone';
import { IconButton } from '@mui/material';
import Icon from '@mdi/react';
import { mdiClose } from '@mdi/js';

import { useDispatch, useSelector } from 'react-redux';
import { updateMentor } from "../../../redux/actions/usersActions";

const CreateMentorModal = ({ isOpenModal, closeModal, createMentor, createMode, initialData, page, rowsPerPage }) => {
    const dispatch = useDispatch();
    const modalStatus = useSelector((state) => state.users.modalActionStatus);
    const [name, setName] = useState("");
    const [bibliography, setBibliography] = useState("");
    const [photo, setPhoto] = useState([]);
    const [submittedForm, setSubmittedForm] = useState(false);

    useEffect(() => {
        if (!createMode) {
            setName(initialData?.name)
            setBibliography(initialData?.bibliography)
            setPhoto([{
                name: initialData?.photo_url?.slice(initialData?.photo_url.lastIndexOf('/') + 1)
            }])
        }
    }, [isOpenModal])

    useEffect(() => {
        if (modalStatus === "COMPLETED")
            handleClose();
    }, [modalStatus])

    const handleCreateMentor = () => {
        setSubmittedForm(true);
        if (!handleError(name, bibliography) && photo.length > 0) {
            let bodyFormData = new FormData();
            bodyFormData.append("name", name);
            bodyFormData.append("bibliography", bibliography);
            bodyFormData.append("photo_url", photo[0]);
            bodyFormData.append("is_active", 'True');

            createMentor(bodyFormData);
        }
    }

    const handleUpdateMentor = () => {
        setSubmittedForm(true);
        if (!handleError(name, bibliography) && photo.length > 0) {
            if (photo[0]?.name === initialData?.photo_url.slice(initialData?.photo_url.lastIndexOf('/') + 1)) { //if the photo is the same of initialData
                const body = {
                    id: initialData.id,
                    name,
                    bibliography,
                    photo: initialData.photo_url,
                    is_active: initialData.is_active
                }

                dispatch(updateMentor(
                    body,
                    {
                        page: page + 1,
                        page_size: rowsPerPage
                    },
                    1 //Type normal PUT petition
                ))
            }
            else {
                let bodyFormData = new FormData();
                bodyFormData.append("id", initialData.id);
                bodyFormData.append("name", name);
                bodyFormData.append("bibliography", bibliography);
                bodyFormData.append("photo_url", photo[0]);
                bodyFormData.append("is_active", initialData.is_active);

                dispatch(updateMentor(
                    bodyFormData,
                    {
                        page: page + 1,
                        page_size: rowsPerPage
                    },
                    2 //Type formData PUT petition
                ))
            }
        }
    }

    const handleChangeInput = (e, setInput) => {
        setInput(e.target.value)
    }

    const handleError = (field1, field2) => (
        field1 === '' || field2 === '' ? true : false
    )

    const handleClose = () => {
        setName("");
        setBibliography("");
        setPhoto([]);
        setSubmittedForm(false);
        closeModal();
    }

    const disableButtonValidation = () => {
        if (createMode) {
            return modalStatus === "LOADING"
        }
        else {
            return (initialData?.name === name && initialData?.bibliography === bibliography && photo[0]?.name === initialData?.photo_url.slice(initialData?.photo_url.lastIndexOf('/') + 1))
        }
    }

    return (
        <Modal isOpen={isOpenModal} centered className='createCategoryModal'>
            <div className='containButton' style={{ position: "absolute", right: 0 }}>
                <IconButton onClick={handleClose} className="closeButton">
                    <Icon path={mdiClose} color="#131F2A" size="23px" />
                </IconButton>
            </div>
            <ModalHeader style={{ padding: "20px 45px" }}>
                Subir Conferenciante
            </ModalHeader>
            <ModalBody style={{ marginTop: 30 }}>
                <form>
                    <Input
                        placeholder='Nombre'
                        onChange={(e) => handleChangeInput(e, setName)}
                        invalid={handleError(name) && submittedForm}
                        value={name}
                    />
                    <Input
                        placeholder='Biografía'
                        onChange={(e) => handleChangeInput(e, setBibliography)}
                        invalid={handleError(bibliography) && submittedForm}
                        value={bibliography}
                        type="textarea"
                    />
                    <DropZone
                        onChange={setPhoto}
                        pictures={photo}
                        multiple={false}
                        typeFiles={["image/jpeg", "image/png"]}
                        title={"Subir foto"}
                        invalid={photo.length === 0 && submittedForm}
                    />
                    <div className='buttons'>
                        <Button className='secondarybutton' onClick={handleClose}>Cancelar</Button>
                        <Button className='principalbutton' onClick={createMode ? handleCreateMentor : handleUpdateMentor} disabled={disableButtonValidation()}>Guardar</Button>
                    </div>
                </form>
            </ModalBody>
        </Modal>
    )
}

export default CreateMentorModal;