import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import Table from '../../commons/components/Table';
import { Spinner } from 'reactstrap';
import TwoRowsText from "../../commons/components/tableComponents/TwoRowsText";
import CreateNotificationModal from "./components/CreateNotificationModal"

import { getNotifications } from "../../redux/actions/commonsActions";
import { rowsPerPageOptions } from "../../commons/constants/pagination";

const columnsTable = [
    {
        value: 'user',
        title: 'Administrador',
        isCustom: false
    },
    {
        value: 'title',
        title: 'Título',
        isCustom: false
    },
    {
        value: 'description',
        title: 'Descripción',
        isCustom: true,
        CustomElement: TwoRowsText
    }
]

const handleData = (data) => (
    data.map(({ title, description, user }) => ({
        user: `${user.name} ${user.last_name}`,
        title,
        description,
    }))
)

const Notifications = () => {
    const dispatch = useDispatch();
    const [isOpenModal, setIsOpenModal] = useState(false);
    const notificationsList = useSelector((state) => state.commons.notifications);
    const refTable = useRef(null);

    useEffect(() => {
        notificationsRequest(1, rowsPerPageOptions[0])
    }, []);

    const notificationsRequest = (page, page_size) => {
        dispatch(getNotifications({
            page,
            page_size
        }))
    }

    return (
        <div className='coursesContainer'>
            <div className='headerContainer'>
                <div className='leftHeaderContainer'>
                    <h4>Notificaciones</h4>
                </div>
                <button className='addItemButton' onClick={() => setIsOpenModal(true)}>
                    Crear nueva notificación
                </button>
            </div>
            {
                notificationsList
                    ? <Table
                        columns={columnsTable}
                        data={handleData(notificationsList.data)}
                        changePage={(newPage, pageSize) => notificationsRequest(newPage + 1, pageSize)}
                        changeRowsPerPage={(pageSize) => notificationsRequest(1, pageSize)}
                        count={notificationsList.count}
                        ref={refTable}
                    />
                    : <Spinner style={{ marginLeft: "45%", marginTop: "46px" }} />
            }
            <CreateNotificationModal
                isOpen={isOpenModal}
                closeModal={() => setIsOpenModal(false)}
                paramsGetNotifications={{
                    page: refTable.current?.page + 1,
                    page_size: refTable.current?.rowsPerPage,
                }}
            />
        </div>
    )
}

export default Notifications;