import { createAction } from "redux-actions";
import api from "../../utils/api/apiClient";
import { toast } from "react-toastify";

export const fetchDiscountCouponsRequest = createAction(
  "FETCH_DISCOUNT_REQUEST"
);
export const fetchDiscountCouponsSuccess = createAction(
  "FETCH_DISCOUNT_SUCCESS"
);
export const fetchDiscountRatesSuccess = createAction(
  "FETCH_DISCOUNT_RATE_SUCCESS"
);
export const fetchModalDiscountCouponRequest = createAction(
  "FETCH_MODAL_DISCOUNT_REQUEST"
);
export const fetchModalDiscountCouponSuccess = createAction(
  "FETCH_MODAL_DISCOUNT_SUCCESS"
);
export const fetchDiscountCouponsError = createAction("FETCH_DISCOUNT_ERROR");

export const getDiscountCoupons = (params) => async (dispatch) => {
  try {
    dispatch(fetchDiscountCouponsRequest());
    const { data } = await api.discounts.getDiscountCoupons({ params });
    dispatch(fetchDiscountCouponsSuccess(data));
  } catch (error) {
    errorFunction(error, dispatch);
  }
};

export const createDiscountCoupon =
  (body, paramsGetDiscountCoupons, resetPagination) => async (dispatch) => {
    try {
      dispatch(fetchModalDiscountCouponRequest());
      await api.discounts.createDiscountCoupon(body);
      dispatch(fetchModalDiscountCouponSuccess());
      toast.success("Cupón de descuento creado de forma exitosa", {
        autoClose: 3000,
        theme: "colored",
      });
      await dispatch(getDiscountCoupons(paramsGetDiscountCoupons));
      resetPagination();
    } catch (error) {
      errorFunction(error, dispatch);
    }
  };

export const deleteDiscountCoupon =
  (id, paramsGetDiscountCoupons) => async (dispatch) => {
    try {
      await api.discounts.deleteDiscountCoupon(id);
      toast.success("Cupón de descuento eliminado de forma exitosa", {
        autoClose: 3000,
        theme: "colored",
      });
      dispatch(getDiscountCoupons(paramsGetDiscountCoupons));
    } catch (error) {
      errorFunction(error, dispatch);
    }
  };

export const updateDiscountCoupon =
  (body, paramsGetDiscountCoupons, typePutPetition) => async (dispatch) => {
    try {
      dispatch(fetchModalDiscountCouponRequest());
      await api.discounts.updateDiscountCoupon(body, typePutPetition);
      dispatch(fetchModalDiscountCouponSuccess());
      toast.success("Cupón de descuento actualizado de forma exitosa", {
        autoClose: 3000,
        theme: "colored",
      });
      dispatch(getDiscountCoupons(paramsGetDiscountCoupons));
    } catch (error) {
      errorFunction(error, dispatch);
    }
  };

export const getDiscountRates = (params) => async (dispatch) => {
  try {
    dispatch(fetchDiscountCouponsRequest());
    const { data } = await api.discounts.getDiscountRates({ params });
    dispatch(fetchDiscountRatesSuccess(data));
  } catch (error) {
    errorFunction(error, dispatch);
  }
};

export const createDiscountRate =
  (body, paramsGetDiscountRates, resetPagination) => async (dispatch) => {
    try {
      dispatch(fetchModalDiscountCouponRequest());
      await api.discounts.createDiscountRate(body);
      dispatch(fetchModalDiscountCouponSuccess());
      toast.success("Descuento creado de forma exitosa", {
        autoClose: 3000,
        theme: "colored",
      });
      await dispatch(getDiscountRates(paramsGetDiscountRates));
      resetPagination();
    } catch (error) {
      errorFunction(error, dispatch);
    }
  };

export const deleteDiscountRate =
  (id, paramsGetDiscountRates) => async (dispatch) => {
    try {
      await api.discounts.deleteDiscountRate(id);
      toast.success("Descuento eliminado de forma exitosa", {
        autoClose: 3000,
        theme: "colored",
      });
      dispatch(getDiscountRates(paramsGetDiscountRates));
    } catch (error) {
      errorFunction(error, dispatch);
    }
  };

export const updateDiscountRate =
  (body, paramsGetDiscountRates, typePutPetition) => async (dispatch) => {
    try {
      dispatch(fetchModalDiscountCouponRequest());
      await api.discounts.updateDiscountRate(body, typePutPetition);
      dispatch(fetchModalDiscountCouponSuccess());
      toast.success("Descuento actualizado de forma exitosa", {
        autoClose: 3000,
        theme: "colored",
      });
      dispatch(getDiscountRates(paramsGetDiscountRates));
    } catch (error) {
      errorFunction(error, dispatch);
    }
  };

const errorFunction = (error, dispatch) => {
  const errorMessage = error?.response?.data?.message
    ? error.response.data.message
    : error.message;
  dispatch(fetchDiscountCouponsError(errorMessage));
  toast.error(errorMessage, { autoClose: 3000, theme: "colored" });
};
