import React, { useState } from 'react'
import { Button, Input } from 'reactstrap';
import { useSelector, useDispatch } from 'react-redux';
import Icon from '@mdi/react';
import { mdiCheckCircle } from '@mdi/js';
import mainLogo from '../../commons/img/logo.svg';
import { useNavigate, useParams } from 'react-router-dom';
import { passwordReset } from '../../redux/actions/authActions';

const PasswordReset = () => {
    const isFetching = useSelector((state) => state.auth.isFetching);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { token } = useParams();

    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [submittedForm, setSubmittedForm] = useState(false);
    const [confirmRequest, setConfirmRequest] = useState(false);

    const handleChangePassword = (e) => {
        e.preventDefault();
        setSubmittedForm(true);
        if (!handleError(password, confirmPassword) && confirmPassword === password) {
            dispatch(passwordReset({
                password: password,
                token
            }, () => setConfirmRequest(true)))
        }
    }

    const handleError = (field1, field2) => (
        field1 === '' || field2 === '' ? true : false
    )

    return (
        <div className='passwordResetContainer'>
            <img src={mainLogo} height="100px" width="500px" />
            {
                !confirmRequest ?
                    <div className='loginForm'>
                        <form onSubmit={(e) => handleChangePassword(e)}>
                            <Input type='password' placeholder='Contraseña nueva' invalid={handleError(password) && submittedForm} onChange={(e) => setPassword(e.target.value)} />
                            <Input type='password' placeholder='Confirmar contraseña nueva' invalid={handleError(confirmPassword) && submittedForm} onChange={(e) => setConfirmPassword(e.target.value)} />
                            <Button color='primary' type='submit' disabled={isFetching}>Restablecer contraseña</Button>
                        </form>
                        {handleError(password, confirmPassword) && submittedForm && <h6 className='errorWarning'>*Campos obligatorios</h6>}
                        {confirmPassword !== "" && password !== "" && confirmPassword !== password && submittedForm && <h6 className='errorWarning'>*Las contraseñas no coinciden</h6>}
                    </div>
                    : <div style={{ marginTop: 50, display: "flex", flexDirection: "column", alignItems: "center" }}>
                        <Icon path={mdiCheckCircle} color="#05FF00" size="62px" />
                        <h6 style={{ fontSize: 18, fontWeight: 400, marginTop: 20 }}>Contraseña actualizada con éxito</h6>
                        <Button color='primary' style={{ marginTop: 30 }} onClick={() => navigate("/")}>Ingresar a mi cuenta</Button>
                    </div>
            }
        </div>
    )
}

export default PasswordReset;