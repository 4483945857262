import { createAction } from "redux-actions";
import api from "../../utils/api/apiClient";
import { toast } from "react-toastify";

export const fetchSubscriptionsRequest = createAction('FETCH_SUBSCRIPTIONS_REQUEST');
export const fetchSubscriptionsSuccess = createAction('FETCH_SUBSCRIPTIONS_SUCCESS');
export const fetchSubscriptionsError = createAction('FETCH_SUBSCRIPTIONS_ERROR');

export const getSubscriptions = (params) => async (dispatch) => {
    try {
        dispatch(fetchSubscriptionsRequest());
        const { data } = await api.subscription.getSubscriptions({ params });
        dispatch(fetchSubscriptionsSuccess(data));
    } catch (error) {
        errorFunction(error, dispatch);
    }
};

export const createSubscription = (body, paramsGetSubscriptions, handleClose) => async (dispatch) => {
    try {
        dispatch(fetchSubscriptionsRequest());
        await api.subscription.createSubscription(body);
        toast.success('Suscripción creada de forma exitosa', { autoClose: 3000, theme: "colored" });
        await dispatch(getSubscriptions(paramsGetSubscriptions));
        handleClose();
    } catch (error) {
        errorFunction(error, dispatch);
    }
}

export const deleteSubscription = (id, paramsGetSubscriptions) => async (dispatch) => {
    try {
        await api.subscription.deleteSubscription(id);
        toast.success('Suscripción eliminada de forma exitosa', { autoClose: 3000, theme: "colored" });
        dispatch(getSubscriptions(paramsGetSubscriptions))
    } catch (error) {
        errorFunction(error, dispatch);
    }
}

export const updateSubscription = (body, paramsGetSubscriptions, handleClose) => async (dispatch) => {
    try {
        dispatch(fetchSubscriptionsRequest());
        await api.subscription.updateSubscription(body);
        toast.success('Suscripción actualizada de forma exitosa', { autoClose: 3000, theme: "colored" });
        await dispatch(getSubscriptions(paramsGetSubscriptions));
        handleClose();
    } catch (error) {
        errorFunction(error, dispatch);
    }
}

const errorFunction = (error, dispatch) => {
    const errorMessage = error?.response?.data?.message ? error.response.data.message : error.message
    dispatch(fetchSubscriptionsError(errorMessage));
    toast.error(errorMessage, { autoClose: 3000, theme: "colored" });
}