import axios, { defaultParams } from './axios';

export default async (url, params, body) => {
  try {
    return await axios.delete(url, { ...defaultParams(), ...params, data: body });
  } catch (e) {
    if (!e || !e.response || e.response.status !== 401) throw e;
    if (e.response.status === 401) {
      localStorage.removeItem("token")
      window.location.replace("/")
    }
    localStorage.setItem('url', `${window.location.pathname}${window.location.search}`);
    try {
      return axios.delete(url, { ...defaultParams(), ...params, data: body });
    } catch (err) {
      return null;
    }
  }
};
