import { get, put, post, deleteMethod } from '../base/index';

export default {
    getAssessments: (params) => {
        return get(`${process.env.REACT_APP_URL_BASE}/assessments/`, params)
    },
    updateAssessment: (body) => {
        return put(`${process.env.REACT_APP_URL_BASE}/assessments/`, body)
    },
    createAssessment: (body) => {
        return post(`${process.env.REACT_APP_URL_BASE}/assessments/`, body)
    },
    deleteAssessment: (id) => {
        return deleteMethod(`${process.env.REACT_APP_URL_BASE}/assessments/${id}/`)
    },
    getAssessment: (id) => {
        return get(`${process.env.REACT_APP_URL_BASE}/assessment-questions/?assessment_id=${id}&data_all=YES`)
    },
    deleteQuestionsAssessment: (body) => {
        return deleteMethod(`${process.env.REACT_APP_URL_BASE}/assessment-questions/`, {}, body)
    },
    downloadAssessmentReportBySubcategory: (params) => {
        return get(`${process.env.REACT_APP_URL_BASE}/assessment-report-by-subcategory/?start_date=${params.start_date}&end_date=${params.end_date}&subcategory_id=${params.subcategory_id}`)
    }
}