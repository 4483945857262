import React, { useEffect, useState, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { Spinner, Input } from 'reactstrap';
import ToggleSwitch from "../../commons/components/tableComponents/ToggleSwitch";
import Table from '../../commons/components/Table';

import { getCategories } from "../../redux/actions/categoriesActions";
import { getCourses, updateCourse, deleteCourse } from "../../redux/actions/coursesActions";
import { rowsPerPageOptions } from "../../commons/constants/pagination";
import getUniqueValuesFromArray from '../../commons/functions/getUniqueValuesFromArray';

const columnsTable = [
    {
        value: 'title',
        title: 'Nombre del curso',
        isCustom: false
    },
    {
        value: 'code',
        title: 'Cod',
        isCustom: false
    },
    {
        value: 'category',
        title: 'Categoría',
        isCustom: false
    },
    {
        value: 'subcategory',
        title: 'Subcategoría',
        isCustom: false
    },
    {
        value: 'course_type',
        title: 'Tipo de Curso',
        isCustom: false
    },
    {
        value: 'has_pre_test',
        title: 'Pre-prueba',
        isCustom: true,
        CustomElement: ToggleSwitch,
        state: 'courses.courses',
    },
    {
        value: 'has_post_test',
        title: 'Post-prueba',
        isCustom: true,
        CustomElement: ToggleSwitch,
        state: 'courses.courses',
    }
]

const handleData = (data) => (
    data.map(({ id, title, code, subcategory, course_type, has_pre_test, has_post_test, is_deleted }) => ({
        title,
        code,
        category: subcategory?.category?.name,
        subcategory: subcategory?.name,
        course_type: course_type?.name,
        has_pre_test,
        has_post_test,
        id
    }))
)

const Courses = () => {
    const dispatch = useDispatch();
    const coursesList = useSelector((state) => state.courses.courses);
    const categoriesList = useSelector((state) => state.categories.categories?.data);

    const [categorySelected, setCategorySelected] = useState(null);
    const refTable = useRef(null);

    useEffect(() => {
        dispatch(getCategories({
            data_all: "YES"
        }))
    }, [])

    useEffect(() => {
        if (categoriesList) setCategorySelected(categoriesList[0]?.id)
    }, [categoriesList])

    useEffect(() => {
        if (categorySelected !== null) coursesRequest(1, rowsPerPageOptions[0])
    }, [categorySelected])

    const coursesRequest = (page, page_size) => {
        dispatch(getCourses(
            {
                page,
                page_size,
                subcategory__category_id: categorySelected
            },
            false
        ))
    }

    const handleUpdateCourse = (newValues, { id, code, title, description, course_material_url, course_audio_url, estimated_duration, course_cover_url, course_price, is_free, is_free_public_user, is_active, is_featured, has_pre_test, has_post_test, course_type, subcategory, mentor }) => {
        const body = {
            id,
            code,
            title,
            description,
            course_material_url,
            course_audio_url,
            estimated_duration,
            course_cover_url,
            course_price,
            is_free,
            is_free_public_user,
            is_active,
            is_featured,
            has_pre_test,
            has_post_test,
            course_type_id: course_type.id,
            subcategory_id: subcategory.id,
            mentor_id: mentor.id
        }
        dispatch(updateCourse(
            {
                ...body,
                ...newValues
            },
            {
                page: refTable.current.page + 1,
                page_size: refTable.current.rowsPerPage,
                subcategory__category_id: categorySelected
            },
            1
        ))
    }

    return (
        <div className='assessmentsContainer'>
            <div className='headerContainer'>
                <div className='leftHeaderContainer'>
                    <h4>Lista de cursos</h4>
                    {categoriesList &&
                        <Input
                            id="categorySelect"
                            type="select"
                            onChange={e => setCategorySelected(e.target.value)}
                            style={{ maxWidth: "200px" }}
                        >
                            {getUniqueValuesFromArray(categoriesList, d => d.name)?.map((category, index) => (
                                <option key={`Category${index}`} value={category.id}>
                                    {category.name}
                                </option>
                            ))}
                        </Input>
                    }
                </div>
            </div>
            <div className="assessmentsCoursesTable">
                {
                    coursesList
                        ? <Table
                            columns={columnsTable}
                            data={handleData(coursesList.data)}
                            changePage={(newPage, pageSize) => coursesRequest(newPage + 1, pageSize)}
                            changeRowsPerPage={(pageSize) => coursesRequest(1, pageSize)}
                            count={coursesList.count}
                            ref={refTable}
                            updateData={handleUpdateCourse}
                        />
                        : <Spinner style={{ marginLeft: "45%", marginTop: "46px" }} />
                }
            </div>
        </div>
    )
}

export default Courses;