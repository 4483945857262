import React, { useEffect } from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

import privateRoutes from './privateRoutes';
import WrappedRoutes from './WrappedRoutes';
import Login from "../containers/Login/index";
import TermsAndConditionsPublicPage from "../containers/TermsAndConditions/publicPage";
import PasswordReset from '../containers/Login/passwordReset';
import NotFound from '../commons/components/NotFound';
import { tokenRenovation, fetchTokenRenovation, getUserByToken } from "../redux/actions/authActions"

const Router = () => {
    const dispatch = useDispatch();
    const isAuth = useSelector(state => state.auth.isAuth);
    const isFetchingTokenRenovation = useSelector(state => state.auth.isFetchingTokenRenovation);

    useEffect(() => {
        if (localStorage.getItem("token")) dispatch(tokenRenovation());
        dispatch(fetchTokenRenovation())
    }, [])

    useEffect(() => {
        if (isAuth) {
            dispatch(getUserByToken())
        }
    }, [isAuth])

    return (
        isFetchingTokenRenovation
            ? <div />
            : <Routes>
                <Route
                    path="/"
                    element={!isAuth
                        ? <Login />
                        : <Navigate to="/dashboard" replace />
                    }
                />
                <Route
                    path="/privacy-politics"
                    element={<TermsAndConditionsPublicPage />}
                />
                <Route
                    path="/password-reset/:token"
                    element={<PasswordReset />}
                />
                {privateRoutes.map(route =>
                    <Route
                        key={route.path}
                        path={route.path}
                        element={isAuth
                            ? <WrappedRoutes>{route.component}</WrappedRoutes>
                            : <Navigate to="/" replace />
                        }
                    />
                )}
                <Route path="*" element={isAuth
                    ? <NotFound />
                    : <Navigate to="/dashboard" replace />} />
            </Routes>
    )
};

export default Router;