import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import Table from '../../commons/components/Table';
import { Spinner } from 'reactstrap';
import moment from 'moment';
import { DesktopDatePicker } from "@mui/x-date-pickers";
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import TextField from '@mui/material/TextField';
import downloadDocument from "../../commons/functions/downloadDocument"

import { getReports, getPurchasedCoursesReports } from "../../redux/actions/commonsActions";
import { rowsPerPageOptions } from "../../commons/constants/pagination";

const columnsTable = [
    {
        title: 'No',
        value: 'id',
        isCustom: false
    },
    {
        title: 'ID',
        value: 'professional_id',
        isCustom: false
    },
    {
        title: 'Nombre',
        value: 'name',
        isCustom: false
    },
    {
        title: 'apellidoPAT',
        value: 'first_name',
        isCustom: false
    },
    {
        title: 'apellidoMAT',
        value: 'last_name',
        isCustom: false
    },
    {
        title: 'Cod',
        value: 'code',
        isCustom: false
    },
    {
        title: 'Curso',
        value: 'title',
        isCustom: false
    },
    {
        title: 'Fecha de repuesta',
        value: 'created_at',
        isCustom: false
    }
]

const handleData = (data) => (
    data.map(({ id, user: { professional_id, name, first_name, last_name }, course: { code, title }, created_at }) => ({
        id, professional_id, name, first_name, last_name, code, title, created_at: moment(created_at).format("DD/MM/YYYY")
    }))
)

const Reports = () => {
    const dispatch = useDispatch();
    const reportsList = useSelector((state) => state.commons.reports);
    const refTable = useRef(null);

    const [initialDate, setInitialDate] = useState(moment().subtract(1, 'months'));
    const [endDate, setEndDate] = useState(moment());

    useEffect(() => {
        reportsRequest(1, rowsPerPageOptions[0])
    }, []);

    const reportsRequest = (page, page_size) => {
        dispatch(getReports({
            page,
            page_size
        }))
    }

    const onClearDates = () => {
        setInitialDate(moment().subtract(1, 'months'));
        setEndDate(moment());
    }

    const DownloadReport = () => {
        downloadDocument("purchasedCourses", {
            start_date: moment(initialDate).set({ hour: 0, minute: 0, second: 0 }).toISOString(),
            end_date: moment(endDate).set({ hour: 23, minute: 59, second: 59 }).toISOString(),
        })
    }

    return (
        <div className='reportsContainer'>
            <div className='headerContainer'>
                <div className='leftHeaderContainer'>
                    <h4>Cursos comprados</h4>
                </div>
                <div className='rightHeaderContainer'>
                    <div className='datePickerContainer'>
                        <LocalizationProvider dateAdapter={AdapterMoment}>
                            <DesktopDatePicker
                                inputFormat="DD/MM/YYYY"
                                value={initialDate}
                                maxDate={moment()}
                                onChange={e => setInitialDate(e)}
                                renderInput={(params) => <TextField {...params} />}
                            />
                            <DesktopDatePicker
                                inputFormat="DD/MM/YYYY"
                                value={endDate}
                                maxDate={moment()}
                                onChange={e => setEndDate(e)}
                                renderInput={(params) => <TextField {...params} />}
                            />
                        </LocalizationProvider>
                    </div>
                    <button className='addItemButton' onClick={DownloadReport}>
                        Exportar
                    </button>
                    <button className='addItemButton' onClick={onClearDates}>
                        Clear
                    </button>
                </div>
            </div>
            {
                reportsList
                    ? <Table
                        columns={columnsTable}
                        data={handleData(reportsList.data)}
                        changePage={(newPage, pageSize) => reportsRequest(newPage + 1, pageSize)}
                        changeRowsPerPage={(pageSize) => reportsRequest(1, pageSize)}
                        count={reportsList.count}
                        ref={refTable}
                    />
                    : <Spinner style={{ marginLeft: "45%", marginTop: "46px" }} />
            }
        </div>
    )
}

export default Reports;