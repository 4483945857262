import { handleActions } from 'redux-actions';
import {
    fetchAssessmentsRequest,
    fetchAssessmentsSuccess,
    fetchAssessmentSuccess,
    fetchAssessmentsError
} from '../actions/assessmentsAction';

const defaultState = {
    assessments: null,
    assessment: null,
    isFetching: false,
    error: null,
};

export default handleActions(
    {
        [fetchAssessmentsRequest](state) {
            return {
                ...state,
                isFetching: true,
            };
        },
        [fetchAssessmentsSuccess](state, { payload: { results, count } }) {
            return {
                ...state,
                isFetching: false,
                assessments: {
                    data: results,
                    count
                }
            };
        },
        [fetchAssessmentsError](state, { payload }) {
            return {
                ...state,
                isFetching: false,
                error: payload
            };
        },
        [fetchAssessmentSuccess](state, { payload: { results } }) {
            return {
                ...state,
                isFetching: false,
                assessment: results
            };
        }
    },
    defaultState
);