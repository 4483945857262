import { get, put, getWithCustomConfig, postFormData, deleteMethod, putFormData, post } from '../base/index';

export default {
    getUsers: (params) => {
        return get(`${process.env.REACT_APP_URL_BASE}/users/`, params)
    },
    getUserByToken: (token) => {
        return getWithCustomConfig(`${process.env.REACT_APP_URL_BASE}/users/token`, {}, {
            headers: { "Authorization": `Bearer ${token}` },
        })
    },
    updateUser: (body, id) => {
        return put(`${process.env.REACT_APP_URL_BASE}/users/${id}/`, body)
    },
    getUser: (id) => {
        return get(`${process.env.REACT_APP_URL_BASE}/users/${id}/`)
    },
    getMentors: (params) => {
        return get(`${process.env.REACT_APP_URL_BASE}/mentors/`, params)
    },
    createMentor: (body) => {
        return postFormData(`${process.env.REACT_APP_URL_BASE}/mentors/`, body)
    },
    deleteMentor: (id) => {
        return deleteMethod(`${process.env.REACT_APP_URL_BASE}/mentors/${id}/`)
    },
    updateMentor: (body, typePutPetition) => {
        return typePutPetition === 2
            ? putFormData(`${process.env.REACT_APP_URL_BASE}/mentors/`, body)
            : put(`${process.env.REACT_APP_URL_BASE}/mentors/`, body)
    },
    addUserSubscription: (body) => {
        return post(`${process.env.REACT_APP_URL_BASE}/user_subscriptions/`, body)
    },
    addUserCourse: (body) => {
        return post(`${process.env.REACT_APP_URL_BASE}/purchased_courses/`, body)
    }
}