import React, { useState, useEffect } from 'react';
import { Modal, Input, ModalBody, Button } from 'reactstrap';
import { IconButton } from '@mui/material';
import Icon from '@mdi/react';
import { mdiClose } from '@mdi/js';

import { updateCourseType } from "../../../redux/actions/categoriesActions";
import { useDispatch, useSelector } from 'react-redux';

const CreateCourseTypeModal = ({ isOpenModal, closeModal, createMode, initialData, page, rowsPerPage }) => {
    const dispatch = useDispatch();
    const modalStatus = useSelector((state) => state.categories.modalActionStatus);
    const [courseTypeName, setcourseTypeName] = useState("");
    const [submittedForm, setSubmittedForm] = useState(false);

    useEffect(() => {
        if (!createMode) {
            setcourseTypeName(initialData?.name)
        }
    }, [isOpenModal])

    useEffect(() => {
        if (modalStatus === "COMPLETED")
            handleClose();
    }, [modalStatus])

    const handleUpdateCourseType = () => {
        setSubmittedForm(true);
        if (!handleError(courseTypeName)) {
            dispatch(updateCourseType(
                {
                    id: initialData.id,
                    name: courseTypeName,
                    code: initialData.code,
                    is_active: initialData.is_active
                },
                {
                    page: page + 1,
                    page_size: rowsPerPage
                },
            ))
        }
    }

    const handleChangeInput = (e, setInput) => {
        setInput(e.target.value)
    }

    const handleError = (field1, field2) => (
        field1 === '' || field2 === '' ? true : false
    )

    const handleClose = () => {
        setcourseTypeName("");
        setSubmittedForm(false);
        closeModal();
    }

    const disableButtonValidation = () => {
        if (createMode) {
            return modalStatus === "LOADING"
        }
        else {
            return (initialData?.name === courseTypeName)
        }
    }

    return (
        <Modal isOpen={isOpenModal} centered className='createCategoryModal'>
            <div className='containButton'>
                <IconButton onClick={handleClose} className="closeButton">
                    <Icon path={mdiClose} color="#131F2A" size="23px" />
                </IconButton>
            </div>
            <ModalBody>
                <form>
                    <Input
                        placeholder='Nombre del tipo de curso'
                        onChange={(e) => handleChangeInput(e, setcourseTypeName)}
                        invalid={handleError(courseTypeName) && submittedForm}
                        value={courseTypeName}
                    />
                    <div className='buttons'>
                        <Button className='secondarybutton' onClick={handleClose}>Cancelar</Button>
                        <Button className='principalbutton' onClick={createMode ? null : handleUpdateCourseType} disabled={disableButtonValidation()}>Guardar</Button>
                    </div>
                </form>
            </ModalBody>
        </Modal>
    )
}

export default CreateCourseTypeModal;