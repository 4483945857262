import { handleActions } from "redux-actions";
import {
  fetchDiscountCouponsRequest,
  fetchDiscountCouponsSuccess,
  fetchModalDiscountCouponRequest,
  fetchModalDiscountCouponSuccess,
  fetchDiscountCouponsError,
  fetchDiscountRatesSuccess,
} from "../actions/discountsActions";

const defaultState = {
  discountCoupons: null,
  discountRates: null,
  isFetching: false,
  modalActionStatus: "STARTED",
  error: null,
};

export default handleActions(
  {
    [fetchDiscountCouponsRequest](state) {
      return {
        ...state,
        isFetching: true,
      };
    },
    [fetchDiscountCouponsSuccess](state, { payload: { results, count } }) {
      return {
        ...state,
        discountCoupons: {
          data: results,
          count,
        },
        modalActionStatus: "STARTED",
        isFetching: false,
        error: null,
      };
    },
    [fetchDiscountRatesSuccess](state, { payload: { results, count } }) {
      return {
        ...state,
        discountRates: {
          data: results,
          count,
        },
        modalActionStatus: "STARTED",
        isFetching: false,
        error: null,
      };
    },
    [fetchModalDiscountCouponRequest](state) {
      return {
        ...state,
        modalActionStatus: "LOADING",
      };
    },
    [fetchModalDiscountCouponSuccess](state) {
      return {
        ...state,
        modalActionStatus: "COMPLETED",
      };
    },
    [fetchDiscountCouponsError](state, { payload }) {
      return {
        ...state,
        isFetching: false,
        error: payload,
      };
    },
  },
  defaultState
);
