import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Spinner } from 'reactstrap';

import { getUsers, updateUser } from "../../redux/actions/usersActions";
import Table from '../../commons/components/Table';
import Checkbox from "../../commons/components/tableComponents/Checkbox";
import Link from "../../commons/components/tableComponents/Link";
import ToggleSwitch from "../../commons/components/tableComponents/ToggleSwitch";
import { rowsPerPageOptions } from "../../commons/constants/pagination";
import AutoSearch from "../../commons/components/tableComponents/AutoSearch";
import { useSearchParams } from 'react-router-dom';

const columnsTable = [
    {
        value: 'id',
        title: 'No',
        isCustom: true,
        CustomElement: Link
    },
    {
        value: 'public_user',
        title: 'U. Público',
        isCustom: true,
        CustomElement: Checkbox,
        state: 'users.users'
    },
    {
        value: 'professional_id',
        title: 'ID',
        isCustom: true,
        CustomElement: Link
    },
    {
        value: 'name',
        title: 'Nombre',
        isCustom: false
    },
    {
        value: 'first_name',
        title: 'apellidoPAT',
        isCustom: false
    },
    {
        value: 'last_name',
        title: 'apellidoMAT',
        isCustom: false
    },
    {
        value: 'num_purchased_courses',
        title: 'Cursos Comprados',
        isCustom: false
    },
    {
        value: 'num_certificates',
        title: 'Certificados',
        isCustom: false
    },
    {
        value: 'is_active', //value from API
        title: 'Activo', //column Title
        isCustom: true, //is a custom component?
        CustomElement: ToggleSwitch, //Custom component in case it is
        state: 'users.users' //State to get the initial data of the user because it has to be sent in the PUT method
    }
]

const handleData = (data) => (
    data.map(({ id, public_user, professional_id, name, first_name, last_name, num_purchased_courses, num_certificates, is_active }) => ({
        id,
        public_user,
        professional_id,
        name,
        first_name,
        last_name,
        num_purchased_courses,
        num_certificates,
        is_active
    }))
)

const Users = () => {
    const dispatch = useDispatch();
    const usersLists = useSelector((state) => state.users.users);
    const [nameFilter, setNameFilter] = useState("");
    const refTable = useRef(null);
    const [searchParams] = useSearchParams();
    const pageParam = searchParams.get('page');
    const rowsPerPageParam = searchParams.get('rowsPerPage');

    useEffect(() => {
        if (typeof refTable.current?.page === 'undefined' && typeof refTable.current?.rowsPerPage === 'undefined') {
            usersRequest(
                pageParam ? (parseInt(pageParam) + 1) : 1,
                rowsPerPageParam ? rowsPerPageParam : rowsPerPageOptions[0]
            ) //If is undefined is because it's the first time tht is queried 
        }
        else usersRequest(refTable.current?.page + 1, refTable.current?.rowsPerPage) //If isn't undefined it's because changed the filter value, then is required a new query
    }, [nameFilter])

    const usersRequest = (page, page_size) => {
        dispatch(getUsers({
            name__icontains: nameFilter, page, page_size
        }))
    }

    const handleUpdateUsers = (newValues, userInitialData) => {
        const body = {
            username: userInitialData.username,
            email: userInitialData.email,
            name: userInitialData.name,
            first_name: userInitialData.first_name,
            last_name: userInitialData.last_name,
            professional_id: userInitialData.professional_id,
            public_user: userInitialData.public_user,
            groups: [userInitialData.groups[0]?.id],
            categories: userInitialData.categories.map(val => val.id),
            is_active: userInitialData.is_active
        }
        dispatch(updateUser(
            {
                ...body,
                ...newValues
            },
            {
                page: refTable.current.page + 1,
                page_size: refTable.current.rowsPerPage
            },
            userInitialData.id
        ))
    }

    return (
        <div className='usersContainer'>
            <div className='title'>
                <h4>Usuarios</h4>
                <AutoSearch
                    placeholder='Buscar por nombre'
                    setFilter={res => setNameFilter(res)}
                />
            </div>
            {
                usersLists
                    ? <Table
                        columns={columnsTable}
                        data={handleData(usersLists.data)}
                        changePage={(newPage, pageSize) => usersRequest(newPage + 1, pageSize)}
                        changeRowsPerPage={(pageSize) => usersRequest(1, pageSize)}
                        count={usersLists.count}
                        ref={refTable}
                        updateData={handleUpdateUsers}
                    />
                    : <Spinner style={{ marginLeft: "45%", marginTop: "46px" }} />
            }
        </div>
    )
}

export default Users;