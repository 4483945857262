import React, { useState } from 'react'
import { Collapse, NavItem, NavLink } from "reactstrap";
import Icon from '@mdi/react';
import { mdiChevronDown, mdiChevronUp } from '@mdi/js'
import PropTypes from 'prop-types'
import { Link, useLocation } from "react-router-dom";

const SubMenu = ({ title, iconMenu, subItems, pathBase }) => {
    const { pathname } = useLocation();
    const [collapsed, setCollapsed] = useState(pathname.includes(`${pathBase}/`));

    return (
        <div>
            <NavItem onClick={() => setCollapsed(!collapsed)}>
                <NavLink>
                    <Icon path={iconMenu} color="#D6D1C3" />
                    <div className='submenuTitle'>
                        {title}
                        <Icon path={!collapsed ? mdiChevronDown : mdiChevronUp} color="#D6D1C3" className={collapsed ? 'activeIcon' : 'inactiveIcon'} />
                    </div>
                </NavLink>
            </NavItem>
            <Collapse isOpen={collapsed}>
                {
                    subItems.map(subtItem => (
                        <NavItem key={subtItem.title}>
                            <NavLink className={pathname === pathBase + subtItem.path ? 'sub-linkActived' : 'sub-linkInactived'} tag={Link} to={pathBase + subtItem.path} key={subtItem.title}>
                                {subtItem.title}
                            </NavLink>
                        </NavItem>
                    ))
                }
            </Collapse>
        </div>
    )
}

SubMenu.propTypes = {
    title: PropTypes.string.isRequired,
    iconMenu: PropTypes.elementType.isRequired,
    pathBase: PropTypes.string.isRequired,
    subItems: PropTypes.arrayOf(
        PropTypes.shape({
            subTitle: PropTypes.string,
        })
    )
}

export default SubMenu;
