import { createAction } from "redux-actions";
import api from "../../utils/api/apiClient";
import { toast } from "react-toastify";

export const fetchCategoriesRequest = createAction('FETCH_CATEGORIES_REQUEST');
export const fetchCategoriesSuccess = createAction('FETCH_CATEGORIES_SUCCESS');
export const fetchSubcategoriesSuccess = createAction('FETCH_SUBCATEGORIES_SUCCESS');
export const fetchCourseTypeSuccess = createAction('FETCH_COURSE_TYPE_SUCCESS');
export const fetchModalCategoryRequest = createAction('FETCH_MODAL_CATEGORY_REQUEST');
export const fetchModalCategorySuccess = createAction('FETCH_MODAL_CATEGORY_SUCCESS');
export const fetchCategoriesError = createAction('FETCH_CATEGORIES_ERROR');

export const getCategories = (params) => async (dispatch) => {
    try {
        dispatch(fetchCategoriesRequest());
        const { data } = await api.categories.getCategories({ params });
        dispatch(fetchCategoriesSuccess(data));
    } catch (error) {
        errorFunction(error, dispatch);
    }
};

export const createCategory = (body, paramsGetCategories, resetPagination) => async (dispatch) => {
    try {
        dispatch(fetchModalCategoryRequest());
        await api.categories.createCategory(body);
        dispatch(fetchModalCategorySuccess());
        toast.success('Categoría creada de forma exitosa', { autoClose: 3000, theme: "colored" });
        await dispatch(getCategories(paramsGetCategories))
        resetPagination();
    } catch (error) {
        errorFunction(error, dispatch);
    }
}

export const deleteCategory = (id, paramsGetCategories) => async (dispatch) => {
    try {
        await api.categories.deleteCategory(id);
        toast.success('Categoría eliminada de forma exitosa', { autoClose: 3000, theme: "colored" });
        dispatch(getCategories(paramsGetCategories))
    } catch (error) {
        errorFunction(error, dispatch);
    }
}

export const updateCategory = (body, paramsGetCategories, typePutPetition) => async (dispatch) => {
    try {
        dispatch(fetchModalCategoryRequest());
        await api.categories.updateCategory(body, typePutPetition);
        dispatch(fetchModalCategorySuccess());
        toast.success('Categoría actualizada de forma exitosa', { autoClose: 3000, theme: "colored" });
        dispatch(getCategories(paramsGetCategories))
    } catch (error) {
        errorFunction(error, dispatch);
    }
}

export const getSubcategories = (params, preserveStatusModal) => async (dispatch) => {
    try {
        dispatch(fetchCategoriesRequest());
        const { data } = await api.categories.getSubcategories({ params });
        dispatch(fetchSubcategoriesSuccess({ ...data, preserveStatusModal }));
    } catch (error) {
        errorFunction(error, dispatch);
    }
};

export const createSubcategory = (body, paramsGetSubcategories, resetPagination) => async (dispatch) => {
    try {
        dispatch(fetchModalCategoryRequest());
        await api.categories.createSubcategory(body);
        dispatch(fetchModalCategorySuccess());
        toast.success('Subcategoría creada de forma exitosa', { autoClose: 3000, theme: "colored" });
        await dispatch(getSubcategories(paramsGetSubcategories, true))
        resetPagination();
    } catch (error) {
        errorFunction(error, dispatch);
    }
}

export const deleteSubcategory = (id, paramsGetSubcategories) => async (dispatch) => {
    try {
        await api.categories.deleteSubcategory(id);
        toast.success('Subcategoría eliminada de forma exitosa', { autoClose: 3000, theme: "colored" });
        dispatch(getSubcategories(paramsGetSubcategories, true))
    } catch (error) {
        errorFunction(error, dispatch);
    }
}

export const updateSubcategory = (body, paramsGetSubcategories, typePutPetition) => async (dispatch) => {
    try {
        dispatch(fetchModalCategoryRequest());
        await api.categories.updateSubcategory(body, typePutPetition);
        dispatch(fetchModalCategorySuccess());
        toast.success('Subcategoría actualizada de forma exitosa', { autoClose: 3000, theme: "colored" });
        //alert("UP")
        dispatch(getSubcategories(paramsGetSubcategories, true))
    } catch (error) {
        errorFunction(error, dispatch);
    }
}

export const getCourseType = (params) => async (dispatch) => {
    try {
        dispatch(fetchCategoriesRequest());
        const { data } = await api.categories.getCourseType({ params });
        dispatch(fetchCourseTypeSuccess(data));
    } catch (error) {
        errorFunction(error, dispatch);
    }
};

export const updateCourseType = (body, paramsGetCourseType) => async (dispatch) => {
    try {
        dispatch(fetchModalCategoryRequest());
        await api.categories.updateCourseType(body);
        dispatch(fetchModalCategorySuccess());
        toast.success('Tipo de curso actualizado de forma exitosa', { autoClose: 3000, theme: "colored" });
        dispatch(getCourseType(paramsGetCourseType))
    } catch (error) {
        errorFunction(error, dispatch);
    }
}

const errorFunction = (error, dispatch) => {
    const errorMessage = error?.response?.data?.message ? error.response.data.message : error.message
    dispatch(fetchCategoriesError(errorMessage));
    toast.error(errorMessage, { autoClose: 3000, theme: "colored" });
}