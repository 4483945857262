import React from 'react';
import mainLogo from '../../commons/img/sidebarLogo.svg';
import { Nav, NavLink } from 'reactstrap';
import SubMenu from './SubMenu';
import Icon from '@mdi/react';
import { Link, useLocation } from "react-router-dom";
import { sidebarStructure } from "../constants/sidebarStructure"
import { mdiExitToApp } from '@mdi/js';
import { useDispatch } from 'react-redux';
import { logout } from '../../redux/actions/authActions';

const Sidebar = () => {
    const { pathname } = useLocation();
    const dispatch = useDispatch();

    const handleCloseSection = () => {
        localStorage.removeItem("token");
        dispatch(logout())
    }

    return (
        <div className='sidebar'>
            <img src={mainLogo} />
            <Nav vertical>
                {
                    sidebarStructure.map(category => (
                        category.type === "subCategory" ? (
                            <SubMenu title={category.title} iconMenu={category.icon} subItems={category.items} key={category.title} pathBase={category.path} />
                        ) : (
                            <NavLink className={pathname === category.path ? 'linkActived' : ''} tag={Link} to={category.path} key={category.title}>
                                <Icon path={category.icon} color="#D6D1C3" />
                                {category.title}
                            </NavLink>
                        )
                    ))
                }
                <NavLink onClick={handleCloseSection}>
                    <Icon path={mdiExitToApp} color="#D6D1C3" />
                    Cerrar Sesión
                </NavLink>
            </Nav>
        </div>
    )
}

export default Sidebar;
