import { createAction } from "redux-actions";
import api from "../../utils/api/apiClient";
import { toast } from "react-toastify";

export const fetchCommonsRequest = createAction('FETCH_COMMONS_REQUEST');
export const fetchTermsAndConditionsSuccess = createAction('FETCH_TERMS_AND_CONDITIONS_SUCCESS');
export const fetchNotificationsSuccess = createAction('FETCH_NOTIFICATIONS_SUCCESS');
export const fetchAttendancesSuccess = createAction('FETCH_ATTENDANCES_SUCCESS');
export const fetchReportsSuccess = createAction('FETCH_REPORTS_SUCCESS');
export const fetchPublicParametersSuccess = createAction('FETCH_PUBLIC_PARAMETERS_SUCCESS');
export const fetchCommonsError = createAction('FETCH_COMMONS_ERROR');

export const getTermsAndConditions = () => async (dispatch) => {
    try {
        dispatch(fetchCommonsRequest());
        const { data } = await api.commons.getTermsAndConditions();
        dispatch(fetchTermsAndConditionsSuccess(data.results.find(param => param.code === "terms_and_conditions")));
    } catch (error) {
        errorFunction(error, dispatch);
    }
};

export const updateTermsAndConditions = (body) => async (dispatch) => {
    try {
        dispatch(fetchCommonsRequest());
        await api.commons.updateTermsAndConditions(body);
        toast.success('Términos  y condiciones actualizados de forma exitosa', { autoClose: 3000, theme: "colored" });
        dispatch(getTermsAndConditions());
    } catch (error) {
        errorFunction(error, dispatch);
    }
};

export const getNotifications = (params) => async (dispatch) => {
    try {
        dispatch(fetchCommonsRequest());
        const { data } = await api.commons.getNotifications({ params });
        dispatch(fetchNotificationsSuccess(data));
    } catch (error) {
        errorFunction(error, dispatch);
    }
};

export const createNotification = (body, paramsNotifications, handleClose) => async (dispatch) => {
    try {
        dispatch(fetchCommonsRequest());
        await api.commons.createNotification(body);
        toast.success('Notificación creada de forma exitosa', { autoClose: 3000, theme: "colored" });
        await dispatch(getNotifications(paramsNotifications))
        handleClose();
    } catch (error) {
        errorFunction(error, dispatch);
    }
}

export const getAttendances = (params, isLoadingCompleted) => async (dispatch) => {
    try {
        dispatch(fetchCommonsRequest());
        const { data } = await api.commons.getAttendances({ params });
        await dispatch(fetchAttendancesSuccess(data));
        isLoadingCompleted();
    } catch (error) {
        errorFunction(error, dispatch);
    }
};

export const getCourseCertificates = (params) => async (dispatch) => {
    try {
        const { data: { certificate_url } } = await api.commons.getCourseCertificates({ params });
        window.open(certificate_url)
    } catch (error) {
        errorFunction(error, dispatch);
    }
};

export const getReports = (params) => async (dispatch) => {
    try {
        dispatch(fetchCommonsRequest());
        const { data } = await api.commons.getReports({ params });
        dispatch(fetchReportsSuccess(data));
    } catch (error) {
        errorFunction(error, dispatch);
    }
};

export const getPublicParameters = (params) => async (dispatch) => {
    try {
        dispatch(fetchCommonsRequest());
        const { data } = await api.commons.getPublicParameters({ params });
        dispatch(fetchPublicParametersSuccess(data));
    } catch (error) {
        errorFunction(error, dispatch);
    }
};

const errorFunction = (error, dispatch) => {
    const errorMessage = error?.response?.data?.message ? error.response.data.message : error.message
    dispatch(fetchCommonsError(errorMessage));
    toast.error(errorMessage, { autoClose: 3000, theme: "colored" });
}