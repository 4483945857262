import axios, { defaultParams } from './axios';
import publicRoutesList from "../../../routers/publicRoutes"

export default async (url, params) => {
  try {
    return await axios.get(url, { ...defaultParams(), ...params });
  } catch (e) {
    if (!e || !e.response || e.response.status !== 401) throw e;
    if (e.response.status === 401) {
      localStorage.removeItem("token")
      if (!(publicRoutesList.findIndex(route => route === window.location.pathname) >= 0)) window.location.replace("/")
    }
    localStorage.setItem('url', `${window.location.pathname}${window.location.search}`);
    try {
      return axios.get(url, { ...defaultParams(), ...params });
    } catch (err) {
      return null;
    }
  }
};

export const getWithCustomConfig = async (url, params, config) => {
  try {
    return await axios.get(url, config);
  } catch (e) {
    if (!e || !e.response || e.response.status !== 401) throw e;
    if (e.response.status === 401) {
      localStorage.removeItem("token")
      window.location.replace("/")
    }
    localStorage.setItem('url', `${window.location.pathname}${window.location.search}`);
    try {
      return axios.get(url, { ...defaultParams(), ...params });
    } catch (err) {
      return null;
    }
  }
};