import React, { useState, forwardRef, useImperativeHandle } from 'react';
import PropTypes, { bool, object, string } from 'prop-types';
import { Box, Table, TableBody, TableCell, TableHead, TableRow, TableSortLabel, TablePagination } from '@mui/material';
import { elementType } from 'prop-types';
import { rowsPerPageOptions } from "../constants/pagination"
import { useSearchParams } from 'react-router-dom';

const TableComponent = forwardRef(({ columns, data, count, changePage, changeRowsPerPage, dataFilter, updateData, handleDownload, paginationDisabled }, ref) => {
    const [searchParams, setSearchParams] = useSearchParams();
    const pageParam = searchParams.get('page');
    const rowsPerPageParam = searchParams.get('rowsPerPage');
    const filterParam = searchParams.get('filter');

    const [typeOrder, setTypeOrder] = useState("asc");
    const [orderBy, setOrderBy] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(rowsPerPageParam ? parseInt(rowsPerPageParam) : rowsPerPageOptions[0]);
    const [page, setPage] = useState(pageParam ? parseInt(pageParam) : 0);

    useImperativeHandle(ref,
        () => ({ page, rowsPerPage, resetPagination, resetRowsPerPage })
    );


    const handleTypeOrder = (columnIndex) => {
        if (columnIndex === orderBy) setTypeOrder(typeOrder === "asc" ? "desc" : "asc")
        else setTypeOrder("asc");
        setOrderBy(columnIndex)
    };

    const ascendingComparator = (a, b, column) => {
        if (a[column] > b[column]) return 1;
        if (a[column] < b[column]) return -1;
        return 0;
    };

    const resetPagination = () => {
        setPage(0)
    }

    const resetRowsPerPage = () => {
        setRowsPerPage(rowsPerPageOptions[0])
    }

    const handleDataSorter = (array) => (
        array.sort((a, b) => (
            typeOrder === "asc"
                ? ascendingComparator(a, b, Object.keys(a)[orderBy])
                : -ascendingComparator(a, b, Object.keys(a)[orderBy])
        ))
    )

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
        changePage(newPage, rowsPerPage);
        setSearchParams({ page: newPage, rowsPerPage, ...(filterParam ? { filter: filterParam } : {}) });
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
        changeRowsPerPage(parseInt(event.target.value, 10));
        setSearchParams({ page: 0, rowsPerPage: parseInt(event.target.value, 10), ...(filterParam ? { filter: filterParam } : {}) });
    };

    return (
        <Box>
            <Table sx={{ minWidth: 650 }} className="table">
                <TableHead className='header'>
                    <TableRow>
                        {columns.map((column, index) => (
                            <TableCell key={column.title}>
                                {
                                    column?.notIsSortable ?
                                        column.title
                                        : <TableSortLabel
                                            active={orderBy === index}
                                            direction={orderBy === index ? typeOrder : "asc"}
                                            onClick={() => handleTypeOrder(index)}
                                        >
                                            {column.title}
                                        </TableSortLabel>
                                }
                            </TableCell>
                        ))}
                    </TableRow>
                </TableHead>
                <TableBody className="body">
                    {handleDataSorter(data).map((row, indexRow) => (
                        <TableRow
                            key={indexRow}
                            className={indexRow % 2 === 0 ? 'lightBackground' : 'darkBackground'}
                        >
                            {Object.keys(row).map((property, indexProperty) => (
                                <TableCell key={indexProperty}>
                                    {columns[indexProperty]?.isCustom
                                        ? React.createElement(
                                            columns[indexProperty]?.CustomElement,
                                            {
                                                initialValue: row[columns[indexProperty]?.value],
                                                dataColumn: columns[indexProperty],
                                                id: row.id,
                                                page,
                                                rowsPerPage,
                                                dataFilter,
                                                updateData,
                                                handleDownload
                                            }
                                        )
                                        : row[property]
                                    }
                                </TableCell>
                            ))}
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
            {!paginationDisabled && <TablePagination
                rowsPerPageOptions={rowsPerPageOptions}
                component="div"
                count={count}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                style={{ paddingRight: '15px' }}
                labelRowsPerPage='Filas por página'
                labelDisplayedRows={
                    ({ from, to, count }) => (from + '-' + to + ' de ' + count)
                }
            />}
        </Box>
    )
});

TableComponent.propTypes = {
    data: PropTypes.arrayOf(object).isRequired,
    columns: PropTypes.arrayOf(PropTypes.shape({
        value: string.isRequired,
        title: string.isRequired,
        isCustom: bool.isRequired,
        CustomElement: elementType
    })).isRequired,
    count: PropTypes.number,
    changePage: PropTypes.func,
    changeRowsPerPage: PropTypes.func,
    paginationDisabled: PropTypes.bool
}

TableComponent.defaultProps = {
    paginationDisabled: false
}

export default TableComponent;