import {
  mdiChartPie,
  mdiViewGridOutline,
  mdiAccount,
  mdiContactlessPayment,
  mdiFile,
  mdiBell,
  mdiFinance,
  mdiCashMultiple,
  mdiForum,
  mdiFileChart,
  mdiTicketPercent,
  mdiTag,
} from "@mdi/js";

export const sidebarStructure = [
  {
    type: "category",
    path: "/dashboard",
    title: "Tablero",
    icon: mdiChartPie,
  },
  {
    type: "subCategory",
    path: "/categorias",
    title: "Categorías",
    icon: mdiViewGridOutline,
    items: [
      {
        path: "",
        title: "Categorías",
      },
      {
        path: "/subcategorias",
        title: "Subcategorías",
      },
      {
        path: "/TipoDeCurso",
        title: "Tipo de curso",
      },
    ],
  },
  {
    type: "subCategory",
    path: "/cursos",
    title: "Cursos",
    icon: mdiContactlessPayment,
    items: [
      {
        path: "",
        title: "Mis cursos",
      },
      {
        path: "/podcasts",
        title: "Podcasts",
      },
      {
        path: "/anuncios",
        title: "Anuncios",
      },
    ],
  },
  {
    type: "subCategory",
    path: "/evaluaciones",
    title: "Evaluaciones",
    icon: mdiForum,
    items: [
      {
        path: "",
        title: "Categorías",
      },
      {
        path: "/cursos",
        title: "Cursos",
      },
      {
        path: "/prePostEvaluacion",
        title: "Pre-Post Evaluación",
      },
      {
        path: "/respuestas",
        title: "Respuestas",
      },
    ],
  },
  {
    type: "category",
    path: "/notificaciones",
    title: "Notificaciones",
    icon: mdiBell,
  },
  {
    type: "category",
    path: "/asistencias",
    title: "Asistencias",
    icon: mdiFinance,
  },
  {
    type: "subCategory",
    path: "/reportes",
    title: "Reportes",
    icon: mdiFileChart,
    items: [
      {
        path: "/cursosComprados",
        title: "Cursos comprados",
      },
      {
        path: "/cursosFinalizados",
        title: "Cursos finalizados",
      },
    ],
  },
  {
    type: "subCategory",
    path: "/usuarios",
    title: "Usuarios",
    icon: mdiAccount,
    items: [
      {
        path: "",
        title: "Usuarios",
      },
      {
        path: "/Conferenciante",
        title: "Conferenciante",
      },
    ],
  },
  {
    type: "category",
    path: "/suscripciones",
    title: "Suscripciones",
    icon: mdiCashMultiple,
  },
  {
    type: "category",
    path: "/terminosCondiciones",
    title: "Términos y Condiciones",
    icon: mdiFile,
  },
  {
    type: "category",
    path: "/discount-coupons",
    title: "Cupones",
    icon: mdiTicketPercent,
  },
  {
    type: "category",
    path: "/discount-rates",
    title: "Descuentos",
    icon: mdiTag,
  },
];
