import React, { useEffect } from 'react'
import mainLogo from '../../commons/img/logo.svg';
import { getPublicParameters } from "../../redux/actions/commonsActions";
import { useDispatch, useSelector } from 'react-redux';
import { Spinner } from 'reactstrap';

export default function TermsAndConditionsPublicPage() {
    const dispatch = useDispatch();
    const isLoading = useSelector((state) => state.commons.isFetching);
    const termsAndConditions = useSelector((state) => state.commons.publicParameters);

    useEffect(() => {
        dispatch(getPublicParameters({
            code: "terms_and_conditions"
        }))
    }, [])

    return (
        <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
            <img src={mainLogo} height="138px" width="543px" />
            {termsAndConditions && !isLoading ?
                <div style={{ maxWidth: "1200px", padding: "20px 0px 50px 0px" }}>
                    <div
                        dangerouslySetInnerHTML={{ __html: termsAndConditions[0]?.description }}
                    />
                </div>
                : <Spinner style={{ marginTop: "46px" }} />}
        </div>
    )
}
