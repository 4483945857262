import { get, post, put } from '../base/index';

export default {
    getTermsAndConditions: () => {
        return get(`${process.env.REACT_APP_URL_BASE}/parameters/?code=terms_and_conditions`)
    },
    updateTermsAndConditions: (body) => {
        return put(`${process.env.REACT_APP_URL_BASE}/parameters/`, body)
    },
    getNotifications: (params) => {
        return get(`${process.env.REACT_APP_URL_BASE}/notifications/`, params)
    },
    createNotification: (body) => {
        return post(`${process.env.REACT_APP_URL_BASE}/notifications/`, body)
    },
    getAttendances: (params) => {
        return get(`${process.env.REACT_APP_URL_BASE}/reproduced_courses/`, params)
    },
    getCourseCertificates: (params) => {
        return get(`${process.env.REACT_APP_URL_BASE}/course_certificates/`, params)
    },
    getReports: (params) => {
        return get(`${process.env.REACT_APP_URL_BASE}/purchased_courses/`, params)
    },
    getPublicParameters: (params) => {
        return get(`${process.env.REACT_APP_URL_BASE}/parameters-public/`, params)
    }
}