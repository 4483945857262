import React, { useState } from 'react';
import { IconButton, Switch } from '@mui/material';
import Icon from '@mdi/react';
import { mdiDelete, mdiSquareEditOutline } from '@mdi/js';
import { useDispatch, useSelector } from 'react-redux';
import getObjectFromString from '../../functions/getObjectFromString';
import { useNavigate } from 'react-router-dom';
import { Modal, ModalBody, Button, ModalHeader, ModalFooter } from 'reactstrap';
import { mdiClose } from '@mdi/js';

const ActionsPanel = ({ id, page, rowsPerPage, dataColumn, dataFilter, updateData }) => {
    const dispatch = useDispatch();
    const dataState = useSelector((state) => getObjectFromString(state, dataColumn?.state));//get state sending name as string
    const initialDataFiltered = dataState?.data.find(element => element.id === id) //filter the data with the user required
    const [isOpenEditModal, setIsOpenEditModal] = useState(false);
    const [isOpenDeleteModal, setIsOpenDeleteModal] = useState(false);
    const navigate = useNavigate();

    const handleChangeStatus = (e) => {
        e.preventDefault();
        updateData(
            { is_active: e.target.checked },
            initialDataFiltered
        )
    }

    const handleEdit = () => {
        if (dataColumn?.editionRedirect) navigate(`${dataColumn?.editionRedirectURL}/${id}`)
        else setIsOpenEditModal(true)
    }

    const handleDelete = () => {
        dispatch(dataColumn.deleteDispatchAction(
            id,
            {
                page: page + 1,
                page_size: rowsPerPage,
                ...dataFilter
            }
        ))
        setIsOpenDeleteModal(false)
    }

    return (
        <div style={{ padding: '0px 15px', display: "flex", alignItems: "center" }}>
            {dataColumn.deleteOption && <IconButton onClick={() => setIsOpenDeleteModal(true)}>
                <Icon path={mdiDelete} color="#000" size="23px" />
            </IconButton>}
            <IconButton onClick={handleEdit}>
                <Icon path={mdiSquareEditOutline} color="#000" size="23px" />
            </IconButton>
            <Switch
                size="small"
                onClick={handleChangeStatus}
                className="toggleSwitch"
                checked={initialDataFiltered?.is_active}
            />
            {!dataColumn?.editionRedirect && isOpenEditModal && //editionRedirect defines if when is pressed the edit button is redirected to a page for to edit the info or if is edited in a modal
                React.createElement( //Custom modal defined in table`s columns
                    dataColumn.editModalElement,
                    {
                        isOpenModal: isOpenEditModal,
                        closeModal: () => setIsOpenEditModal(false),
                        createMode: false,
                        initialData: initialDataFiltered,
                        page,
                        rowsPerPage,
                        dataFilter,
                        paramsGet: {
                            page: page + 1,
                            page_size: rowsPerPage
                        }
                    }
                )
            }
            <Modal isOpen={isOpenDeleteModal} centered className='deleteElementModal'>
                <ModalHeader>
                    <span>
                        Eliminar elemento
                    </span>
                    <IconButton className="closeButton" onClick={() => setIsOpenDeleteModal(false)}>
                        <Icon path={mdiClose} color="#131F2A" size="23px" />
                    </IconButton>
                </ModalHeader>
                <ModalBody>
                    Estás seguro que quieres eliminar este elemento?
                </ModalBody>
                <ModalFooter>
                    <Button color='danger' outline onClick={() => setIsOpenDeleteModal(false)}>Cancelar</Button>
                    <Button color='danger' onClick={handleDelete}>Confirmar</Button>
                </ModalFooter>
            </Modal>
        </div>
    )
}

export default ActionsPanel;