import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Spinner } from 'reactstrap';

import Table from '../../commons/components/Table';
import ActionsPanel from "../../commons/components/tableComponents/ActionsPanel";
import { rowsPerPageOptions } from "../../commons/constants/pagination";
import CreateDiscountCouponModal from "./components/CreateDiscountCouponModal"
import { createDiscountCoupon, deleteDiscountCoupon, getDiscountCoupons, updateDiscountCoupon } from '../../redux/actions/discountsActions';
import moment from 'moment';

const columnsTable = [
    {
        value: 'id',
        title: 'No',
        isCustom: false
    },
    {
        value: 'code',
        title: 'Nombre',
        isCustom: false
    },
    {
        value: 'discount',
        title: 'Descuento',
        isCustom: false
    },
    {
        value: 'start_date',
        title: 'Fecha de Inicio',
        isCustom: false
    },
    {
        value: 'end_date',
        title: 'Fecha de Finalización',
        isCustom: false
    },
    {
        value: 'is_active',
        title: 'Acciones',
        isCustom: true,
        notIsSortable: true,
        CustomElement: ActionsPanel,
        editModalElement: CreateDiscountCouponModal,
        updateDispatchAction: updateDiscountCoupon,
        state: 'discounts.discountCoupons',
        deleteOption: true,
        deleteDispatchAction: deleteDiscountCoupon
    }
]

const handleData = (data) => (
    data.map(({ id, code, discount, start_date,end_date, is_deleted }) => ({
        id,
        code,
        discount: `${discount}%`,
        start_date : start_date === null ? "" : moment(start_date).format("DD/MM/YYYY"),
        end_date: end_date === null ? "" : moment(end_date).format("DD/MM/YYYY"),
        is_deleted
    }))
)

const DiscountCoupons = () => {
    const dispatch = useDispatch();
    const [isOpenModal, setIsOpenModal] = useState(false);
    const discountCouponList = useSelector((state) => state.discounts.discountCoupons);
    const refTable = useRef(null);

    useEffect(() => {
        discountCouponsRequest(1, rowsPerPageOptions[0])
    }, [])

    const discountCouponsRequest = (page, page_size) => {
        dispatch(getDiscountCoupons({
            page, page_size
        }))
    }

    const handleCreateCoupon = (body) => {
        dispatch(createDiscountCoupon(
            body,
            {
                page: 1,
                page_size: refTable.current?.rowsPerPage
            },
            refTable.current.resetPagination //Reset pagination on data reload in the page 1
        ));
    }

    const handleUpdateCoupon = (newValues, discountCouponInitialData) => {
        const body = {
            id: discountCouponInitialData.id,
            code: discountCouponInitialData.code,
            discount: discountCouponInitialData.discount,
            start_date: discountCouponInitialData.start_date,
            end_date: discountCouponInitialData.end_date,
            is_active: discountCouponInitialData.is_active
        }
        dispatch(updateDiscountCoupon(
            {
                ...body,
                ...newValues
            },
            {
                page: refTable.current.page + 1,
                page_size: refTable.current.rowsPerPage,
            },
            1
        ))
    }

    return (
        <div className='categoriesContainer'>
            <div className='headerContainer'>
                <div className='leftHeaderContainer'>
                    <h4>Cupones</h4>
                </div>
                <button className='addItemButton' onClick={() => setIsOpenModal(true)}>
                    Añadir cupón
                </button>
            </div>
            {
                discountCouponList
                    ? <Table
                        columns={columnsTable}
                        data={handleData(discountCouponList.data)}
                        changePage={(newPage, pageSize) => discountCouponsRequest(newPage + 1, pageSize)}
                        changeRowsPerPage={(pageSize) => discountCouponsRequest(1, pageSize)}
                        count={discountCouponList.count}
                        ref={refTable}
                        updateData={handleUpdateCoupon}
                    />
                    : <Spinner style={{ marginLeft: "45%", marginTop: "46px" }} />
            }
            <CreateDiscountCouponModal
                isOpenModal={isOpenModal}
                closeModal={() => setIsOpenModal(false)}
                createCoupon={handleCreateCoupon}
                createMode={true}
                initialData={null}
            />
        </div>
    )
}

export default DiscountCoupons;